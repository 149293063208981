import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosClient } from '../config/axios'


const fetcher = async (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    const { data } = response;
    return data;
  };

  const onError = function (error: AxiosError) {
    return Promise.reject({
      message: error.message,
      code: error.code,
      response: error.response,
    });
  };

  return axiosClient(options).then(onSuccess).catch(onError);
};

export default fetcher;
