import * as React from "react";
const SvgActivities = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.676 3.4a1.275 1.275 0 1 0 0 2.55 1.275 1.275 0 0 0 0-2.55m11.578 4.004L9.104.247A.85.85 0 0 0 8.502 0H.851A.85.85 0 0 0 0 .85v7.651a.85.85 0 0 0 .247.604l7.157 7.15a2.55 2.55 0 0 0 3.605 0l5.245-5.203a2.55 2.55 0 0 0 0-3.605zm-1.199 2.398-5.253 5.245a.85.85 0 0 1-1.199 0L1.7 8.153V1.7h6.453l6.903 6.903a.85.85 0 0 1 0 1.199"
    />
  </svg>
);
export default SvgActivities;
