
import TopHeader from "./TopHeader";
import AnnouncementBar from "./AnnouncementBar";

const Header = () => {

    return (
        <>
            {/* Top Header */}
            <TopHeader />
            {/* Announcement Header */}
            <AnnouncementBar />
        </>
    );
};

export default Header;
