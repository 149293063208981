import * as React from "react";
const SvgMoney = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24.019.627H1.18A1.18 1.18 0 0 0 0 1.81v13.387a1.18 1.18 0 0 0 1.181 1.181h.197v.788a1.18 1.18 0 0 0 1.181 1.181h.197v.788a1.18 1.18 0 0 0 1.181 1.18h17.325a1.18 1.18 0 0 0 1.182-1.18v-.788h.197a1.18 1.18 0 0 0 1.18-1.181v-.788h.198a1.18 1.18 0 0 0 1.18-1.181V1.809A1.18 1.18 0 0 0 24.02.627m-2.363 18.507a.394.394 0 0 1-.394.393H3.937a.394.394 0 0 1-.393-.393v-.788h18.112zm1.378-1.97a.394.394 0 0 1-.393.394H2.559a.394.394 0 0 1-.393-.393v-.788h20.868zm1.378-1.968a.394.394 0 0 1-.393.394H1.18a.394.394 0 0 1-.394-.394V1.809a.394.394 0 0 1 .394-.394H24.02a.394.394 0 0 1 .393.394z"
    />
    <path
      fill="currentColor"
      d="M22.05 6.141a2.363 2.363 0 0 1-2.363-2.362.394.394 0 0 0-.394-.394H5.906a.394.394 0 0 0-.394.394A2.36 2.36 0 0 1 3.15 6.14a.394.394 0 0 0-.394.394v3.938a.394.394 0 0 0 .394.393 2.363 2.363 0 0 1 2.362 2.363.394.394 0 0 0 .394.394h13.387a.394.394 0 0 0 .394-.394 2.363 2.363 0 0 1 2.362-2.363.394.394 0 0 0 .394-.393V6.535a.394.394 0 0 0-.394-.394m-.394 3.961a3.15 3.15 0 0 0-2.733 2.733H6.276a3.15 3.15 0 0 0-2.733-2.733V6.905a3.15 3.15 0 0 0 2.733-2.732h12.647a3.15 3.15 0 0 0 2.733 2.732z"
    />
    <path
      fill="currentColor"
      d="M12.6 4.955a3.544 3.544 0 1 0 0 7.088 3.544 3.544 0 0 0 0-7.088m0 6.3a2.756 2.756 0 1 1 0-5.511 2.756 2.756 0 0 1 0 5.511"
    />
  </svg>
);
export default SvgMoney;
