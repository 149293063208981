
const LimitedPlacesBadge = ({
    count
}: {
    count?: number
}) => {
    return (
        count ?
            <div className="relative w-max flex items-center">
                <div className="bg-lightTeal h-auto py-1.5 px-2 text-xs sm:text-sm font-semibold">Only {count} places left</div>
                <div className="absolute right-0 top-0 bottom-0 translate-x-full h-full border-s-[16px] border-t-[16px] border-b-[16px] border-s-lightTeal border-t-transparent border-b-transparent" />
            </div>
            :
            null
    )
}

export default LimitedPlacesBadge
