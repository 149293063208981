import { useMutation } from "@tanstack/react-query";
import { ForgotPassword, ForgotPasswordResponse, LoginError } from "src/interfaces/login";
import fetcher from "src/utils/fetcher";
import { toast } from "./use-toast";

function useForgotPassword() {

  const mutation = useMutation<ForgotPasswordResponse, LoginError,  Partial<ForgotPassword>>({
    mutationFn: (Data) => {
      console.log("sending login credentials..", Data);
        const result = fetcher({
          url: "/User/ForgetPassword",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: Data,
        });
        console.log("Fetcher result:", result);
        return result;
    },
    onError: (error) => {
      console.log("isError in sending reset link", error);
      toast({
        title: "Error!",
        variant: "destructive",
        description: "Failed to send reset link! \nPlease try again",
      });
    },
    onSuccess: (data) => {
      console.log("isSuccess in sending link", data);
      toast({
        title: "Success!",
        variant: "success",
        description: "reset link sent successfully",
      });
    },
  });
  return {
    ...mutation,
  };
}

export default useForgotPassword;
