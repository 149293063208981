import { Link } from 'react-router-dom'
import Container from 'src/components/common/Container'
import { buttonVariants } from 'src/components/ui/button'
import illustration from 'src/assets/images/illustrations/download_app.jpg'
import illustrationSmall from 'src/assets/images/illustrations/download_app_small.jpg'
import { useMediaQueries } from 'src/hooks/use-media-query'

const DownloadApp = () => {
    const { xl } = useMediaQueries()
    return (
        <section className='relative w-full bg-lightGreen xl:h-[560px] bg-cover sm:bg-contain overflow-hidden py-16 sm:py-20 xl:py-10'>
            <div className='relative xl:absolute w-full h-[271px] sm:h-[368px] xl:h-[85%] bg-contain overflow-hidden' style={{
                    backgroundImage: xl ? `url(${illustration})` : `url(${illustrationSmall})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }} />
            <Container className='mt-8 xl:mt-0 h-full flex items-center'>
                <div className='h-full w-full bg-cover sm:bg-contain'>
                    <div className="h-full w-full flex flex-col justify-center items-center xl:items-start gap-2 sm:gap-3 xl:gap-7">
                        <h2 className='text-2xl sm:text-3xl xl:text-4xl text-green font-heading font-bold text-center xl:text-start'>Download the App Now!</h2>
                        <p className='text-md sm:text-[20px] xl:text-lg text-black font-bold text-center xl:text-start'>Download the App for better prices!</p>
                        <Link to={''} className={buttonVariants({
                            variant: 'default',
                            className: 'mt-5 mb-10 w-fit max-w-full !px-12 !py-3 h-auto text-sm xl:text-base font-semibold'
                        })}>Get the App</Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default DownloadApp
