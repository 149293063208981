import RatingDetails from "src/components/common/rating/RatingDetails";
import RatingProgress from "src/components/common/rating/RatingProgress";
import { Button } from "src/components/ui/button";


const TourRating = () => {
  const ratings = [
    { label: 'Value for money', value: 4.5 },
    { label: 'Staff', value: 4.1 },
    { label: 'Comfort', value: 3.5 },
    { label: 'Location', value: 3.2 },
    { label: 'Facilities', value: 4.3 },
  ];

  return (
    <div style={{boxShadow: '2px 2px 14px 0px rgba(12, 100, 122, 0.17)'}} className="border-[0.5px] border-darkTeal bg-white px-5 pt-12 pb-10 sm:px-10 sm:pt-[4.5rem] sm:pb-14 rounded-[11px]">
      <div className="w-full flex flex-col gap-8">
        <div className="w-full flex flex-row flex-wrap items-center sm:flex-col sm:items-start gap-4 sm:gap-2 xl:gap-8">
          <h3 className="text-darkTeal font-bold">Rating</h3>
          <div className="ms-auto w-max sm:w-full xl:w-max mb-0 flex justify-start items-center gap-4 flex-wrap">
            <RatingDetails rating={4.5} reviewsCount={132} />
            <div className="w-max ms-auto hidden sm:block xl:hidden">
              <Button type="button" variant={'outline'} className="outline-button px-8">Leave a review</Button>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-8">
          {
            ratings.map((rating, index) => (
              <RatingProgress key={index} label={rating.label} value={rating.value} index={index} />
            ))
          }
        </div>
        <div className="flex sm:hidden w-full xl:flex justify-center xl:justify-end mt-5 xl:mt-10">
          <Button type="button" variant={'outline'} className="outline-button">Leave a review</Button>
        </div>
      </div>
    </div>
  );
};

export default TourRating;
