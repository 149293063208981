import { ArrowLeft } from "lucide-react";
import { ReactNode } from "react";
import { Dialog, DialogTitle, DialogTrigger, DialogContent, DialogClose } from "src/components/ui/dialog";
import { RoomDetails as IRoomDetails } from "src/interfaces/tourDetails";
import RoomDetails from "src/components/accommodation/RoomDetails";
import { ScrollArea } from "src/components/ui/scroll-area";
import ImageGalleryCarousel from "../carousels/ImageGalleryCarousel";


const RoomDetailsDialog = ({
    title,
    trigger,
    images,
    details
}: {
    title: string;
    trigger: ReactNode;
    images: string[];
    details: IRoomDetails;
}) => {
    return (
        <Dialog>
            <DialogTrigger asChild>{trigger}</DialogTrigger>
            <DialogContent className="max-w-[1400px] bg-transparent !rounded-none border-none gap-0 p-0">
                <DialogTitle className="sr-only">Details Dialog</DialogTitle>
                <ScrollArea className="max-h-screen">
                    <div className="bg-darkTeal py-4 px-5 lg:py-5 lg:px-8">
                        <DialogClose asChild>
                            <button className="text-white no-underline flex items-center gap-1.5 font-semibold text-sm">
                                <ArrowLeft className="w-[22px] text-white" /> Back
                            </button>
                        </DialogClose>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 py-4 px-5 lg:py-5 lg:px-8 gap-4 xl:gap-8 bg-white">
                        {/* Gallery */}
                        <div className="w-full h-full">
                            <ImageGalleryCarousel images={images} />
                        </div>
                        {/* Text */}
                        <RoomDetails details={details} />
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}

export default RoomDetailsDialog
