import { LogoApple } from "src/components/icons";
import { LogoInstagram } from "src/components/icons";
import { LogoOutlook } from "src/components/icons";
import { LogoX } from "src/components/icons";

const SocialIcons = () => {
    return (
        <>
            <div className="relative w-max flex justify-center xl:justify-start gap-4 xl:gap-8">
                <button className="p-3 rounded-full border hover:border-green bg-black/80 hover:bg-green text-white">
                    <LogoApple className="w-6 sm:w-7 h-6 sm:h-7" />
                </button>
                <button className="p-3 rounded-full border hover:border-green bg-black/80 hover:bg-green text-white">
                    <LogoInstagram className="w-6 sm:w-7 h-6 sm:h-7" />
                </button>
                <button className="p-3 rounded-full border hover:border-green bg-black/80 hover:bg-green text-white">
                    <LogoX className="w-6 sm:w-7 h-6 sm:h-7" />
                </button>
                <button className="p-3 rounded-full border hover:border-green bg-black/80 hover:bg-green text-white">
                    <LogoOutlook className="w-6 sm:w-7 h-6 sm:h-7" />
                </button>
            </div>
        </>
    );
};

export default SocialIcons;
