// import "../../../styles/tourDetails/priceDetails.css";
import { Button } from "src/components/ui/button";
import PriceDetailsUpgrade from "./PriceDetailsUpgrade";
import { Link } from "react-router-dom";
import CardContainer from "src/components/common/CardContainer";

const PreTripInstallments = () => {
  return (
    <Button type="button" variant="default" className="secondary-button !bg-darkTeal hover:!bg-darkTeal ms-auto rounded-[4px] text-[11px] sm:text-xs xl:text-[13px]">
      Pre-Trip Installments
    </Button>
  )
}

const DatesContent = () => { 
  return (
    <div className="w-full block sm:flex xl:block justify-between items-center gap-8 space-y-4 sm:space-y-0 xl:space-y-4">
      <div className="w-full flex justify-between items-center gap-8 border-b sm:border-b-0 xl:border-b pb-4 sm:pb-0 xl:pb-4 border-greySeparator">
        <div className="text-wrap sm:text-nowrap xl:text-wrap w-full p-0 flex flex-col gap-1 font-semibold text-xs sm:text-sm xl:text-[15px] text-darkBlue">
          <div className="font-normal text-black text-[9px] sm:text-[11px] xl:text-xs">From</div>
          Mon 25 Nov 2024
        </div>
        <div className="mx-auto h-[38px] min-w-px w-px bg-greySeparator"></div>
        <div className="text-wrap sm:text-nowrap xl:text-wrap w-full p-0 flex flex-col gap-1 font-semibold text-xs sm:text-sm xl:text-[15px] text-darkBlue">
          <div className="font-normal text-black text-[9px] sm:text-[11px] xl:text-xs">To</div>
          Mon 08 Dec 2024
        </div>
      </div>
      <div className="hidden sm:block xl:hidden mx-auto h-[38px] min-w-px w-px bg-greySeparator"></div>
      <div className="text-wrap sm:text-nowrap xl:text-wrap max-w-52 w-full flex flex-col gap-1 font-semibold text-xs sm:text-sm xl:text-[15px] text-darkBlue">
        <div className="font-normal text-black text-[9px] sm:text-[11px] xl:text-xs">Total length of stay:</div>
        12 Nights / 13 Days
      </div>
    </div>
  )
}

const TravelersContent = () => { 
  return (
    <div className="space-y-0 flex flex-col sm:flex-row xl:flex-col sm:justify-between xl:justify-start items-start sm:items-center xl:items-start gap-2 xl:gap-3">
      <div className="font-semibold text-xs sm:text-sm xl:text-[15px] text-darkBlue">
        1 room, 2 Adults, 2 Children
      </div>
      <Button variant={'link'} className="text-xs underline font-semibold text-hyperLink hover:text-green active:text-green px-0 py-0 mx-0 h-auto">
        Change Travelers Details
      </Button>
    </div>
  )
}

const PriceSummaryContent = () => { 
  return (
    <div className="divide-y divide-greySeparator flex flex-col gap-0">
      <div className="flex justify-start w-full pb-4">
        <div className="text-normal text-black text-xs xl:text-sm">Price per person</div>
        <div className="ms-auto flex flex-col items-end gap-1">
          <div className="text-green font-medium text-[11px] xl:text-sm text-end">20% Discount</div>
          <div className="line-through text-black font-[275] text-[11px] xl:text-sm text-end">2700$ per person</div>
          <div className="text-darkBlue font-semibold text-[11px] xl:text-sm text-end">
            $2500 per person
          </div>
        </div>
      </div>
      <div className="flex justify-start w-full pt-4">
        <div className="text-normal text-black text-xs xl:text-sm">
          1 room, 2 Adults, 2 Children
        </div>
        <div className="ms-auto flex flex-col items-end gap-1">
          <div className="text-black font-[275] text-[11px] xl:text-sm text-end">
            2 x ($2500 per Adult)
          </div>
          <div className="text-black font-[275] text-[11px] xl:text-sm text-end">
            {" 1 x ($0 per Infant < 2 yrz)"}
          </div>
          <div className="text-black font-[275] text-[11px] xl:text-sm text-end">
            {" 1 x ($1250 per Child < 7 yrz)"}
          </div>
        </div>
      </div>
    </div>
  )
}

const PriceDetailsContent = () => {
  return (
    <>
      <div className="price-details-body">
          <div className="italic text-[10px] sm:text-[11px] xl:text-xs text-darkTeal mt-2 mb-2 sm:mb-4 xl:mb-5">
            The price represented is subjected to the selected dates.
          </div>
          {/* Dates  */}
          <div className="mt-3 mb-2">
            <CardContainer label="Dates" content={DatesContent()} className="border border-borderGrey"  />
          </div>
          {/* Travelers */}
          <div className="mt-3 mb-2 price-details-Travelers">
            <CardContainer label="Travelers" content={TravelersContent()} className="border border-borderGrey"  />
          </div>
          {/* Price summary */}
          <div className="mt-3 mb-2">
            <CardContainer
              label="Price Summary"
              price={<span className="text-[13px] xl:text-sm font-semibold text-darkBlue">$6250</span>}
              content={PriceSummaryContent()}
              className="border border-borderGrey" />
          </div>
          {/* price Upgrade */}
          <PriceDetailsUpgrade />
        </div>
        {/* total price */}
        <div className="bg-lightBlue px-8 -mx-7 mt-4 flex justify-between items-center gap-4 py-5">
          <div className="text-[13px] xl:text-sm font-semibold text-darkBlue">
            Total
            <span className="text-[10px] sm:text-[11px] xl:text-xs font-normal">
              ( including fees and taxes )
            </span>
          </div>
          <div className="text-[13px] xl:text-sm font-semibold">$6250</div>
        </div>
        <div className="italic text-[10px] sm:text-[11px] xl:text-xs text-darkTeal px-2 my-4">
          This price is Website price, please{" "}
          <Link to={''} className="text-hyperLink hover:text-green active:text-green">
            download the mobile app for a chance of better price.
          </Link>
        </div>
        {/* end total price */}
        <div className="my-5">
          <Button variant={'default'} className="primary-button w-full text-sm sm:text-[15px] xl:text-base font-semibold py-[14px] px-16 h-auto">Book Now</Button>
        </div>
    </>
  )
}

const PriceDetails = () => {
  return (
    <div className="price-details-container">
      <CardContainer label="Pricing Details" content={PriceDetailsContent()} button={PreTripInstallments()} />
    </div>
  )
}

export default PriceDetails;
