import { Link } from "react-router-dom"
import SvgAccount from "../icons/Account"
import SvgGlobe from "../icons/Globe"
import SvgLogoHeader from "../icons/LogoHeader"
import SvgMenu from "../icons/Menu"
import Container from "../common/Container"
import { ChevronDown, LogIn } from "lucide-react"

const TopHeader = () => {
    const isLoggedIn = false; // TODO

    return (
        <div className="sticky top-0 z-[40] bg-white text-darkBlue h-auto sm:h-20 w-full flex items-center">
            <Container>
                <div className="py-2 sm:py-4 w-full flex sm:grid sm:grid-cols-3 gap-2 text-sm sm:text-sm xl:text-base">
                    {/* Tablet and up menu toggler */}
                    <div className="hidden w-full sm:flex justify-start items-center">
                        <button>
                            <SvgMenu className="w-8" />
                        </button>
                    </div>
                    {/* Logo */}
                    <div className="w-full flex sm:justify-center">
                        <Link to="/" className="h-10 lg:h-14">
                            <SvgLogoHeader className="h-10 sm:h-12 xl:h-14" />
                        </Link>
                    </div>
                    {/* Control menu */}
                    <div className="w-full flex justify-end items-center gap-3 sm:gap-5 xl:gap-6">
                        <div className="flex justify-end items-center gap-1.5">
                            <button className="flex justify-start items-center gap-1 font-medium">
                                <SvgGlobe className="w-4 sm:w-5" />
                                <span>En</span>
                            </button>
                            <span className="h-[10px] w-[0.5px] bg-darkBlue"></span>
                            <button className="flex justify-start items-center gap-1 font-medium">
                                <span>USD</span>
                                <ChevronDown className="w-2 sm:w-3" />
                            </button>
                        </div>
                        <div className="mt-1 sm:mt-0">
                            <Link to={'/login'} className='hover:no-underline !p-0 text-darkBlue hover:text-green'>
                                {
                                    isLoggedIn ? <SvgAccount className="w-6 sm:w-8" /> : <LogIn className="w-6 sm:w-8" />
                                }
                            </Link>
                        </div>
                        {/* Mobile screen menu toggler */}
                        <div className="flex w-max sm:hidden justify-end items-center">
                            <button>
                                <SvgMenu className="w-[26px]" />
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default TopHeader
