import Container from "src/components/common/Container";
import SortBy from "src/components/common/SortBy"
import FilterBySheet from "./FilterBySheet";
import SortBySelect from "./SortBySelect";


const Header = ({
    sortByOptions
}: {
    sortByOptions: string[]
}) => {

    const handleSortChange = (value: string/*should be enum*/) => {
        // TODO
    };


    return (
        <>
            <Container className="!my-0">
                {/* Desktop */}
                <header className="hidden w-full xl:flex flex-col sm:flex-row gap-5 py-2 border-b border-mediumGrey">
                    <div className="w-full flex items-center gap-2">
                        <label className="text-black">Search results</label>
                        <label className="italic font-normal">Showing 9 out of 56</label>
                    </div>
                    <div className="w-max md:pe-5 ms-auto">
                        <SortBy options={sortByOptions} onChange={handleSortChange} />
                    </div>
                </header>
            </Container>
            {/* Mobile and Tablet */}
            <header className="flex flex-col w-full xl:hidden gap-8 pt-3">
                <div className="flex justify-evenly items-center border-y-[0.5px] border-darkBlue divide-x-[0.5px] divide-darkBlue">
                    <div className="w-full">
                        <FilterBySheet />
                    </div>
                    <div className="w-full text-center">
                        <SortBySelect options={sortByOptions} onChange={handleSortChange} />
                    </div>
                </div>
                <Container className="pb-0">
                    <div className="w-full flex items-center gap-2">
                        <label className="text-black">Search results</label>
                        <label className="italic font-normal">Showing 9 out of 56</label>
                    </div>
                </Container>
            </header>
        </>
    )
}

export default Header
