import { useCallback, useState } from "react";
import SvgDownloadApp from "../icons/DownloadApp";
import { Button } from "../ui/button";
import Container from "../common/Container";
import { X } from "lucide-react";
import { cn } from "src/lib/utils";

const AnnouncementBar = () => {
    const [isAnnouncementVisible, setIsAnnouncementVisible] = useState<boolean>(true);
    const handleCloseAnnouncement = useCallback(() => {
        setIsAnnouncementVisible(false);
    }, []);
    return (
        // isAnnouncementVisible ?
            <div className={cn("relative z-[0] bg-teal text-white text-xs sm:text-[13px] xl:text-sm", {
                "animate-fade-in": isAnnouncementVisible,
                "animate-fade-out": !isAnnouncementVisible,
            })}>
                <Container className="py-6">
                    <div className="relative flex flex-col sm:flex-row justify-center items-center gap-3 xl:gap-4">
                        <div className="flex flex-col items-center sm:flex-row gap-4 sm:gap-20 xl:gap-4">
                            <div className="-ms-[30px] sm:ms-0 flex justify-start items-center gap-4">
                                <SvgDownloadApp className="h-12" />
                                <div className="flex flex-col items-center sm:items-start xl:items-center xl:flex-row gap-1 xl:gap-3">
                                    <span className="text-[17px] sm:text-[19px] xl:text-lg font-bold">
                                        Download the App Now!
                                    </span>
                                    <span className="text-white font-semibold">
                                        Get the Tripvex App for better prices.
                                    </span>
                                </div>
                            </div>
                            <Button variant={'outline'} className="bg-teal ms-0 sm:ms-4 xl:ms-14 me-0 sm:me-5 h-[34px] sm:h-[40px] py-0 sm:py-2 px-8 text-xs sm:text-sm">
                                Download
                            </Button>
                        </div>
                        <button
                            className="absolute top-0 right-0"
                            onClick={handleCloseAnnouncement}>
                            <X className="h-3 xl:h-4" />
                        </button>
                    </div>
                </Container>
            </div>
            // : null
    )
}

export default AnnouncementBar
