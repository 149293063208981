import React from "react";
import { Button } from "src/components/ui/button";
const GmailButton = () => {
    return (
        <Button
            className="border border-white rounded-full h-12 w-[412px] xl:h-14 text-white font-medium text-sm sm:text-base bg-black/40 hover:bg-green transition-all"
            aria-label="Continue With Gmail"
        >
            Continue With Gmail
        </Button>
    );
};

export default GmailButton;
