import { Link } from "react-router-dom";
import SvgLocationPin from "../../icons/LocationPin";
import SvgActivities from "../../icons/Activities";
import { buttonVariants } from "../../ui/button";
import ShareButton from "../../common/buttons/ShareButton";
import FavouriteButton from "../../common/buttons/FavouriteButton";
import HotDealBadge from "../../common/badges/HotDeal";
import DiscountBadge from "../../common/badges/DiscountBadge";
import LimitedPlacesBadge from "../../common/badges/LimitedPlaces";
import RatingDetails from "../../common/rating/RatingDetails";
import StayDetails from "../../common/StayDetails";
import Price from "../../common/Price";
import { Card } from "../../ui/card";
import { TourCardData } from "src/interfaces/tourCard";

const TourCardHorizontal = ({
    data
}: {
    data: TourCardData
}) => {
    const {
        id,
        isHotDeal,
        isOnSale,
        salePercent,
        isLimitedPlaces,
        placesLeft,
        imageSrc,
        title,
        avgRating,
        reviewsCount,
        nightsCount,
        daysCount,
        currency,
        salePrice,
        price,
        locations,
        activities,
    } = data;
    return (
        <Card className="max-w-[95vw] sm:max-w-full mx-auto p-2 rounded-[12px] border-[0.5px] border-teal bg-cardsBG w-full flex flex-col md:flex-row justify-start items-stretch gap-4 xl:hover:shadow-card transition-all ease-out duration-200">
            {/* Image with Badges */}
            <Link
                to={`/tour-packages/${id}`}
                className={'cursor-pointer'}>
                <div className="relative w-full md:max-w-[400px] h-[261px] md:h-full rounded-[8px] overflow-hidden">
                    <img src={imageSrc} alt="cardImage" className="h-full w-full object-cover" />
                    <div className="absolute bottom-5 left-0 flex flex-col gap-2">
                        {
                            isHotDeal ? <HotDealBadge /> : null
                        }
                        {
                            isOnSale ? <DiscountBadge percent={salePercent} /> : null
                        }
                        {
                            isLimitedPlaces ? <LimitedPlacesBadge count={placesLeft} /> : null
                        }
                    </div>
                    {/* Mobile Share & Favourite actions */}
                    <div className="absolute top-5 right-5 flex items-center gap-3 xl:hidden">
                        <ShareButton />
                        <FavouriteButton />
                    </div>
                </div>
            </Link>
            {/* Text */}
            <div className="w-full p-2 sm:p-3 xl:p-4">
                <div className="w-full flex flex-col gap-3">
                    <div className="relative w-full flex justify-between gap-4 items-start">
                        {/* Title */}
                        <Link
                            to={`/tour-packages/${id}`}
                            className={'cursor-pointer'}>
                            <h3 className="text-darkBlue pe-20">{title}</h3>
                        </Link>
                        {/* XL Share & Favourite actions */}
                        <div className="hidden xl:flex items-center gap-3">
                            <ShareButton />
                            <FavouriteButton />
                        </div>
                    </div>
                    {/* Ratings */}
                    <RatingDetails rating={avgRating} reviewsCount={reviewsCount} />
                    {/* Stay & Price */}
                    <div className="flex flex-col gap-2 mb-1">
                        <StayDetails nights={nightsCount} days={daysCount} />
                        <Price currency={currency} sale={salePrice} price={price} />
                    </div>
                    {/* Location & Activities */}
                    <div className="flex flex-col gap-3">
                        <div className="flex items-start gap-2">
                            <SvgLocationPin className="text-teal w-4 mt-1" />
                            <p className="text-black text-xs sm:text-[13px] xl:text-sm">
                                {locations}
                            </p>
                        </div>
                        <div className="flex items-start gap-2">
                            <SvgActivities className="text-teal w-4 mt-1" />
                            <p className="text-black text-xs sm:text-[13px] xl:text-sm">
                                {activities}
                            </p>
                        </div>
                    </div>
                </div>
                {/* View Details Button */}
                <div className="mt-4 flex justify-end">
                    <Link
                        to={`/tour-packages/${id}`}
                        className={
                            buttonVariants({
                                variant: 'link',
                                className: 'text-teal hover:text-green active:text-green text-sm xl:text-base !p-0 font-semibold underline xl:no-underline'
                            })
                        }>
                        View Details
                    </Link>
                </div>
            </div>
        </Card>
    );
};

export default TourCardHorizontal;
