import { Star } from 'lucide-react';
import React from 'react'
import { cn } from 'src/lib/utils';

const RatingStars = ({
    rating,
    className,
}: {
    rating: number;
    className?: string;
}) => {
    const fullStars: any[] = Array.from({ length: Math.floor(rating) }).fill(1);
    const partialStarLength: number = Math.ceil(rating) - Math.floor(rating) > 0 ? 1 : 0;
    const partialStars: any[] = Array.from({ length: partialStarLength }).fill(rating - Math.floor(rating));
    const partialStarValue: number = partialStars[0] * 100;
    const emptyStars: any[] = Array.from({ length: 5 - Math.ceil(rating) }).fill(0);
    
    return (
        <div className={cn("flex items-center gap-0", className)}>
            {
                fullStars.length ?
                    fullStars.map((_, index) => (
                        <Star key={index} className="w-3 h-3 text-darkTeal fill-darkTeal" />
                    ))
                : null
            }
            {
                partialStars.length ?
                    partialStars.map((_, index) => (
                        <div key={index} className="relative w-3 h-3">
                            <Star className="absolute w-3 h-3 text-mediumGrey" />
                            <div className="absolute w-3 h-3 overflow-hidden" style={{clipPath: `polygon(0% 0%, ${partialStarValue}% 0%, ${partialStarValue}% 100%, 0% 100%)`}}>
                                <Star className="w-3 h-3 text-darkTeal fill-darkTeal" />
                            </div>
                        </div>
                    ))
                : null
            }
            {
                emptyStars.length ?
                    emptyStars.map((_, index) => (
                        <Star key={index} className="w-3 h-3 text-mediumGrey fill-none" />
                    ))
                : null
            }
        </div>
    )
}

export default RatingStars
