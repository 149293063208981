import { Dispatch, SetStateAction } from 'react'
import { Button } from 'src/components/ui/button';

const LoadMoreResults = ({
    currentPage,
    setCurrentPage,
    pageCount,
    totalCount
}: {
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    pageCount: number;
    totalCount: number;
}) => {
    return (
        <div className='w-full flex justify-center my-4'>
            <Button variant={'default'} className='secondary-button px-10 py-4 h-auto w-max font-semibold text-sm'>Load More Results</Button>
        </div>
    )
}

export default LoadMoreResults
