import { UseFormReturn } from "react-hook-form"
import FilterCheckboxCard from "src/components/common/FilterCheckboxCard"
import { FilterCheckboxCardOption } from "src/interfaces/filterCheckboxCard"
import { filterFormSchema } from "src/schemas/filter"
import { z } from "zod"

const BudgetPerDayFilter = ({
    form
}: {
    form: UseFormReturn<z.infer<typeof filterFormSchema>, any, undefined>;
}) => {
    const items: FilterCheckboxCardOption[] = [
        {value: '0-200', label: '$ 0 - $ 200'},
        {value: '200-500', label: '$ 200 - $ 500'},
        {value: '500-1000', label: '$ 500 - $ 1000'},
        {value: '1000-2000', label: '$ 1000 - $ 2000'},
        {value: '2000-5000', label: '$ 2000 - $ 5000'},
    ]

    // const 
    
    return (
        <FilterCheckboxCard
            schema={filterFormSchema}
            form={form}
            fieldName="budget"
            options={items}
            label="Your budget per day"
            // children={ }
        />
        
    )
}

export default BudgetPerDayFilter
