import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "src/components/ui/carousel";
import TourCardHorizontal from "src/components/cards/tourCard/TourCardHorizontal";
import { TourCardData } from "src/interfaces/tourCard";
import { DotButtons } from "./carousels/components/DotButtons";
import { useState } from "react";
import { useMediaQueries } from "src/hooks/use-media-query";
const RecentlyViewed = ({
    data
}: {
    data: TourCardData[]
    }) => {
    const [cardsCarouselApi, setCardsCarouselApi] = useState<CarouselApi>()
    const { xl } = useMediaQueries()

    return (
        <div className="pt-8 pb-20">
            <h4 className="text-start mb-4 text-darkBlue text-md">Recently Viewed</h4>
            <div className="w-full flex flex-col gap-6">
                <Carousel setApi={setCardsCarouselApi} opts={{
                align: "start",
                skipSnaps: true
                }} className="slider-visible-overflow-sm w-full mx-auto">
                    <CarouselContent className="w-full mx-auto ml-0 justify-evenly gap-5">
                        {
                            data.map((item) => (
                                <CarouselItem key={item.id} className="basis-auto max-w-full ps-0">
                                    <TourCardHorizontal key={item.id} data={item} />
                                </CarouselItem>
                            ))
                        }
                        
                    </CarouselContent>
                    {/* Dots */}
                    {
                        !xl ?
                            <DotButtons
                                className="xl:hidden"
                                carouselApi={cardsCarouselApi}
                            /> :
                            null
                    }
                </Carousel>
            </div>
        </div>
    );
};

export default RecentlyViewed;
