
const StayDetails = ({
    nights,
    days
}: {
    nights: number;
    days: number;
}) => {
    return (
        <h4 className="text-darkBlue font-semibold">
            {nights} nights / {days} days
        </h4>
    )
}

export default StayDetails
