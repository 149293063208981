import { PropsWithChildren } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import { Outlet } from "react-router-dom";
import { ScrollArea } from "src/components/ui/scroll-area";

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <ScrollArea className="relative w-full max-w-full h-screen [&>div>div]:!block">
      <div>
        <Header /> {/* Header at the top */}
        <main>
          <Outlet /> {/* Placeholder for nested routes */}
        </main>
        <Footer /> {/* Footer at the bottom */}
      </div>
    </ScrollArea>
  );
};

export default Layout;
