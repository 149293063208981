import { useState } from "react";
import SortBy from "src/components/common/SortBy";

import reviewer1 from 'src/assets/images/ui/reviewer-1.jpg';
import reviewer2 from 'src/assets/images/ui/reviewer-2.jpg';
import reviewer3 from 'src/assets/images/ui/reviewer-3.jpg';
import { ChevronLeft, ChevronLeftCircle, ChevronRight, ChevronRightCircle } from "lucide-react";
 
const TourReviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("Latest Rating");
  const [dropdownOpen, setDropdownOpen] = useState(false);

    const sortOptions = [
      "Latest Rating",
      "Highest Rating",
      "Lowest Rating",
  ]

  const reviewsPerPage = 3; // Number of reviews per page
  const reviews = [
    {
        username: "User 1",
        comment:
        "Lorem ipsum dolor sit amet consectetur. Lectus porta quisque tristique ut quis mattis. A sed neque eget eget massa. Elementum velit imperdiet magna blandit in malesuada mollis. Eget.",
        rating: 4.5,
        imgSrc: reviewer1,
    },
    {
        username: "User 2",
        comment: "Lorem ipsum dolor sit amet consectetur. Lectus porta quisque tristique ut quis mattis. A sed neque eget eget massa. Elementum velit imperdiet magna blandit in malesuada mollis. Eget.",
        rating: 5.0,
        imgSrc: reviewer2,

    },
    {
        username: "User 3",
        comment: "Lorem ipsum dolor sit amet consectetur. Lectus porta quisque tristique ut quis mattis. A sed neque eget eget massa. Elementum velit imperdiet magna blandit in malesuada mollis. Eget.",
        rating: 3.2,
        imgSrc: reviewer3,

    },
    {
        username: "User 4",
        comment: "Lorem ipsum dolor sit amet consectetur. Lectus porta quisque tristique ut quis mattis. A sed neque eget eget massa. Elementum velit imperdiet magna blandit in malesuada mollis. Eget.",
        rating: 2.5,
        imgSrc: reviewer1,

    },
    {
        username: "User 5",
        comment: "Lorem ipsum dolor sit amet consectetur. Lectus porta quisque tristique ut quis mattis. A sed neque eget eget massa. Elementum velit imperdiet magna blandit in malesuada mollis. Eget.",
        rating: 4.8,
        imgSrc: reviewer2,

    },
  ];

  // Sort reviews based on selected option
  const sortedReviews = [...reviews].sort((a, b) => {
    if (sortOption === "Highest Rating") return b.rating - a.rating;
    if (sortOption === "Lowest Rating") return a.rating - b.rating;
    return 0; // Default: Last Rating (no sorting applied)
  });

  const totalPages = Math.ceil(sortedReviews.length / reviewsPerPage);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (value: string/*should be enum*/) => {
    alert(value)
    setSortOption(value);
    setDropdownOpen(false);
    setCurrentPage(1); // Reset to first page after sorting
  };

  const renderReviews = () => {
    const startIndex = (currentPage - 1) * reviewsPerPage;
    const endIndex = startIndex + reviewsPerPage;
    
    return sortedReviews.slice(startIndex, endIndex).map((review, index) => (
        <div key={index}
            style={{ boxShadow: '2px 2px 14px 0px rgba(12, 100, 122, 0.17)' }}
            className="flex justify-start gap-3 mb-3 p-3 sm:p-4 lg:p-4 border-[0.5px] border-darkTeal bg-white rounded-[11px]">
            <div className="min-w-[36px] w-[36px] sm:w-[45px] h-[58px] rounded-full overflow-hidden">
                <img src={review.imgSrc} alt="user" className="object-cover" />
            </div>
            <div className="flex flex-col gap-1 mt-2">
                <h6 className="text-darkBlue font-semibold sm:text-[13px] xl:text-sm">{review.username}</h6>
                <p className="text-black text-xs tracking-wider mb-2">{review.comment}</p>
            </div>
        </div>
    ));
  };

  return (
    <>
        <div className="pt-5 flex flex-row justify-between flex-nowrap items-center gap-4">
            <h3 className="w-max sm:w-max font-bold font-main text-darkBlue">
            What travellers said about this trip.
            </h3>
            <div className="w-max ms-auto">
                <SortBy options={sortOptions} onChange={handleSortChange} />
            </div>
        </div>
        {/* Reviews */}
        <div className="py-10 flex flex-col gap-3">
            {renderReviews()}
        </div>
        {/* Pagination */}
        <div className="flex justify-center w-full items-center gap-4">
            <button
                type='button'
                disabled={currentPage === 1}            
                className={`outline-button rounded-full flex justify-center items-center ${
                    currentPage === 1 ? "text-disabled !cursor-default" : ""
                            }`}
                onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            >
                <ChevronLeft className="text-current w-8 h-8 stroke-[2px] p-1 pe-[5px]" />
            </button>
            <div className="flex justify-center items-center gap-2">
                {/* Render Pagination Numbers */}
                {
                    Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            type='button'
                            className={`w-[26px] h-[26px] text-xs rounded-full bg-lightGreen border-none font-semibold text-teal transition-all flex justify-center items-center [&.active]:hover:text-white hover:text-darkTeal ${
                            currentPage === index + 1 ? "active bg-teal text-white" : ""
                            }`}
                            onClick={() => handleClick(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))
                }
            </div>
            <button
                type='button'
                disabled={currentPage === totalPages}
                className={`outline-button rounded-full flex justify-center items-center ${
                    currentPage === totalPages ? "text-disabled !cursor-default" : ""
                }`}
                onClick={() =>
                    currentPage < totalPages && setCurrentPage(currentPage + 1)
                }
            >
            <ChevronRight className="text-current w-8 h-8 stroke-[2px] p-1 ps-[5px]" />
            </button>
        </div>
    </>
  );
};

export default TourReviews;
