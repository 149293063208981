import tourDetails from "src/assets/images/ui/tour-details.jpg";
import package1 from "src/assets/images/ui/package-image-1.jpg";
import package2 from "src/assets/images/ui/package-image-2.jpg";
import package3 from "src/assets/images/ui/package-image-3.jpg";
import DiscountBadge from "src/components/common/badges/DiscountBadge";
import HotDealBadge from "src/components/common/badges/HotDeal";
import LimitedPlacesBadge from "src/components/common/badges/LimitedPlaces";
import FavouriteButton from "src/components/common/buttons/FavouriteButton";
import ShareButton from "src/components/common/buttons/ShareButton";
import RatingDetails from "src/components/common/rating/RatingDetails";
import Price from "src/components/common/Price";
import companyLogo from "src/assets/images/ui/companyLogo.png";
import SvgPets from "src/components/icons/Pets";
import { Button } from "src/components/ui/button";
import { Image } from "lucide-react";
import GalleryDialog from "src/components/common/dialogs/GalleryDialog";

const ViewGallery = () => {
    const isHotDeal = true;
    const isOnSale = true;
    const salePercent = 20;
    const isLimitedPlaces = true;
    const placesLeft = 2;

    // Placeholder for gallery images
    const galleryImages = [
      tourDetails,
      tourDetails,
      tourDetails,
      tourDetails,
    ];

    return (
        <>
            <div className="relative h-full max-h-[465px] mb-2 rounded-[8px] overflow-hidden">
                <img src={tourDetails} alt="cardImage" className="w-full object-cover" />
                <div className="absolute top-5 right-5 flex items-center gap-3">
                    <ShareButton />
                    <FavouriteButton />
                </div>
                <div className="absolute bottom-5 left-0 flex flex-col gap-2">
                    {
                        isHotDeal ? <HotDealBadge /> : null
                    }
                    {
                        isOnSale ? <DiscountBadge percent={salePercent} /> : null
                    }
                    {
                        isLimitedPlaces ? <LimitedPlacesBadge count={placesLeft} /> : null
                    }
                </div>
                <div className="absolute bottom-5 right-4">
                    <GalleryDialog
                        title={'Tour package Details'}
                        backButtonText="Back"
                        images={galleryImages}
                        trigger={
                            <button type='button' className="px-3 py-1 rounded-[4px] bg-white text-teal text-xs sm:text-[13px] xl:text-sm font-semibold flex items-center gap-1.5">
                                <Image className="w-5" /> View Gallery
                            </button>
                        }
                    />
                </div>
            </div>
            <div className="w-full p-4">
                <div className="w-full flex flex-col gap-4">
                    <div className="w-full flex flex-col xl:flex-row xl:justify-between gap-4">
                        <h3 className="text-darkBlue font-bold">3 Nights in Cairo  -  4 Nights in Sharm Al Shaikh</h3>
                        <Price className={'flex sm:hidden xl:flex text-base sm:text-[17px] xl:text-md'} currency={'$'} sale={2700} price={2500} />
                    </div>
                    <div className="w-full flex flex-col items-start justify-start sm:flex-row sm:items-start sm:justify-between gap-4">
                        <div className="text-nowrap text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold w-full flex flex-col xl:flex-row xl:items-center gap-2">
                            Tour Guides Languages:
                            <span className="text-grey font-normal text-xs sm:text-[13px] xl:text-sm">
                                English, French, Italian, German, Korean
                            </span>
                        </div>
                        <div className="w-full flex flex-col justify-end items-end gap-4">
                            <Price className={'hidden sm:flex xl:hidden w-full text-base sm:text-[17px] xl:text-md justify-end'} currency={'$'} sale={2700} price={2500} />
                            <div className="w-full flex justify-between sm:justify-end items-center my-2 sm:my-0 gap-4">
                                <RatingDetails className="text-darkTeal" rating={4.5} reviewsCount={132} />
                                <div className="text-xs sm:text-[13px] xl:text-md text-darkTeal font-semibold flex sm:hidden items-center gap-2">
                                    <SvgPets className="w-4 xl:w-5 text-darkTeal" />
                                    pets allowed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-between gap-2 mb-10">
                        <div className="text-xs text-darkBlue font-semibold w-ful flex flex-col items-start gap-6">
                            Operated by:
                            <img
                                src={companyLogo}
                                alt="company logo"
                                className="h-7 xl:h-8 px-2"
                            />
                        </div>
                        <div className="w-ful flex flex-col justify-end sm:justify-start items-end gap-10">
                            <div className="hidden text-xs sm:text-[13px] xl:text-sm text-darkTeal font-semibold sm:flex items-center gap-2">
                                <SvgPets className="w-4 xl:w-5 text-darkTeal" />
                                pets allowed
                            </div>
                            <div className="flex justify-end">
                                <Button variant={'outline'} className="outline-button px-8 text-xs text-darkTeal !border-darkTeal hover:!text-darkTeal">Save For Later</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewGallery;
