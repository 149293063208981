import * as React from "react";
const SvgTravelers = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 19"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.125 8.125a3.26 3.26 0 0 1-3.265-3.25 3.26 3.26 0 0 1 3.265-3.25 3.26 3.26 0 0 1 3.266 3.25 3.26 3.26 0 0 1-3.266 3.25m3.068.547a4.86 4.86 0 0 0 1.773-4.553C12.644 1.99 10.863.283 8.712.034 5.745-.31 3.228 1.99 3.228 4.875c0 1.536.715 2.904 1.83 3.797-2.74 1.024-4.74 3.43-5.054 6.677a.822.822 0 0 0 1.36.695.8.8 0 0 0 .262-.518c.328-3.626 3.117-5.776 6.5-5.776s6.171 2.15 6.498 5.776a.805.805 0 0 0 .807.724c.485 0 .862-.42.815-.901-.313-3.247-2.313-5.653-5.053-6.677"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.313 10.562a2.283 2.283 0 0 1-2.286-2.275 2.283 2.283 0 0 1 2.286-2.275c1.26 0 2.286 1.02 2.286 2.275a2.28 2.28 0 0 1-2.286 2.275m2.147.383a3.4 3.4 0 0 0 1.241-3.187c-.226-1.492-1.472-2.685-2.978-2.86a3.423 3.423 0 0 0-3.839 3.39c0 1.074.5 2.032 1.281 2.657-1.918.717-3.318 2.401-3.537 4.674a.575.575 0 0 0 .951.487.56.56 0 0 0 .184-.363c.23-2.538 2.182-4.043 4.55-4.043s4.32 1.505 4.55 4.043a.563.563 0 0 0 .564.507.575.575 0 0 0 .57-.631c-.219-2.273-1.619-3.957-3.537-4.674"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M.731 15.844c4.47 2.437 9.425 2.6 14.625 0"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.95 15.844c3.228 1.048 6.807 1.118 10.563 0"
    />
  </svg>
);
export default SvgTravelers;
