import SvgMoney from "../icons/Money";
import { FormControl, FormField, FormItem } from "../ui/form";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { cn } from "src/lib/utils";
import FormError from "../common/FormError";
import { useQuery } from "@tanstack/react-query";
import { PriceRangeList } from "src/interfaces/priceRange";
import axios from "axios";
import { PRICE_RANGE_URL } from "src/utils/constants";
import { useEffect } from "react";
import { toast } from "src/hooks/use-toast";
import fetcher from "src/utils/fetcher";

const PriceRangeField = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
    }) => {
    
    // TODO: make it dynamic
    const currency = '$';
    // Fetch Price Range List
    const {
        data,
        isError,
        isPending
    } = useQuery<PriceRangeList>({
        queryKey: ['price', 'range', 'list'],
        queryFn: () => {
            return fetcher({url: PRICE_RANGE_URL, method: 'post'})
        },
        refetchInterval: 1000 * 60 * 60 * 24,
    })

    console.log('DATA', data)

    useEffect(() => {
        if (isError) {
            toast({
                title: 'Error!',
                variant: 'destructive',
                description: 'Couldn\'t load price range list'
            })
        }
    }, [isError])

    return (
        <FormField
            control={form.control}
            name="price_range"
            render={({ field }) => (
                <FormItem>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                            <SelectTrigger className={cn({'border-red-500': form.getFieldState('price_range').invalid})}>
                                <div className="text-xs sm:text-[13px] xl:text-sm flex justify-start items-center gap-2">
                                    <SvgMoney className="w-7" />
                                    <SelectValue placeholder="Price Range" />
                                </div>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent className="rounded-[16px] max-w-[17rem]">
                                    <SelectGroup className="divide-y divide-gray-400 max-w-[16.5rem] px-4 py-3 min-w-[16rem]">
                                        <SelectLabel className="text-xs sm:text-[13px] xl:text-sm flex items-center px-1 gap-2 py-2">
                                            Total price + taxes and fees
                                        </SelectLabel>
                                        {
                                            data ?
                                            (
                                                data.data?.map(({id, budgetFrom, budgetTo}) => (
                                                    <SelectItem key={id} value={id.toString()} isCheckMarkEnd className="cursor-pointer text-xs sm:text-[13px] xl:text-sm px-1 py-3 rounded-[0px] hover:!bg-lightBlue">
                                                        {`${budgetFrom}${currency} to ${budgetTo}${currency}`}
                                                    </SelectItem>
                                                ))
                                            ) : null
                                        }
                                    </SelectGroup>
                        </SelectContent>
                    </Select>
                    {/* Errors */}
                    <FormError />
                </FormItem>
            )}
        />
        
    );
};

export default PriceRangeField;
