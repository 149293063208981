import React from 'react'
import SvgExcluded from 'src/components/icons/Excluded';
import SvgIncluded from 'src/components/icons/Included';
import { cn } from 'src/lib/utils';

const IncludedExcluded = ({
    text,
    className,
    isIncluded,
}: {
    text: string;
    className?: string; 
    isIncluded?: boolean;
}) => {
    return (
        <div className={cn("flex items-center gap-2 text-xs sm:text-[13px] xl:text-sm text-black font-normal", className)}>
            {
                isIncluded ?
                    <SvgIncluded className="min-w-3 w-3 h-3 sm:min-w-4 sm:w-4 sm:h-4 text-green" />
                    :
                    <SvgExcluded className="min-w-3 w-3 h-3 sm:min-w-4 sm:w-4 sm:h-4 text-darkBlue" />
            }
            <p className='text-inherit'>
                {text}
            </p>
        </div>
    )
}

export default IncludedExcluded
