import { useEffect, useState } from "react";
import { Progress } from "src/components/ui/progress";

const RatingProgress = ({
    label,
    value,
    index
}: {
    label: string;
    value: number;
    index?: number;
}) => {
    const [progress, setProgress] = useState(0)
 
    useEffect(() => {
        const timer = setTimeout(() => setProgress(value), index !== undefined ? ((index + 1) / 3) * 500 : 500)
        return () => clearTimeout(timer)
    }, [])
    
    return (
        <div className="flex flex-col gap-1">
            <div className="flex justify-between mb-1">
                <div className="text-darkBlue text-xs sm:text-[13px] xl:text-sm font-semibold">{label}</div>
                <div className="text-darkTeal text-xs sm:text-[13px] xl:text-sm font-semibold">{value} / 5.0</div>
            </div>
            <Progress className="text-darkTeal h-[11px] rounded-[6px] bg-rating" value={progress/5 * 100} />
        </div>
    )
}

export default RatingProgress
