import { Filter } from "lucide-react";
import {
    Sheet,
    SheetContent,
    SheetTrigger
} from "src/components/ui/sheet";
import BudgetPerDayFilter from "./filter/BudgetPerDayFilter";
import RatingFilter from "./filter/RatingFilter";
import PopularFiltersFilter from "./filter/PopularFiltersFilter";
import ActivitiesFilter from "./filter/ActivitiesFilter";
import { useForm } from "react-hook-form";
import { filterFormSchema } from "src/schemas/filter";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormLabel } from "src/components/ui/form";
import { ScrollArea } from "src/components/ui/scroll-area";

const FilterBySheet = () => {

    function onSubmit(data: z.infer<typeof filterFormSchema>) {
        console.log(data)
    }

    const form = useForm<z.infer<typeof filterFormSchema>>({
        resolver: zodResolver(filterFormSchema),
    })

    return (
        <Sheet>
            <SheetTrigger asChild>
                <button className="w-full flex justify-center items-center gap-2.5 py-4 text-xs font-semibold text-center text-darkTeal">
                    <Filter className="w-[15px] shrink-0" /> Open Filters
                </button>
            </SheetTrigger>
            <SheetContent side="left" className="p-0 max-w-screen w-80">
                <ScrollArea className="h-screen w-full px-5 py-8">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="sticky top-4 w-full">
                            <FormLabel className="font-semibold text-sm tetx-black block mb-3">Filter by</FormLabel>
                            <div className="space-y-5">
                                <BudgetPerDayFilter form={form} />
                                <RatingFilter form={form} />
                                <PopularFiltersFilter form={form} />
                                <ActivitiesFilter form={form} />
                            </div>
                        </form>
                    </Form>
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}

export default FilterBySheet
