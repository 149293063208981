import * as React from "react";
const SvgSmoke = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 13"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.929.452c0-.25.202-.452.452-.452a2.26 2.26 0 0 1 2.262 2.262v.72c0 .628-.29 1.188-.744 1.553a2.71 2.71 0 0 1 1.196 2.25v1.358a.452.452 0 1 1-.904 0V6.786c0-1-.81-1.81-1.81-1.81a.452.452 0 0 1 0-.905h.267a1.09 1.09 0 0 0 1.09-1.09v-.72c0-.749-.607-1.356-1.357-1.356a.45.45 0 0 1-.452-.453M13.57 3.167c-.75 0-1.357.607-1.357 1.357v.822c0 .75.608 1.357 1.357 1.357h1.358c.75 0 1.357.608 1.357 1.358v.082a.452.452 0 1 1-.905 0V8.06a.45.45 0 0 0-.452-.453H13.57a2.26 2.26 0 0 1-2.261-2.262v-.822a2.26 2.26 0 0 1 2.261-2.262.452.452 0 1 1 0 .905M1.357 9.047C.607 9.048 0 9.656 0 10.406v.905c0 .75.608 1.357 1.357 1.357H13.12c.75 0 1.357-.608 1.357-1.357v-.905c0-.75-.607-1.357-1.357-1.357zm-.452 1.358c0-.25.202-.453.452-.453H13.12c.25 0 .452.203.452.453v.905c0 .25-.202.452-.452.452H1.357a.45.45 0 0 1-.452-.453zm15.38-.453a.452.452 0 1 0-.904 0v1.81a.452.452 0 0 0 .905 0zm1.81 0a.452.452 0 1 0-.904 0v1.81a.452.452 0 1 0 .904 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmoke;
