import { Form, FormControl, FormField, FormItem } from "src/components/ui/form";
import { Link } from "react-router-dom";
import { Button, buttonVariants } from "src/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginCredentialsSchema } from "src/schemas/auth";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import { toast } from "src/hooks/use-toast";
import { Eye, EyeOff } from "lucide-react";
import { Input } from "src/components/ui/input";
import { cn } from "src/lib/utils";
import Spinner from "src/components/common/Spinner";
import FormError from "src/components/common/FormError";
import useLogin from "src/hooks/use-login";
import bcrypt from "bcryptjs";

function LoginCredentialsForm() {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutate } = useLogin();
  
  function handleHashing(password: string): string{
    const customSalt = process.env.REACT_APP_SALT;
    if (!customSalt) {
      throw new Error("Missing salt. Ensure REACT_APP_SALT is set in your .env file.");
    }
    const hashedPassword = bcrypt.hashSync(password + customSalt, 10);
    return hashedPassword;
  }

  const form = useForm<z.infer<typeof loginCredentialsSchema>>({
    resolver: zodResolver(loginCredentialsSchema),
  });

  function onSubmit(data: z.infer<typeof loginCredentialsSchema>) {
        const hashedPassword = handleHashing(data.password);
        setIsSubmitting(true);
        mutate(
        {
          name: data.email,
          password: hashedPassword
        },
        {
          onSettled: () => {
            setIsSubmitting(false);
          }
        }
      );
  }

  useEffect(() => {
    if (isSubmitting) {
      if (
        (!form.formState.errors.email || !form.formState.errors.password) &&
        form.formState.submitCount
      ) {
        toast({
          variant: "default",
          title: "🕙 Please wait...",
        });
        setIsSubmitting(false);
      }
    }
  }, [form.formState, isSubmitting]);

  const handleToggleShowPassword = () => {
    const currentType = passwordInputRef.current?.getAttribute("type");
    const isPassword = currentType === "password" ? true : false;
    passwordInputRef.current?.setAttribute(
      "type",
      `${isPassword ? "text" : "password"}`
    );
    setIsPassword(isPassword ? false : true);
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center xl:items-start gap-8 w-full max-w-[480px] mx-auto xl:ms-0 pb-8"
      >
        <div className="form-group relative w-full">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Email"
                    {...field}
                    className={cn(
                      "bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                      { "border-red-500": form.getFieldState("email").invalid }
                    )}
                  />
                </FormControl>
                {/* Errors */}
                <FormError />
              </FormItem>
            )}
          />
        </div>

                <div className="w-full flex flex-col">
                    <div className="form-group relative w-full">
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Password"
                                            {...field}
                                            ref={(e) => {
                                                passwordInputRef.current = e // you can still assign to ref
                                            }}
                                            className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                                { 'border-red-500': form.getFieldState('password').invalid }
                                            )}
                                        />
                                    </FormControl>
                                    {/* Errors */}
                                    <FormError />
                                </FormItem>
                            )} />
                        
                        <div className="absolute right-2 top-[18px] translate-x-[-50%] transition-all ease-in-out duration-0">
                            {
                                isPassword ?
                                (
                                    <EyeOff onClick={() => handleToggleShowPassword()} className="w-6 text-white cursor-pointer" />
                                ): (
                                    <Eye onClick={() => handleToggleShowPassword()} className="w-6 text-white cursor-pointer" />
                                )
                            }
                        </div>
                    </div>
                    <div className="flex w-full justify-end items-center">
                        <Link
                            to="/forgot-password"
                            className={buttonVariants({
                            variant: "link",
                            className: "text-white underline hover:underline xl:hover:underline xl:no-underline text-sm sm:text-[15px] xl:text-base font-semibold",
                            })}
                        >
                            Forgot Password
                        </Link>
                    </div>
                </div>


        <Button
          type="submit"
          variant={"default"}
          disabled={isSubmitting}
          className="primary-button w-full h-14 text-sm sm:text-[15px] xl:text-base font-main font-medium py-4 flex justify-center items-center gap-1.5"
        >
          {isSubmitting ? <Spinner /> : null}
          Sign In
        </Button>
      </form>
    </Form>
  );
}

export default LoginCredentialsForm;
