import { UseFormReturn } from "react-hook-form"
import FilterCheckboxCard from "src/components/common/FilterCheckboxCard"
import { FilterCheckboxCardOption } from "src/interfaces/filterCheckboxCard"
import { filterFormSchema } from "src/schemas/filter"
import { z } from "zod"

const PopularFiltersFilter = ({
    form
}: {
    form: UseFormReturn<z.infer<typeof filterFormSchema>, any, undefined>;
}) => {
    const items: FilterCheckboxCardOption[] = [
        {value: 'free_cancellation', label: 'Free cancellation'},
        {value: 'spa', label: 'Spa'},
        {value: 'beach_front', label: 'Beach front'},
        {value: 'hot_tup_jacuzzi', label: 'Hot tub/jacuzzi'},
        {value: 'book_without_credit_card', label: 'Book without credit card'},
        {value: 'no_prepayment', label: 'No prepayment'},
    ]

    // const 
    
    return (
        <FilterCheckboxCard
            schema={filterFormSchema}
            form={form}
            fieldName="popular_filters"
            options={items}
            label="Popular Filters"
        />
        
    )
}

export default PopularFiltersFilter
