import * as React from "react";
const SvgLogoInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.334 14a4.667 4.667 0 1 1 9.334-.001A4.667 4.667 0 0 1 9.334 14m-2.523 0a7.189 7.189 0 1 0 14.378 0 7.189 7.189 0 0 0-14.378 0m12.983-7.474a1.68 1.68 0 1 0 3.362 0 1.68 1.68 0 0 0-3.362 0m-11.45 18.87c-1.365-.063-2.107-.29-2.6-.482a4.35 4.35 0 0 1-1.61-1.047 4.3 4.3 0 0 1-1.048-1.61c-.192-.492-.42-1.234-.482-2.6-.067-1.475-.081-1.918-.081-5.657s.015-4.18.081-5.658c.063-1.365.291-2.105.482-2.6a4.35 4.35 0 0 1 1.047-1.61c.49-.49.956-.794 1.61-1.047.494-.192 1.236-.42 2.6-.482 1.477-.068 1.92-.081 5.658-.081s4.18.015 5.658.081c1.364.062 2.105.29 2.6.482a4.34 4.34 0 0 1 1.61 1.047c.49.49.793.957 1.047 1.61.192.494.42 1.235.482 2.6.068 1.477.081 1.92.081 5.658s-.013 4.18-.081 5.658c-.062 1.365-.29 2.107-.482 2.6a4.34 4.34 0 0 1-1.047 1.609c-.49.49-.957.793-1.61 1.047-.494.192-1.236.42-2.6.482-1.476.067-1.92.081-5.658.081s-4.181-.014-5.657-.081M8.228.085C6.738.153 5.72.389 4.83.735A6.9 6.9 0 0 0 2.35 2.35 6.85 6.85 0 0 0 .735 4.83C.39 5.72.153 6.738.085 8.228.015 9.721 0 10.198 0 14s.016 4.28.085 5.772c.068 1.49.304 2.508.65 3.398.357.92.836 1.702 1.615 2.48a6.9 6.9 0 0 0 2.48 1.615c.891.346 1.908.582 3.398.65 1.494.068 1.97.085 5.773.085s4.279-.016 5.772-.085c1.49-.068 2.508-.304 3.398-.65.92-.358 1.7-.837 2.48-1.615a6.9 6.9 0 0 0 1.615-2.48c.346-.89.584-1.908.65-3.398.068-1.494.084-1.97.084-5.772s-.016-4.28-.084-5.772c-.067-1.49-.304-2.508-.65-3.398a6.9 6.9 0 0 0-1.615-2.48A6.9 6.9 0 0 0 23.172.735c-.891-.346-1.909-.583-3.398-.65C18.28.017 17.804 0 14.002 0c-3.804 0-4.28.016-5.774.085"
    />
  </svg>
);
export default SvgLogoInstagram;
