import { CheckCircle } from "lucide-react";
import SvgBeds from "src/components/icons/Beds";
import SvgCutlery from "src/components/icons/Cutlery";
import SvgHomeSpace from "src/components/icons/HomeSpace";
import SvgPets from "src/components/icons/Pets";
import SvgRoomMates from "src/components/icons/RoomMates";
import SvgSmoke from "src/components/icons/Smoke";
import { RoomDetails as IRoomDetails } from "src/interfaces/tourDetails";


const RoomDetails = ({
    details,
}: {
    details: IRoomDetails;
}) => {
    const {
        title,
        subtitle,
        isIncluded,
        currency,
        price,
        extraNights,
        homeSpace,
        beds,
        adults,
        children,
        isAllInclusive,
        isPetsAllowed,
        isSmokingAllowed,
        facilities
    } = details;
    return (
    <>
        <div className='w-full h-full border border-grey rounded-[10px] overflow-hidden'>
            <div className='bg-lightBlue w-full flex flex-col items-start sm:flex-row sm:justify-start sm:items-center gap-4 sm:gap-6 px-4 sm:px-5 xl:px-6 py-3 sm:py-4 xl:py-5 sm:divide-x-[0.5px] divide-darkBlue' >
                <div className='flex flex-col gap-0 sm:py-2'>
                    <h1 className='font-main text-[28px] sm:text-3xl xl:text-[32px] text-darkBlue font-bold'>{title}</h1>
                    <h2 className='font-main text-md sm:text-lg xl:text-[22px] text-darkTeal font-bold'>{subtitle}</h2>
                </div>
                <div className='flex flex-col gap-2 sm:gap-4 sm:ps-6 py-2 sm:py-2'>
                    <div className='text-[10px] sm:text-[11px] xl:text-xs text-darkBlue font-bold'>Price:</div>
                    <h4 className='text-base sm:text-[17px] xl:text-md text-darkBlue font-semibold'>
                        {isIncluded ? 'Included in package' : `${currency}${price} Extra for ${extraNights} nights`}        
                    </h4>
                </div>
            </div>
            
            <div className="px-4 sm:px-5 xl:px-6 py-3 sm:py-4 xl:py-5 flex flex-col gap-8 mt-3">
                {/* Room details */}
                <div className='flex flex-row flex-wrap gap-x-12 gap-y-4'>
                    {
                        homeSpace ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgHomeSpace className="text-green h-4 max-w-[35px]" />
                                {homeSpace} <span>m<sup>2</sup></span>
                            </div>
                        ): null        
                    }
                    {
                        beds ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgBeds className="text-green h-4 max-w-[35px]" />
                                {beds} {`${ beds === 2 ? 'Twin': ''}`} Beds
                            </div>
                        ): null        
                    }    
                    {
                        adults || children ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgRoomMates className="text-green h-4 max-w-[35px]" />
                                Suitable for {adults} Adults - {children} Children
                            </div>
                        ): null        
                    }
                    {
                        isAllInclusive ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgCutlery className="text-green h-4 max-w-[35px]" />
                                All inclusive
                            </div>
                        ): null        
                    }
                    {
                        isPetsAllowed ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgPets className="text-green h-4 max-w-[35px]" />
                                Pets Allowed
                            </div>
                        ): null        
                    }
                    {
                        isSmokingAllowed ? (
                            <div className='flex flex-row items-center gap-1.5 text-xs sm:text-[13px] xl:text-sm'>
                                <SvgSmoke className="text-green h-4 max-w-[35px]" />
                                Smoking Allowed
                            </div>
                        ): null        
                    }
                </div>
                {/* Facilities grid */}
                <div className='flex flex-col gap-4'>
                    <div className='text-darkBlue text-xs sm:text-[13px] xl:text-sm font-semibold'>
                        Facilities
                    </div>
                    <div className='flex justify-between '>
                        <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-4 gap-4'>
                            {
                                facilities.length ? (
                                    facilities.map(facility => (
                                        <div className='flex items-center'>
                                            <CheckCircle className="text-green w-4 mr-2"/> <span className='text-[10px] sm:text-[11px] xl:text-xs'>{facility}</span> 
                                        </div>
                                    ))
                                ) : null       
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default RoomDetails;
