import SvgFavourite from '../../icons/Favourite'

const FavouriteButton = () => {
    // Logic will be here using props to be defined later

    return (
        <button className="w-8 h-8 sm:w-9 sm:h-9 xl:w-11 xl:h-11">
            <SvgFavourite className="rounded-full border border-teal hover:border-green text-teal fill-white hover:text-white hover:fill-green" />
        </button>
    )
}

export default FavouriteButton