import Search from "src/components/search";
import TourPackages from "../../components/common/TourPackages";
import Trending from "./Trending";
import QuickNavigationBar from "src/components/layout/QuickNavigationBar";
const Home = () => {
  return (
    <>
      {/* Quick Navigation Bar */}
      <QuickNavigationBar />
      {/* Search */}
      <Search className="search-bg" />
      {/* Top Rated Tour Packages */}
      <TourPackages />
      {/* Trending Now */}
      <Trending />
    </>
  );
};

export default Home;
