import { ChevronDown, PencilLine, Trash2 } from "lucide-react";
import { useState } from "react";

const UpgradeCard = ({
    title,
    items,
    currency,
    price,
}: {
    title: string;
    items: string[];
    currency: string;
    price: number;
}) => {
    const handleEdit = () => {}
    const handleDelete = () => {}
    
    return (
        <div className="p-3 sm:p-4 border border-borderGrey rounded-[8px]">
            <div className="w-full flex gap-4 justify-between items-center pb-2 mb-2 border-b border-borderGreyLight">
                <button type="button" className="!p-0">
                    <PencilLine className="w-4 h-4 sm:w-5 sm:h-5 text-teal hover:text-green" onClick={() => handleEdit()} />
                </button>
                <button type="button" className="!p-0">
                    <Trash2 className="w-4 h-4 sm:w-5 sm:h-5 text-teal hover:text-green" onClick={() => handleDelete()} />
                </button>
            </div>
            <div className="w-full flex justify-start mt-2">
                <div className="text-[10px] sm:text-[11px] xl:text-xs text-black">
                    {title}
                </div>
                <ul className="ms-auto w-full flex flex-col items-end gap-1">
                    {
                        items.length ? (
                            items.map(item => (
                                <li className="text-[10px] sm:text-[11px] xl:text-xs text-black font-[275]">{item}</li>
                            ))
                        ): null
                    }
                </ul>
            </div>
            <div className="mt-4 mb-2 flex justify-end items-center text-xs sm:text-[13px] xl:text-sm font-semibold text-darkBlue">
                {currency}{price}
            </div>
        </div>
    )
}

const PriceDetailsUpgrade = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // State to manage dropdown visibility

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="mt-4 mb-5 border-y border-borderGreyLight">
      <div
        className="cursor-pointer flex justify-between price-upgrade-dropdown px-2 py-4"
        onClick={toggleDropdown}>
        <div className="text-xs xl:text-sm font-semibold text-darkBlue flex items-center gap-1">
            Upgrades (2)
            <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5 text-darkBlue" />
        </div>
        <div className="text-sm sm:text-[15px] xl:text-base font-semibold text-darkBlue">$205</div>
      </div>
      {
        isDropdownVisible ? (
            <div className="w-full flex flex-col gap-3 mb-4">
                <UpgradeCard
                    title="Day 1 - 25 Oct. 2024, activity name lorem ipsum, 2 Adults, 4 children"
                    items={[
                        "2 x ($30 per Adult)",
                        "1 x ($0 per Infant < 2 yrz)",
                        "1 x ($10 per Child < 7 yrz))",
                        "1 x ($20 per Child < 16 yrz)",
                        "1 x ($25 per teen < 18 yrz)",
                    ]}
                    currency="$"
                    price={105}
                />
                <UpgradeCard
                    title="Day 1 - 25 Oct. 2024, activity name lorem ipsum, 2 Adults, 4 children"
                    items={[
                        "2 x ($30 per Adult)",
                        "1 x ($0 per Infant < 2 yrz)",
                        "1 x ($10 per Child < 7 yrz))",
                        "1 x ($20 per Child < 16 yrz)",
                        "1 x ($20 per teen < 18 yrz)",
                    ]}
                    currency="$"
                    price={100}
                />
            </div>
        ): null
      }
    </div>
  );
};

export default PriceDetailsUpgrade;
