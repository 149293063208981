import Search from "src/components/search";
import PackageImage1 from "src/assets/images/ui/package-image-1.jpg";
import PackageImage2 from "src/assets/images/ui/package-image-2.jpg";
import PackageImage3 from "src/assets/images/ui/package-image-3.jpg";
import { TourCardData } from "src/interfaces/tourCard";
import AppliedFilters from "./AppliedFilters";
import Container from "src/components/common/Container";
import Header from "./Header";
import MainContent from "./MainContent";
import TourPackages from "src/components/common/TourPackages";
import RecentlyViewed from "../../components/common/RecentlyViewed";
import QuickNavigationBar from "src/components/layout/QuickNavigationBar";

function SearchResults() {

  const appliedFilters = [
    'Pets Allowed'
  ]

  const sortByOptions: string[] = [
        'Best Value',
        'Rating +4 Stars',
        'Most Destinations',
        'Length of Stay'
  ];

  const searchResults: TourCardData[] = [
        {
            id: 1,
            imageSrc: PackageImage1,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Long Stay Vacation in El Quseir',
            avgRating: 4.5,
            reviewsCount: 132,
            nightsCount: 12,
            daysCount: 13,
            currency: '$',
            salePrice: 2700,
            price: 2500,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 2,
            imageSrc: PackageImage2,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            title: 'A Legendary Vacation',
            avgRating: 4,
            reviewsCount: 172,
            nightsCount: 10,
            daysCount: 11,
            currency: '$',
            salePrice: 2500,
            price: 2250,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 3,
            imageSrc: PackageImage3,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Movenpick Royal Lily & Lotus Nile Cruises',
            avgRating: 4.8,
            reviewsCount: 15,
            nightsCount: 9,
            daysCount: 10,
            currency: '$',
            price: 2300,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        }
  ]

  const recentlyViewedItems: TourCardData[] = [
        {
            id: 1,
            imageSrc: PackageImage1,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Long Stay Vacation in El Quseir',
            avgRating: 4.5,
            reviewsCount: 132,
            nightsCount: 12,
            daysCount: 13,
            currency: '$',
            salePrice: 2700,
            price: 2500,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 2,
            imageSrc: PackageImage2,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            title: 'A Legendary Vacation',
            avgRating: 4,
            reviewsCount: 172,
            nightsCount: 10,
            daysCount: 11,
            currency: '$',
            salePrice: 2500,
            price: 2250,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 3,
            imageSrc: PackageImage3,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Movenpick Royal Lily & Lotus Nile Cruises',
            avgRating: 4.8,
            reviewsCount: 15,
            nightsCount: 9,
            daysCount: 10,
            currency: '$',
            price: 2300,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        }
    ]
  
  const handleRemoveFilter = () => {
    
  }

  return (
    <>
      {/* Quick Navigation Bar */}
      <QuickNavigationBar />
      {/* Search */}
      <Search className="search-bg" />
      {/* Package main content */}
      <div className="w-full space-y-0 xl:space-y-4 my-8 bg-white">
        <Container>
          {/* Applied Filters */}
          <AppliedFilters filters={appliedFilters} handleRemoveFilter={handleRemoveFilter} />
        </Container>

        {/* Header */}
        <Header sortByOptions={sortByOptions} />

        <Container>
          {/* Main */}
          <MainContent results={searchResults} />
          {/* Top Rated Tour Packages */}
          <TourPackages />
          {/* Recently Viewed */}
          <RecentlyViewed data={recentlyViewedItems} />
        </Container>
      </div>
    </>
  );
}

export default SearchResults;
