import * as React from "react";
const SvgHomeSpace = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.7 16.15h11.05a.85.85 0 0 0 1.7 0v-1.7a.85.85 0 0 0-1.7 0H1.7a.85.85 0 0 0-1.7 0v1.7a.85.85 0 0 0 1.7 0m0-8.998V11.9c0 .47.38.85.85.85h8.5c.47 0 .85-.38.85-.85V7.152l.249.25a.85.85 0 0 0 1.202-1.203L7.401.25a.85.85 0 0 0-1.202 0L.249 6.2a.85.85 0 0 0 1.202 1.202zM16.15 10.2V2.55a.85.85 0 0 0 0-1.7h-1.7a.85.85 0 0 0 0 1.7v7.65a.85.85 0 0 0 0 1.7h1.7a.85.85 0 0 0 0-1.7M3.4 5.452v5.598h6.8V5.452l-3.4-3.4z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHomeSpace;
