import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { EmblaCarouselType } from 'embla-carousel';
import { cn } from 'src/lib/utils';

type UseDotButtonType = {
	selectedIndex: number
	scrollSnaps: number[]
	onDotButtonClick: (index: number) => void
}

export const useDotButton = (
	emblaApi: EmblaCarouselType | undefined,
	onButtonClick?: (emblaApi: EmblaCarouselType) => void
): UseDotButtonType => {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

	const onDotButtonClick = useCallback(
		(index: number) => {
		if (!emblaApi) return
		emblaApi.scrollTo(index)
		if (onButtonClick) onButtonClick(emblaApi)
		},
		[emblaApi, onButtonClick]
	)

	const onInit = useCallback((emblaApi: EmblaCarouselType) => {
		setScrollSnaps(emblaApi.scrollSnapList())
	}, [])

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
		setSelectedIndex(emblaApi.selectedScrollSnap())
	}, [])

	useEffect(() => {
		if (!emblaApi) return

		onInit(emblaApi)
		onSelect(emblaApi)

		emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
	}, [emblaApi, onInit, onSelect])

	return {
		selectedIndex,
		scrollSnaps,
		onDotButtonClick
  }
}

interface DotButtonsProps extends ComponentPropsWithRef<'button'> {
	carouselApi: EmblaCarouselType | undefined,
}

type PropType = DotButtonsProps

export const DotButtons: React.FC<PropType> = (props) => {
    const { children, className, carouselApi, ...restProps } = props
	const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(carouselApi)

    return (
      <div className="w-full flex justify-center mt-4 items-center gap-2.5 xl:gap-4">
          {
              scrollSnaps.map((_: any, index: number) => (
				<button 
				key={index} 
				type="button" 
				className={cn('w-2.5 h-2.5 xl:w-4 xl:h-4 rounded-full bg-rating', {
                    'bg-teal': index === selectedIndex
				},
				className)} 
				onClick={() => onDotButtonClick(index)}
				{...restProps}>
					{children}
				</button>
              ))
          }
      </div>
      
    )
}
