import { ChevronLeft, ChevronRight, Image } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "src/components/ui/carousel"
import { LazyLoadImage } from "./components/LazyLoadImage";
import { EmblaCarouselType } from 'embla-carousel'
import GalleryDialog from "../dialogs/GalleryDialog";

const ImageGalleryCarousel = ({
    images
}: {
    images: string[];
}) => {
    const [carouselApi, setCarouselApi] = useState<CarouselApi>()
    const [slidesInView, setSlidesInView] = useState<number[]>([])
    const [current, setCurrent] = useState(0)
    const [count, setCount] = useState(0)

    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off('slidesInView', updateSlidesInView)
            }
            const inView = emblaApi
                .slidesInView()
                .filter((index) => !slidesInView.includes(index))
            return slidesInView.concat(inView)
        })
    }, [])
    
    useEffect(() => {
        if (!carouselApi) return;
    
        setCount(carouselApi.scrollSnapList().length);
        setCurrent(carouselApi.selectedScrollSnap());
    
        carouselApi.on("select", () => {
            setCurrent(carouselApi.selectedScrollSnap())
        })

        updateSlidesInView(carouselApi)
        carouselApi.on('slidesInView', updateSlidesInView)
        carouselApi.on('reInit', updateSlidesInView)

    }, [carouselApi, updateSlidesInView])
    return (
        <Carousel setApi={setCarouselApi} className="relative rounded-[10px] sm:rounded-[20px] xl:rounded-[30px] overflow-hidden" opts={{
            skipSnaps: true,
            loop: true,
            duration: 10,
            slidesToScroll: 1,
        }}>
            <CarouselContent className={"carousel-content !ml-0 w-full !h-full !min-h-full"}>
                {
                    images.length ?
                        images.map((image, index) => (
                            <CarouselItem key={`image-${index}`} className="remove-px w-full relative overflow-hidden">
                                <LazyLoadImage
                                    title={image}
                                    className="carousel-image h-[500px] w-full"
                                    index={index}
                                    imgSrc={image}
                                    inView={slidesInView.indexOf(index) > -1}
                                />
                            </CarouselItem>
                        ))
                    : null
                }
            </CarouselContent>
            <div className="absolute top-1/2 inset-x-4 px-5 -translate-y-1/2">
                <button
                    onClick={() => carouselApi?.scrollPrev()}
                    disabled={carouselApi?.canScrollPrev() === false}
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 left-0 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                    <ChevronLeft className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                </button>
                <button
                    onClick={() => carouselApi?.scrollNext()}
                    disabled={carouselApi?.canScrollNext() === false}
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 right-0 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                    <ChevronRight className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                </button>
            </div>
            
            <div className="absolute bottom-5 right-5">
                <GalleryDialog
                    title={'Tour package Details'}
                    backButtonText="Back"
                    images={images}
                    trigger={
                        <button type='button' className="px-3 py-1 rounded-[4px] bg-white text-teal text-xs sm:text-[13px] xl:text-sm font-semibold flex items-center gap-1.5">
                            <Image className="w-5" /> View Gallery
                        </button>
                    }
                />
            </div>
        </Carousel>
    )
}

export default ImageGalleryCarousel
