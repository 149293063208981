import { ReactNode } from "react"
import { Card, CardContent, CardHeader } from "src/components/ui/card"
import { cn } from "src/lib/utils";

const CardContainer = ({
    label,
    button,
    price,
    content,
    className
}: {
    label: string;
    button?: ReactNode;   
    price?: ReactNode;
    content: ReactNode; 
    className?: string;
}) => {
    return (
        <Card className={cn("w-full overflow-hidden rounded-[11px] border-[.5px] border-darkBlue", className)}>
            <CardHeader className="pt-4 pb-3 px-5 bg-lightTeal w-full flex flex-row justify-between items-center flex-wrap gap-4">
                <label className="w-max text-darkBlue text-[13px] sm:text-sm font-semibold">
                    {label}
                </label> 
                {price ? price : null}
                {button ? button : null}
            </CardHeader>
            <CardContent className={"my-2 px-5 py-2"}>
                {content ? content : null}
            </CardContent>
        </Card>
    )
}

export default CardContainer
