import { TransportationTab, TransportationTabVariant } from "src/interfaces/tabs";
import tourDetails from "src/assets/images/ui/tour-details.jpg";
import trending1 from "src/assets/images/ui/trending-1.jpg";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import ButtonCarousel from "src/components/common/carousels/ButtonCarousel";
import { CarouselItem } from "src/components/ui/carousel";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Label } from "src/components/ui/label";
import { useEffect, useState } from "react";
import DetailsDialog from "src/components/common/dialogs/DetailsDialog";

const TabsContentComponent = ({
    content,
}: {
    content: TransportationTabVariant[];
}) => {
    const [selectedOptionImage, setSelectedOptionImage] = useState<string>(content[0].coverImage);
    const [selectedOptionTitle, setSelectedOptionTitle] = useState<string>(content[0].title);

    const updateSelected = (option: { title: string; imageSrc: string; }) => {
        setSelectedOptionImage(option.imageSrc);
        setSelectedOptionTitle(option.title);
    }

    useEffect(() => {
        setSelectedOptionImage(content[0].coverImage);
        setSelectedOptionTitle(content[0].title);
    }, [content])

    const images = [
        tourDetails,
        tourDetails,
        tourDetails,
        tourDetails,
    ];

    return (
        <div className="flex flex-col md:flex-row gap-4 mt-5 py-5">
            <div className="w-full md:max-w-72 max-h-72 rounded-[20px] overflow-hidden">
                <img src={selectedOptionImage} alt={selectedOptionTitle} className="w-full h-full object-cover" />
            </div>
            <div className="w-full">
                <div className="mt-5 xl:ms-5 flex flex-col gap-5">
                    <div className="flex flex-col gap-2">
                        <RadioGroup defaultValue={`option-0`} className="flex flex-col gap-3.5">
                            {
                                content.map((option, index) => (
                                    <div key={option.title} className="flex items-center space-x-2 mb-2">
                                        <RadioGroupItem onClick={() => { updateSelected({ title: option.title, imageSrc: option.coverImage }); }}
                                            value={`option-${index}`}
                                            id={`option-${index}`} />
                                        <Label htmlFor={`option-${index}`} className="flex items-center gap-1">
                                            <DetailsDialog
                                                title={option.title}
                                                images={images}
                                                trigger={
                                                    <div className="max-w-full text-xs sm:text-[13px] xl:text-sm leading-normal font-semibold underline text-darkBlue hover:text-green cursor-pointer">
                                                        {option.title} 
                                                    </div>
                                            } />
                                            
                                            {
                                                option.price ? 
                                                    (
                                                        <span className="font-normal italic text-teal text-nowrap text-xs sm:text-[13px] xl:text-sm">
                                                            ({option.currency} {option.price} extra)
                                                        </span>
                                                    )
                                                    :
                                                    (
                                                        <span className="font-normal italic text-teal text-nowrap text-xs sm:text-[13px] xl:text-sm">
                                                            (included)
                                                        </span>
                                                    )
                                            }
                                        </Label>
                                    </div>
                                ))
                            }
                        </RadioGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Transportation = () => {
    const transportationTabs: TransportationTab[] = [
        {
            label: "Cairo",
            value: "cairo",
            sublabel: "3 nights / 4 days",
            content: [
                {
                    coverImage: trending1,
                    title: "Type of transportation ex: air conditioned van.",
                    price: 100,
                    currency: '$'
                    
                },
                {
                    coverImage: tourDetails,
                    title: "Type of transportation 1",
                    price: 200,
                    currency: '$'
                    
                },
                {
                    coverImage: tourDetails,
                    title: "Type of transportation 2",
                    price: 300,
                    currency: '$'
                    
                },
            ]
        },
        {
            label: "Alexandria",
            value: "alexandria",
            sublabel: "3 nights / 4 days",
            content: [
                {
                    coverImage: tourDetails,
                    title: "Type of transportation ex: air conditioned van.",
                    price: 100,
                    currency: '$'
                    
                },
                {
                    coverImage: trending1,
                    title: "Type of transportation 1",
                    price: 200,
                    currency: '$'
                    
                },
            ]
        },
        {
            label: "Sharm Al Sheikh",
            value: "sharm-al-sheikh",
            sublabel: "3 nights / 4 days",
            content: [
                {
                    coverImage: trending1,
                    title: "Type of transportation ex: air conditioned van.",
                    price: 100,
                    currency: '$'
                    
                },
            ]
        },
    ]

    const [tabs, setTabs] = useState<TransportationTab[]>(transportationTabs)
    
    return (
            transportationTabs.length ? 
                <Tabs onValueChange={() => setTabs(transportationTabs)} defaultValue={tabs[0].value} className="w-full !rounded-none">
                    <TabsList className="w-full h-auto bg-transparent !rounded-none">
                        <ButtonCarousel>
                            {
                                tabs.map((tab) => (
                                    <CarouselItem key={tab.value} className="basis-auto relative overflow-hidden">
                                        <TabsTrigger key={tab.value} value={tab.value} className="flex flex-col py-3 px-8 rounded-[3px] justify-center items-center gap-1 text-darkBlue text-[13px] sm:text-sm xl:text-[15px] bg-white border-[0.7px] border-teal data-[state=active]:bg-green data-[state=active]:text-white data-[state=active]:border-green">
                                            <span className="text-inherit font-semibold text-[13px] sm:text-sm xl:text-[15px]">{tab.label}</span>
                                            <span className="text-inherit font-medium text-xs">{tab.sublabel}</span>
                                        </TabsTrigger>
                                    </CarouselItem>
                                ))
                            }
                        </ButtonCarousel>
                    </TabsList> 
                    {
                        tabs.map((tab, index) => (
                            <TabsContent key={`tab-content-${index}`} value={tab.value}>
                                {
                                    TabsContentComponent({ content: tab.content})
                                }
                            </TabsContent>
                        ))
                    }
                </Tabs>
            : null
    );
};

export default Transportation;
