import { z } from "zod";

export const searchFormSchema = z.object({
    departure: z.string({
        required_error: "Please select a valid departure city.",
    }),
    travel_date: z.string({
        required_error: "Please select your travel date.",
    }).nonempty("Please select your travel date."),
    travelers: z.string({
        required_error: "Please set the number of travelers.",
    }).nonempty("Please set the number of travelers."),
})