import { cn } from "src/lib/utils";

const price = ({
    currency,
    sale,
    price,
    className
}: {
    currency: string;
    sale?: number;
    price: number;
    className?: string;
}) => {
    return (
        <h4 className={cn("text-darkBlue font-semibold", className)}>
            {
            sale ?  
                <>
                    <span className="font-light line-through">{currency}{sale}</span> / {currency}{price} per person
                </>
                :
                <>
                    {currency}{price} per person
                </>
            }
        </h4>
    )
}

export default price
