import { ChevronLeft, ChevronRight } from "lucide-react";
import { Dispatch, useState } from "react";
import "../../styles/common/calendar.css";

const Calendar = ({
    setSelectedDate,
    selectedDate
}: {
    selectedDate: Date | null;
    setSelectedDate: Dispatch<React.SetStateAction<Date | null>>;
}) => {

    const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(
        new Date().getMonth()
    );
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

    // Handles date selection
    const handleDateClick = (date: Date) => {
        setSelectedDate(date);
    };


    // Navigate to the previous month
    const goToPrevMonth = () => {
        if (currentMonthIndex === 0) {
        setCurrentMonthIndex(11);
        setCurrentYear(currentYear - 1);
        } else {
        setCurrentMonthIndex(currentMonthIndex - 1);
        }
    };

    // Navigate to the next month
    const goToNextMonth = () => {
        if (currentMonthIndex === 11) {
        setCurrentMonthIndex(0);
        setCurrentYear(currentYear + 1);
        } else {
        setCurrentMonthIndex(currentMonthIndex + 1);
        }
    };

    // Get all days in a specific month
    const getDaysInMonth = (year: number, month: number) => {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
        }
        return days;
    };

    // Get current and next month days
    const currentMonthDays = getDaysInMonth(currentYear, currentMonthIndex);
    const nextMonthIndex = currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
    const nextYear = currentMonthIndex === 11 ? currentYear + 1 : currentYear;
    const nextMonthDays = getDaysInMonth(nextYear, nextMonthIndex);

    // Format the month and year for display
    const formatMonthYear = (year: number, month: number) => {
        return new Date(year, month).toLocaleString("default", {
        month: "long",
        year: "numeric",
        });
    };
    
    return (
        <div className="calendars-wrapper grid grid-cols-1 xl:grid-cols-2 gap-8">
            <div className="calendar p-2 sm:p-4">
                {/* Calendar Header */}
                <div className="calendar-header">
                    <div className="w-full flex items-center gap-0">
                        <button type="button" className="nav-button prev" onClick={goToPrevMonth}>
                            <ChevronLeft className="w-4 text-teal" />
                        </button>

                            <div className="month-name">
                                {formatMonthYear(currentYear, currentMonthIndex)}
                            </div>

                        <button type="button" className="nav-button next xl:hidden" onClick={goToNextMonth}>
                            <ChevronRight className="w-4 text-teal" />
                        </button>
                    </div>
                </div>

                {/* Calendar Body */}
                <div className="calendar-body">
                        {/* Current Month */}
                        <div className="month-column">
                            <div className="days-of-week">
                                <div className="day-header">Sun</div>
                                <div className="day-header">Mon</div>
                                <div className="day-header">Tue</div>
                                <div className="day-header">Wed</div>
                                <div className="day-header">Thu</div>
                                <div className="day-header">Fri</div>
                                <div className="day-header">Sat</div>
                            </div>
                            <div className="days-row">
                                {currentMonthDays.map((date) => (
                                <div
                                    key={date.toDateString()}
                                    className={`day ${
                                    selectedDate &&
                                    date.toDateString() === selectedDate.toDateString()
                                        ? "selected"
                                        : ""
                                    } ${
                                        date.toDateString() === new Date().toDateString() ? 'today': ''
                                    } ${
                                        Date.parse(date.toDateString()) < Date.parse(new Date().toDateString()) ? 'disabled': ''
                                    }`}
                                    onClick={() => handleDateClick(date)}
                                >
                                    {date.getDate()}
                                </div>
                                ))}
                            </div>
                        </div>
                </div>
            </div>

            <div className="calendar hidden xl:block p-4">
                {/* Calendar Header */}
                <div className="calendar-header">
                    <div className="w-full flex items-center gap-0">
                        <button type="button" className="nav-button prev xl:hidden" onClick={goToPrevMonth}>
                            <ChevronLeft className="w-4 text-teal" />
                        </button>
                        
                            <div className="month-name">
                            {formatMonthYear(nextYear, nextMonthIndex)}
                            </div>

                        <button type="button" className="nav-button next" onClick={goToNextMonth}>
                            <ChevronRight className="w-4 text-teal" />
                        </button>
                    </div>

                </div>

                {/* Calendar Body */}
                <div className="calendar-body">
                        {/* Next Month */}
                        <div className="month-column">
                            <div className="days-of-week">
                                <div className="day-header">Sun</div>
                                <div className="day-header">Mon</div>
                                <div className="day-header">Tue</div>
                                <div className="day-header">Wed</div>
                                <div className="day-header">Thu</div>
                                <div className="day-header">Fri</div>
                                <div className="day-header">Sat</div>
                            </div>
                            <div className="days-row">
                                {nextMonthDays.map((date) => (
                                <div
                                    key={date.toDateString()}
                                    className={`day ${
                                    selectedDate &&
                                    date.toDateString() === selectedDate.toDateString()
                                        ? "selected"
                                        : ""
                                    } ${
                                        date.toDateString() === new Date().toDateString() ? 'today': ''
                                    } ${
                                        Date.parse(date.toDateString()) < Date.parse(new Date().toDateString()) ? 'disabled': ''
                                    }`}
                                    onClick={() => handleDateClick(date)}
                                >
                                    {date.getDate()}
                                </div>
                                ))}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Calendar
