import { z } from "zod";

export const searchFormSchema = z.object({
    departure: z.string({
      required_error: "Please select a valid departure city.",
    }).nonempty("At least one destination is required."),
    destinations: z.array(
    z.string({
      required_error: "At least one destination is required.",
    })
    ).nonempty("At least one destination is required."),
    travel_date: z.string().optional(),
    travelers: z.string().optional(),
    price_range: z.string().optional(),
})