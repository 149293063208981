import * as React from "react";
const SvgLogoX = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.98 27.054h-8.905l-6.682-9.73-8.37 9.73H.33l10.423-12.112L.488 0h8.905l6.317 9.196L23.618 0h3.693L17.35 11.577 27.99 27.054zm-8.322-1.105h6.232l-9.937-14.457 8.953-10.41h-.79l-8.516 9.9-6.803-9.9H2.565l9.573 13.921-9.415 10.946h.79l8.977-10.436zm5.26-.51h-4.786L3.512 1.675H8.3zm-4.215-1.094h2.101L7.728 2.782H5.627z"
    />
  </svg>
);
export default SvgLogoX;
