import { Check, Star } from "lucide-react"
import { useEffect } from "react"
import { UseFormReturn } from "react-hook-form"
import { Card, CardContent, CardHeader } from "src/components/ui/card"
import { FormControl, FormField, FormItem, FormLabel } from "src/components/ui/form"
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group"
import { FilterCheckboxCardOption } from "src/interfaces/filterCheckboxCard"
import { cn } from "src/lib/utils"
import { filterFormSchema } from "src/schemas/filter"
import { z } from "zod"

const RatingFilter = ({
    form
}: {
    form: UseFormReturn<z.infer<typeof filterFormSchema>, any, undefined>;
}) => {
    const options: FilterCheckboxCardOption[] = [
        {value: '1+', label: '1'},
        {value: '2+', label: '2'},
        {value: '3+', label: '3'},
        {value: '4+', label: '4'},
    ]

    useEffect(() => {
        console.log(form.watch())
    }, [form.watch('rating')])

    return (
        <Card className="w-full overflow-hidden rounded-[5px] border-[.2px] border-teal">
            <CardHeader className="py-4 px-3 bg-lightTeal border-b-[.2px] border-teal">
                <label>
                    Rating
                </label>
            </CardHeader>
            <CardContent className="mt-4 space-y-2">
                <label className="text-sm font-normal text-black">Show only ratings more than</label>
                <FormField
                    control={form.control}
                    name={'rating'}
                    render={({ field }) => {
                        return (
                            <RadioGroup
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                            className="w-max flex flex-row items-center gap-0 border border-gray-200 rounded-[4px] overflow-hidden divide-x divide-gray-200">
                                {
                                    options.length ? 
                                        options.map(option => (
                                            <FormItem
                                                key={option.value}
                                                className={cn("bg-white", {'bg-lightTeal': option.value === field.value})}
                                            >
                                                <div className={cn("relative py-3 px-3 flex items-center gap-1")}>
                                                    <FormControl>
                                                        <RadioGroupItem value={option.value} className="absolute opacity-0 w-full h-full z-10" />
                                                    </FormControl>
                                                    <FormLabel className="flex items-center gap-0 font-normal text-sm text-black">
                                                        {
                                                            option.value === field.value ? 
                                                                <Check className="w-3 h-3 stroke-gray-400 me-1" />
                                                            : null
                                                        }
                                                        {option.label}
                                                    </FormLabel>
                                                    <Star className="w-3 h-3 stroke-yellow-500 fill-yellow-500" />
                                                </div>
                                                
                                            </FormItem>
                                        )) :
                                        null
                                }
                                
                            </RadioGroup>
                        )
                    }}
                />
            </CardContent>
        </Card>
    )
}

export default RatingFilter
