import { Check, ChevronDown, X } from "lucide-react";

import { Badge } from "src/components/ui/badge";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import { CommandEmpty, Command as CommandPrimitive } from "cmdk";
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import SvgArrivals from "../icons/Arrivals";
import { cn } from "src/lib/utils";
import { FormControl } from "../ui/form";
import { UseFormReturn } from "react-hook-form";
import { Schema, z } from "zod";
import { toast } from "src/hooks/use-toast";
import { MultiSelectOption } from "src/interfaces/multiSelect";
import { ScrollArea } from "../ui/scroll-area";



const MultiSelect = ({
    schema,
    form,
    fieldName,
    options,
    placeholder,
    maxSelection
}: {
    schema: Schema;
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>;
    fieldName: string;
    options: MultiSelectOption[];
    placeholder: string;  
    maxSelection?: number
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<MultiSelectOption[]>([]);
    const [inputValue, setInputValue] = useState("");

    const handleUnselect = useCallback((option: MultiSelectOption) => {
        setSelected((prev) => prev.filter((s) => s.value !== option.value));
    }, []);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            const input = inputRef.current;
            if (input) {
                if (e.key === "Delete" || e.key === "Backspace") {
                if (input.value === "") {
                    setSelected((prev) => {
                        const newSelected = [...prev];
                        newSelected.pop();

                        return newSelected;
                    });
                }
                }
                // This is not a default behaviour of the <input /> field
                if (e.key === "Escape") {
                    input.blur();
                }
            }
    }, []);
    
    const handleSelectItem = useCallback((option: MultiSelectOption) => {
        setInputValue("");
        console.log(selected)
        if (selected.find(prevOption => prevOption.value === option.value)) {
            setSelected((prev) => prev.filter(prevOption => prevOption.value !== option.value));
        } else {
            if (maxSelection && selected.length < maxSelection) {
                setSelected((prev) => [...prev, option]);
            } else if (maxSelection && selected.length >= maxSelection) {
                toast({
                    variant: 'destructive',
                    title: 'Sorry',
                    description: `Maximum items to be selected is ${maxSelection} ${maxSelection && maxSelection !== 1 ? 'items' : 'item'}`
                })
            }
        }
    }, [maxSelection, selected])

    const selectables = options
        // .filter(
        //     (option) => !selected.find(selectedOption => selectedOption.value === option.value),
        // );

    useEffect(() => {
        form.setValue(fieldName, selected.length ? [...selected.map(i => i.value)] : [])
        // Trigger validation
        if(form.control.getFieldState(fieldName).error)
            form.trigger(fieldName)
    }, [selected, fieldName, form])

    return (
        <>
            <FormControl>
                <Command
                    onKeyDown={handleKeyDown}
                    onBlur={() => setOpen(false)}
                    className="overflow-visible bg-transparent h-auto"
                    >
                    <div onClick={() => { setOpen(true); setTimeout(() => inputRef.current?.focus(), 0) }} className={cn("group cursor-pointer flex justify-between items-center rounded-md border border-input min-h-14 bg-black/[0.46] text-white px-4 py-[10px] text-xs sm:text-[13px] xl:text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-1",
                        // { 'items-start': selected.length },
                        { 'border-red-500': form.getFieldState(fieldName).invalid }
                    )}>
                        <div className="flex justify-start items-center">
                            <SvgArrivals className="min-w-6 w-6" />
                            <div className="flex items-center flex-wrap gap-y-1">
                                {
                                    selected.map((option) => (
                                        <Badge key={option.value} variant="default" className="text-xs sm:text-[13px] xl:text-sm ml-2 rounded-[8px] font-normal text-white uppercase bg-black hover:bg-black py-[5px] px-2 flex justify-center items-center gap-1">
                                            {option.value}
                                            <button
                                                className="bg-black"
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                    handleUnselect(option);
                                                    }
                                                }}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                onClick={() => handleUnselect(option)}
                                                >
                                                <X className="-mt-2 h-3 w-3" />
                                            </button>
                                        </Badge>
                                    ))
                                }
                                {/* Avoid having the "Search" Icon */}
                                <CommandPrimitive.Input
                                    ref={inputRef}
                                    value={inputValue}
                                    onValueChange={setInputValue}
                                    onBlur={() => setOpen(false)}
                                    onFocus={() => setOpen(true)}
                                    placeholder={placeholder}
                                    className={cn("text-xs sm:text-[13px] xl:text-sm ml-2 flex-1 bg-transparent outline-none placeholder:text-white", {hidden: selected.length && !open})}
                                />
                            </div>
                        </div>
                        <ChevronDown className="inline-block ms-auto w-4 text-white" />   
                    </div>
                    <div className="relative">
                        <CommandList>
                        {
                            open && !selected.length ? (
                                <div className={cn("absolute top-1.5 z-10 w-full rounded-[16px] bg-popover text-popover-foreground shadow-md outline-none animate-in")}>
                                    <CommandEmpty className="text-xs sm:text-[13px] xl:text-sm p-4 text-muted-foreground">No matching destination.</CommandEmpty>
                                </div>
                            ) : null
                        }

                        {
                            open ? (
                                <div className={cn("absolute top-1.5 z-10 w-full rounded-[16px] overflow-hidden bg-popover text-popover-foreground shadow-md outline-none animate-in")}>
                                    <CommandGroup className="h-full">
                                        <ScrollArea className="h-[240px] max-h-[80vh] [&>div>div]:divide-y-[0.5px] [&>div>div]:divide-darkBlue py-4 px-4">
                                            {
                                                selectables.length ?
                                                    selectables.map((option) => (
                                                        <CommandItem
                                                            key={option.value}
                                                            onMouseDown={(e: any) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }}
                                                            onSelect={() => handleSelectItem(option)}
                                                            className={`text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue`}
                                                            >
                                                            <span className="flex justify-center items-center w-5 h-5 rounded-[3px] border-[0.5px] border-darkBlue bg-white text-teal">
                                                                {
                                                                    selected.map(i => i.value).includes(option.value) ? <Check className="w-4 h-4" /> : null
                                                                }
                                                            </span>
                                                            {option.label}
                                                        </CommandItem>
                                                    ))
                                                :
                                                selected.length && !selectables.length ?
                                                    <p className="text-xs sm:text-[13px] xl:text-sm text-muted-foreground py-4 px-4">No more results</p>
                                                    : null
                                            }
                                        </ScrollArea>
                                    </CommandGroup>
                                </div>
                            ) : null
                        }
                        </CommandList>
                    </div>
                </Command>
            </FormControl>
        </>
    );
}

export default MultiSelect;