// Desc: Description of the tour in TourDetails page

import TextWithContent from "src/components/common/text/TextWithContent"

const Description = () => {
    return (
        <div className="w-full space-y-10">
            <TextWithContent text="About the Trip" content={
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi suscipit impedit itaque sit officiis ad perferendis quasi corporis dolorum iusto provident illum, tempora, necessitatibus officia fugiat iure praesentium! Voluptatem assumenda quisquam placeat ut cupiditate exercitationem cum debitis maxime quam obcaecati excepturi, quibusdam consectetur saepe, labore quia repudiandae est quo minima officiis eveniet. Pariatur expedita quaerat ad omnis ducimus magnam quis non velit voluptatem quae laudantium, quod porro iusto consequatur error doloremque numquam fugit, distinctio tenetur quam cupiditate! Quod maxime quibusdam deleniti et quis eligendi, repudiandae dolores cum, magni enim voluptatibus, consectetur voluptates libero! Fugiat hic, recusandae quia officia facilis molestias.
                </p>
            } />
            <TextWithContent text="About the Service Provider Name" content={
                <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero natus eaque culpa aliquam at eius repellat ullam harum cumque consequuntur? Laboriosam perferendis error repudiandae eligendi sit officiis architecto asperiores. Consectetur necessitatibus fugit non earum sint impedit doloribus dicta, quaerat cupiditate, quas aliquid reiciendis.
                </p>
            } />
        </div>
    )
}

export default Description
