import { Minus, Plus } from 'lucide-react'
import { Dispatch, SetStateAction } from 'react'
import { TravelersCounter as ITravelersCounter } from 'src/interfaces/travelers';

const TravelersCounter = ({
    name,
    label,
    count,
    setCounters,
    min,
    max,
}: {
    name: string;
    label: string;
    count: number;
    setCounters: Dispatch<SetStateAction<ITravelersCounter>>
    min?: number;
    max?: number;
}) => {
    
    const handleIncrement = (key: any) => {
        setCounters((prev: any) => ({
        ...prev, [key]: prev[key] + 1
        }));
    };

    const handleDecrement = (key: any) => {
        setCounters((prev: any) => ({
        ...prev,
        [key]: prev[key] > 0 ? prev[key] - 1 : 0, // Prevent negative values
        }));
    };

    return (
        <div className="flex justify-between items-center gap-4 py-2">
            <span className="counter-label">
                {label}
            </span>
            <div className="counter-controls">
                <button
                className=" counter-button w-6 h-6 flex justify-center items-center rounded-full"
                disabled={min !== undefined ? count === min : false}
                onClick={() => handleDecrement(name)}
                >
                    <Minus className="w-4 h-4" />
                </button>
                <span className="counter-value">{count}</span>
                <button
                className=" counter-button w-6 h-6 flex justify-center items-center rounded-full"
                disabled={max !== undefined ? count === max : false}
                onClick={() => handleIncrement(name)}
                >
                    <Plus className="w-4 h-4" />
                </button>
            </div>
        </div>
    )
}

export default TravelersCounter
