import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import FormError from "src/components/common/FormError";
import { FormControl, FormField, FormItem } from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import countryCodes from "src/data/CountryCodes";
import { cn } from "src/lib/utils";
import { z } from "zod";
const PhoneInput = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
}) => {
    
    useEffect(() => {
        form.setValue("country_code", '+20')
    }, [])
    

    return (
        <div className="order-6 form-group relative w-full">
        {/* Phone Number Input with Country Code */}
        <div className="flex w-full">
            {/* Country Code Dropdown */}
            <div className="form-group relative w-max">
                <FormField
                control={form.control}
                name="country_code"
                render={({ field }) => (
                    <FormItem>
                        <Select onValueChange={field.onChange} defaultValue={field.value || '+20'}>
                            <FormControl>
                                <SelectTrigger className={cn("w-[6.6rem] bg-black/[.35] !placeholder-white border border-white rounded-e-none border-e-0 text-white font-main text-sm font-normal h-14 py-4 ps-5 pe-2",
                                    { 'border-red-500': form.getFieldState('country_code').invalid }
                                )}>
                                    <SelectValue placeholder="Country Code" className="text-nowrap" />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent className="rounded-[16px] max-w-[9.5rem] max-h-[350px] mx-auto">
                                <SelectGroup className="divide-y divide-gray-400 max-w-[9rem] px-4 py-3 min-w-[9rem]">
                                    {countryCodes.map((country) => (
                                        <SelectItem key={country.code} value={country.code} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">
                                            {country.name}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        {/* Errors */}
                        <FormError />
                    </FormItem>
                )} />
                
            </div>
            

            

            {/* Phone Number Input */}
            <div className="order-1 form-group relative w-full xl:max-w-[65%]">
                    {/* Vertical Line */}
                    <div aria-hidden={true} className="absolute top-5 left-0 -ms-px w-[0.5px] h-4 bg-white"></div>
                    <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    type="text"
                                    placeholder="Mobile Number"
                                    {...field}
                                    className={cn("w-full bg-black/[.35] !placeholder-white !border-s-0 rounded-s-none border border-white text-white font-main text-sm font-normal h-14 p-6",
                                        { 'border-red-500': form.getFieldState('phone').invalid }
                                    )}
                                />
                            </FormControl>
                            {/* Errors */}
                            <FormError />
                        </FormItem>
                    )} />
                </div>
            </div>
        </div>
    );
};
export default PhoneInput;
