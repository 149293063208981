import axios from "axios";

export const axiosClient = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
        Accept: "application/json, text/plain, */*",
        },
    });
})();
