import { X } from 'lucide-react'
import { Badge } from 'src/components/ui/badge'

const AppliedFilters = ({
    filters,
    handleRemoveFilter
}: {
    filters: string[];
    handleRemoveFilter: () => void
}) => {

    return (
        <div className="w-full flex justify-start gap-5 pt-[34px] pb-0 xl:py-8 xl:border-b border-mediumGrey">
            <h4 className="text-black">Filters</h4>
            <div className="w-full flex flex-wrap gap-4">
                {
                    filters.map(filter => (
                        <Badge key={filter} className="relative ps-3 pe-6 py-1 flex bg-lightBlue hover:bg-lightBlue text-black font-normal">
                            {filter}
                            <button type="button" onClick={() => handleRemoveFilter()} className="absolute top-[4px] right-[4px]">
                                <X className="w-3 h-3 text-black" />
                            </button>
                        </Badge>
                    ))
                }
            </div>
        </div>
    )
}

export default AppliedFilters
