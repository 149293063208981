import { useCallback, useEffect, useRef, useState } from "react";
import "../../styles/search/numberOfTravelersField.css";
import SvgTravelers from "../icons/Travelers";
import { UseFormReturn } from "react-hook-form";
import { FormControl, FormField, FormItem } from "../ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { z } from "zod";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { cn } from "src/lib/utils";
import { MAX_ADULTS_PER_ROOM, MAX_CHILDREN_PER_ROOM, MAX_ROOMS, MIN_ADULTS_PER_ROOM, MIN_CHILDREN_PER_ROOM, MIN_ROOMS } from "src/utils/constants";
import { TravelersCounter as ITravelersCounter } from "src/interfaces/travelers";
import TravelersCounter from "../common/TravelersCounter";
import FormError from "../common/FormError";
import useGeneralSettings from "src/hooks/use-general-settings";



const NumberOfTravelersField = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
}) => {
    const [counters, setCounters] = useState<ITravelersCounter>({
        rooms: 1,
        adults: 2,
        children: 0,
    });
    const minRooms = MIN_ROOMS;
    const maxRooms = MAX_ROOMS;
    const minAdultsPerRoom = MIN_ADULTS_PER_ROOM;
    const maxAdultsPerRoom = MAX_ADULTS_PER_ROOM;
    const minChildrenPerRoom = MIN_CHILDREN_PER_ROOM;
    const maxChildrenPerRoom = MAX_CHILDREN_PER_ROOM;
    const childrenAgeOptions = [
        '>1', '>6', '>10'
    ]
    const [childrenAges, setChildrenAges] = useState<string[]>([])
    const [petFriendly, setPetFriendly] = useState<boolean>(false);
    // For Update button
    const [travelersFormatted, setTravelersFormatted] = useState<string | null>(null);
    const selectItemRef = useRef<HTMLDivElement>(null);
    // Get General settings
    const { data: generalSettings } = useGeneralSettings();  

    const formatNumberOfTravelers = useCallback(() => {
        return `Rooms: ${counters.rooms}, Adults: ${counters.adults}${counters.children ? `, Children: ${counters.children}` : ''}, ${petFriendly? 'Pets Allowed': 'No Pets'}`;
    }, [counters.rooms, counters.adults, counters.children, petFriendly]);

    const resetCounters = () => {
        setCounters({ rooms: 1, adults: 2, children: 0 });
        setPetFriendly(false);
        setTravelersFormatted(null);
        form.setValue('travelers', '')
        // Trigger validation
        if(form.control.getFieldState('travelers').error)
            form.trigger('travelers')
        console.log(form.watch())
    };

    useEffect(() => {
        console.log(form.watch())
    }, [])

    useEffect(() => {
        if (travelersFormatted) {
            form.setValue('travelers', counters ? formatNumberOfTravelers() + childrenAges.map((age, i) => `${i === 0 ? ', ' : ''}child ${i}: ${age}`).join(',') : '')
        }
        // Trigger validation
        if(form.control.getFieldState('travelers').error)
        form.trigger('travelers')

    }, [travelersFormatted])

    useEffect(() => {
        if (childrenAges.length) {
            const newArray = new Array(counters.children)
            for (let i = 0; i < newArray.length; i++){
                if (childrenAges[i]) {
                    newArray[i] = childrenAges[i];
                } else {
                    newArray[i] = '>1';
                }
            }
            setChildrenAges(newArray);
        } else {
            setChildrenAges(new Array(counters.children).fill('>1'));
        }
    }, [counters.children])
    

    return (
        <FormField
                control={form.control}
                name="travelers"
                render={({ field }) => (
                    <FormItem>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                                <SelectTrigger className={cn({'border-red-500': form.getFieldState('travelers').invalid})}>
                                    <div className="flex justify-start items-center gap-2">
                                        <SvgTravelers className="w-6" />
                                        <div className="text-xs sm:text-[13px] xl:text-sm text-start selected-value-remove-icon">
                                            {/* For update button */}
                                            {travelersFormatted || "Number of Travelers"}
                                        </div>
                                    </div>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent className="rounded-[16px] max-h-[440px] max-w-[95vw] w-[20.5rem] mx-auto p-4">
                                {/* For Closing Content on update button click */}
                                <SelectItem ref={selectItemRef} value={travelersFormatted ? travelersFormatted: '-'} className="hidden"></SelectItem>
                                {/* Content */}
                                <div className="max-w-72">
                                    <div className="border-b border-gray-400">
                                        <TravelersCounter
                                            name={'rooms'}
                                            label="Rooms"
                                            count={counters.rooms}
                                            setCounters={setCounters}
                                            min={minRooms}
                                            max={maxRooms} />
                                    </div>
                                    <div className="flex flex-col gap-0">
                                        <TravelersCounter
                                            name={'adults'}
                                            label="Adults"
                                            count={counters.adults}
                                            setCounters={setCounters}
                                            min={minAdultsPerRoom}
                                            max={generalSettings?.data.settingsMaxAdults || maxAdultsPerRoom} />
                                        <TravelersCounter
                                            name={'children'}
                                            label="Children"
                                            count={counters.children}
                                            setCounters={setCounters}
                                            min={minChildrenPerRoom}
                                            max={generalSettings?.data.settingsMaxChildren || maxChildrenPerRoom} />
                                    </div>
                                    {
                                        counters.children > 0 ? (
                                            <div className="children-ages pt-4 border-t border-gray-400">
                                                <Label className="font-semibold text-xs sm:text-[13px] xl:text-sm text-grey mb-4 block">Children's ages (required)</Label>
                                                <div className="flex flex-col gap-2">
                                                    {
                                                        childrenAges.map((childAge, index) => (
                                                            <div className="child-age flex justify-between items-center gap-4">
                                                                <span className="text-xs sm:text-[13px] xl:text-sm font-normal text-black">
                                                                    {`Child ${index + 1}`}
                                                                </span>
                                                                <div className="">
                                                                    <Select value={childAge} onValueChange={value => setChildrenAges(prev => { prev[index] = value; return [...prev]})}>
                                                                        <SelectTrigger className="rounded-[4px] h-auto px-2 py-1 w-14 bg-background text-foreground border-gray-200">
                                                                            <SelectValue placeholder="Age" />
                                                                        </SelectTrigger>
                                                                        <SelectContent className="rounded-[5px]">
                                                                            {
                                                                                childrenAgeOptions.map((option, i) => (
                                                                                    <SelectItem value={option}>{ option }</SelectItem>
                                                                                ))
                                                                            }
                                                                        </SelectContent>
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ): null
                                    }
                                    {/* Pet Friendly Checkbox */}
                                    <div className="dropdown-option-checkbox pt-4 border-t border-gray-400">
                                        <label className="checkbox-label">
                                        <Checkbox
                                            checked={petFriendly}
                                            onCheckedChange={() => setPetFriendly(!petFriendly)}
                                        />
                                            Pet Friendly
                                        </label>
                                    </div>
                                    {/* Reset and Update buttons */}
                                    <div className="dropdown-buttons max-w-72  flex justify-between items-center">
                                        <Button
                                            variant={'link'}
                                            className="!p-0 font-semibold text-blue-500 underline hover:text-green active:text-green" onClick={() => {
                                                resetCounters();
                                                selectItemRef.current?.click();
                                            }}>
                                            Reset
                                        </Button>
                                        {/* Update Button */}
                                        <Button
                                            variant={'default'}
                                            className="submenu-button"
                                            onClick={() => {
                                                setTravelersFormatted(formatNumberOfTravelers());
                                                selectItemRef.current?.click();
                                            }}> 
                                            Update
                                        </Button>
                                    </div>
                                    </div>
                            </SelectContent>
                        </Select>
                        {/* Errors */}
                        <FormError />
                    </FormItem>
                )}
            />
    );
};

export default NumberOfTravelersField;
