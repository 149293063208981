import { ArrowLeft, Download } from "lucide-react";
import { Link, useNavigate } from "react-router-dom"
import { Button, buttonVariants } from "src/components/ui/button"


const AuthHeader = ({
    to,
}: {
    to: string;
}) => {
    const navigate = useNavigate()

    return (
        <div className="flex xl:justify-between justify-center items-start h-24 w-full gap-x-5 mt-8 mb-4">
            {
                to === 'back' ? 
                    <Button
                        onClick={() => navigate(-1)}
                        variant={'link'}
                        className="text-white group font-semibold text-xs sm:text-[13px] xl:text-sm font-main w-auto hidden xl:flex xl:items-center !gap-1.5"
                    >
                        <ArrowLeft className="!w-[32px] !h-[22px] group-hover:-translate-x-1 transition-all" />
                        Back
                    </Button>
                    
                    :
                    <Link
                        to={to}
                        className={buttonVariants({
                        variant: "link",
                        className:
                            "text-white group font-semibold text-xs sm:text-[13px] xl:text-sm font-main w-auto hidden xl:flex xl:items-center !gap-1.5",
                        })}
                    >
                        <ArrowLeft className="!w-[32px] !h-[22px] group-hover:-translate-x-1 transition-all" />
                        Back
                    </Link>
            }
            <Link
                to=""
                className={buttonVariants({
                variant: "outline",
                className:
                    "sticky group top-4 text-xs sm:text-[13px] h-auto xl:text-sm font-main font-medium text-white border-white bg-opacity-35 bg-black py-3 px-6",
                })}
            >
                <Download className="!min-h-5 !min-w-5 group-hover:animate-pulse transition-all" />
                Download the App for Better Prices
            </Link>
        </div>
    )
}

export default AuthHeader
