import ImageCarousel from "src/components/common/carousels/ImageCarousel";
import tourDetails from "src/assets/images/ui/tour-details.jpg";
import { Tag } from "lucide-react";

const Activities = () => {
    const images = [
        { src: tourDetails, title: 'Activity 1' },
        { src: tourDetails, title: 'Activity 2' },
        { src: tourDetails, title: 'Activity 3' },
        { src: tourDetails, title: 'Activity 4' },
    ];

    return (
        <div className="w-full sm:px-2 xl:px-4">
            <h3 className="text-darkTeal font-semibold text-sm flex items-center gap-2 mb-4">
                <Tag className="w-4 h-4 text-teal" />
                Activities ( 4 Activities )
            </h3>
            <ImageCarousel images={images} />
        </div>
    );
};

export default Activities;
