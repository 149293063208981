import * as React from "react";
const SvgCutlery = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 11 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 3.065a4.3 4.3 0 0 1 .275-1.48C.442 1.12.751.72 1.16.435a2.44 2.44 0 0 1 2.787 0c.408.284.717.685.884 1.148.179.474.272.975.275 1.48 0 1.799-.645 2.195-1.188 2.78-.412.445-.769.944-.76 1.738.03 2.57.77 5.372.322 8.368-.118.795-.447 1.05-.926 1.05s-.809-.255-.927-1.05c-.448-2.996.291-5.799.321-8.368.01-.794-.347-1.293-.759-1.737C.645 5.259 0 4.863 0 3.065m6.724-.932L6.91.25a.28.28 0 0 1 .091-.177A.28.28 0 0 1 7.19 0h.014c.074 0 .145.03.198.08a.27.27 0 0 1 .082.196v3.398a.3.3 0 0 0 .063.212c.049.06.119.098.196.108a.3.3 0 0 0 .196-.108.3.3 0 0 0 .063-.212V.276c0-.073.03-.143.082-.195a.283.283 0 0 1 .397 0 .27.27 0 0 1 .082.195v3.398a.3.3 0 0 0 .063.212c.049.06.119.098.196.108a.3.3 0 0 0 .195-.108.3.3 0 0 0 .064-.212V.276c0-.073.03-.143.082-.195a.283.283 0 0 1 .396 0 .27.27 0 0 1 .083.195v3.398a.3.3 0 0 0 .063.212c.049.06.119.098.196.108a.3.3 0 0 0 .195-.108.3.3 0 0 0 .064-.212V.276a.27.27 0 0 1 .173-.255A.3.3 0 0 1 10.44 0h.014c.07 0 .137.026.189.072s.084.11.09.177l.187 1.884c.194 1.964.061 2.764-.696 3.518a2.303 2.303 0 0 0-.78 1.767c.03 2.614.79 5.465.33 8.514-.121.807-.46 1.068-.952 1.068-.493 0-.83-.26-.953-1.068-.46-3.049.3-5.9.33-8.514a2.3 2.3 0 0 0-.195-.966 2.3 2.3 0 0 0-.584-.8c-.758-.755-.89-1.555-.696-3.519"
    />
  </svg>
);
export default SvgCutlery;
