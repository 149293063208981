import AuthBg from "src/assets/images/auth/bg.jpg"; // Background image
import CredentialsForm from "./CredentialsForm";
import Container from "src/components/common/Container";
import AuthHeader from "../components/AuthHeader";
import AlreadyAMember from "../components/AlreadyAMember";

const RegisterCredentials = () => {
    return (
        <div
        className="w-full bg-cover bg-no-repeat bg-center pt-px pb-px"
        style={{
            backgroundImage: `url(${AuthBg})`,
        }}
        >
            {/* Content Container */}
            <Container>
                {/* header */}
                <AuthHeader to="/register" />
                <div className="mx-auto xl:ms-0 max-w-[480px] xl:max-w-[630px]">
                    {/* title */}
                    <div className="flex flex-col items-center xl:items-start gap-6 mb-8">
                        <h1 className="text-white text-xl font-bold font-main">
                            Sign up to Tripvex
                        </h1>
                        <hr className="w-24 h-1" />
                    </div>
                    <CredentialsForm />
                </div>

                {/* Already a member */}
                <AlreadyAMember />
            </Container>
            
        </div>
    );
};

export default RegisterCredentials;
