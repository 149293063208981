import { UseFormReturn } from "react-hook-form"
import FilterCheckboxCard from "src/components/common/FilterCheckboxCard"
import { FilterCheckboxCardOption } from "src/interfaces/filterCheckboxCard"
import { filterFormSchema } from "src/schemas/filter"
import { z } from "zod"

const ActivitiesFilter = ({
    form
}: {
    form: UseFormReturn<z.infer<typeof filterFormSchema>, any, undefined>;
}) => {
    const items: FilterCheckboxCardOption[] = [
        {value: 'fishing', label: 'Fishing', count: 200},
        {value: 'hiking', label: 'Hiking', count: 100},
        {value: 'beach', label: 'Beach', count: 15},
        {value: 'cycling', label: 'Cycling', count: 12},
        {value: 'sauna', label: 'Sauna', count: 230},
        {value: 'night_lights', label: 'Night lights', count: 12},
    ]

    // const 
    
    return (
        <FilterCheckboxCard
            schema={filterFormSchema}
            form={form}
            fieldName="activities"
            options={items}
            label="Activities"
        />
        
    )
}

export default ActivitiesFilter
