import { ArrowDownUp, ArrowUpDown } from "lucide-react"
import { Select, SelectContent, SelectItem, SelectValue, SelectTrigger } from "src/components/ui/select"

const SortBySelect = ({
    options,
    onChange
}: {
    options: string[]
    onChange: (value: string) => void
}) => {
    return (
        <Select
            defaultValue={options[0]}
            onValueChange={(value) => onChange(value)}>
            <SelectTrigger isHideArrow
                className="w-full flex justify-center items-center gap-2.5 py-4 text-xs font-semibold text-center text-darkTeal bg-transparent rounded-none border-none outline-none focus:ring-0 focus:ring-offset-0"
            >
                <ArrowDownUp className="w-4 stroke-[1.8px] shrink-0" />
                <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent className="rounded-[6px] [&>div]:divide-y [&>div]:divide-gray-200 [&>div]:rounded-[6px] [&>div]:overflow-hidden [&>div]:p-0 ">
                {
                    options.map(option => (
                        <SelectItem key={option} value={option} className="rounded-none py-4 hover:!bg-lightBlue">{option}</SelectItem>
                    ))
                }
            </SelectContent>
        </Select>
    )
}

export default SortBySelect
