import * as React from "react";
const SvgArrivals = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 27 27"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.291 23.662h22.631M3.397 6.607l.208 5.364a3.381 3.381 0 0 0 2.491 3.122l17.613 4.769.285-3.04a3.35 3.35 0 0 0-.596-2.235 3.38 3.38 0 0 0-1.9-1.329l-5.184-1.375-2.976-5.894-1.735-.704a.84.84 0 0 0-.792.091.82.82 0 0 0-.341.717l.253 4.285-3.236-.902L6.292 6.53 4.53 5.816a.84.84 0 0 0-.784.086.82.82 0 0 0-.35.705"
    />
  </svg>
);
export default SvgArrivals;
