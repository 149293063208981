import Search from "./Search";
import PriceDetails from "./PriceDetails";
import TourDetailsContent from "./TourDetailsContent";
import TourRating from "./TourRating";
import TourReviews from "./TourReviews";
import Container from "src/components/common/Container";
import RecentlyViewed from "src/components/common/RecentlyViewed";
import { TourCardData } from "src/interfaces/tourCard";
import PackageImage1 from "src/assets/images/ui/package-image-1.jpg";
import PackageImage2 from "src/assets/images/ui/package-image-2.jpg";
import PackageImage3 from "src/assets/images/ui/package-image-3.jpg";
import DownloadApp from "src/components/banners/downloadApp/DownloadApp";
import QuickNavigationBar from "src/components/layout/QuickNavigationBar";
import { useMediaQueries } from "src/hooks/use-media-query";

const TourDetails = () => {
  const {xl} = useMediaQueries()

  const recentlyViewedItems: TourCardData[] = [
        {
            id: 1,
            imageSrc: PackageImage1,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Long Stay Vacation in El Quseir',
            avgRating: 4.5,
            reviewsCount: 132,
            nightsCount: 12,
            daysCount: 13,
            currency: '$',
            salePrice: 2700,
            price: 2500,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 2,
            imageSrc: PackageImage2,
            isHotDeal: true,
            isOnSale: true,
            salePercent: 20,
            title: 'A Legendary Vacation',
            avgRating: 4,
            reviewsCount: 172,
            nightsCount: 10,
            daysCount: 11,
            currency: '$',
            salePrice: 2500,
            price: 2250,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        },
        {
            id: 3,
            imageSrc: PackageImage3,
            isLimitedPlaces: true,
            placesLeft: 2,
            title: 'Movenpick Royal Lily & Lotus Nile Cruises',
            avgRating: 4.8,
            reviewsCount: 15,
            nightsCount: 9,
            daysCount: 10,
            currency: '$',
            price: 2300,
            locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
            activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
        }
    ]

  return (
    <>
      {/* Quick Navigation Bar */}
      {
        xl ? 
          <QuickNavigationBar />
          : null
      }
      {/* Search */}
      <Search className="search-bg !pt-10 !pb-1" />
      {/* Tour Details and Price */}
      <Container className="my-10">
        <div className="grid grid-cols-4 sm:grid-cols-12 xl:grid-cols-12 gap-4">
          <div className="col-span-4 sm:col-span-12 xl:col-span-8 pt-0 px-0">
            <TourDetailsContent />
          </div>
          <div className="relative col-span-4 sm:col-span-12 xl:col-span-4 bg-white xl:ps-8 pt-0 xl:pt-0">
              <PriceDetails />
          </div>
        </div>
      </Container>
      {/* Reviews and rating */}
      <div className="relative w-full bg-lightGreen mb-10 overflow-hidden">
        <Container>
          <div className="grid grid-cols-4 sm:grid-cols-12 xl:grid-cols-12 gap-10">
            <div className="col-span-4 sm:col-span-12 xl:col-span-8 pt-10">
              <TourReviews />
            </div>
            <div className="relative col-span-4 sm:col-span-12 xl:col-span-4 bg-white sm:ps-4 pt-10 sm:pt-0">
              <div className="absolute z-0 top-0 -start-1/2 sm:start-0 w-[300%] h-full bg-white" />
                <div className="relative z-[1]">
                  <TourRating />
                </div>
            </div>
          </div>
        </Container>
      </div>
      {/* Recently Viewed */}
      <Container>
        <RecentlyViewed data={recentlyViewedItems} />
      </Container>
      {/* Download app banner */}
      <DownloadApp />
    </>
  );
};

export default TourDetails;
