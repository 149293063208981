import "../../../styles/home/search.css";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";
import { Form } from "src/components/ui/form";
import { useEffect } from "react";
import { toast } from "src/hooks/use-toast";
import Container from "src/components/common/Container";
import DepartureField from "src/components/search/DepartureField";
import TravelDateField from "src/components/search/TravelDateField";
import NumberOfTravelersField from "src/components/search/NumberOfTravelersField";
import { searchFormSchema } from "src/schemas/tourDetails";
import { cn } from "src/lib/utils";


const Search = ({
    className
}: {
    className?: string;
}) => {
    const form = useForm<z.infer<typeof searchFormSchema>>({
        resolver: zodResolver(searchFormSchema),
    })
    
    function onSubmit(data: z.infer<typeof searchFormSchema>) {
        console.log(data)
    }

    useEffect(() => {
        if (!form.formState.isValid && form.formState.submitCount) {
            toast({
                variant: 'destructive',
                title: 'Update failed!',
                description:(
                    <ul className="list-disc ps-4">
                        {
                            [
                            form.formState.errors.departure?.message,
                            form.formState.errors.travel_date?.message,
                            form.formState.errors.travelers?.message,
                            ].filter(x=>x).map(error => (
                                <li>{error}</li>
                            ))
                        }
                    </ul>
                )
            })
        }
        if (form.formState.isValid && form.formState.submitCount) { 
            toast({
                variant: 'default',
                title: '👍 Perfect!',
            })
        }
    }, [form.formState])

    return (
        <div className={cn("search", className)}>
            <Container className="z-10">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 mb-8">
                        <DepartureField schema={searchFormSchema} form={form} />
                        <TravelDateField schema={searchFormSchema} form={form} />
                        <NumberOfTravelersField schema={searchFormSchema} form={form} />
                    </form>
                </Form>
            </Container>
        </div>
    );
    };

export default Search;
