import * as React from "react";
const SvgDeparturePlane = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 19"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.177 17.727h22.63M1.264 9.552l3.94 3.646a3.382 3.382 0 0 0 3.969.446L25 4.56l-1.948-2.35a3.35 3.35 0 0 0-2.001-1.16 3.38 3.38 0 0 0-2.284.405l-4.638 2.693-6.272-2.064-1.724.73a.84.84 0 0 0-.496.624.82.82 0 0 0 .266.748l3.209 2.85-2.927 1.651L3.258 7.45l-1.751.741a.84.84 0 0 0-.494.616.82.82 0 0 0 .251.745"
    />
  </svg>
);
export default SvgDeparturePlane;
