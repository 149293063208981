import * as React from "react";
const SvgShare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 45 45"
    {...props}
  >
    <circle cx={22.056} cy={22.166} r={23.366} fill="currentBackground" />
    <path
      fill="currentColor"
      d="M26.26 23.65a4.4 4.4 0 0 0-1.792.393 4.2 4.2 0 0 0-1.443 1.082l-5.354-2.341a3.45 3.45 0 0 0 0-2.252l5.354-2.34a4.25 4.25 0 0 0 2.487 1.378c.974.167 1.98.002 2.838-.465a4.05 4.05 0 0 0 1.854-2.09c.34-.883.34-1.85 0-2.733a4.05 4.05 0 0 0-1.855-2.09 4.4 4.4 0 0 0-2.838-.464 4.25 4.25 0 0 0-2.486 1.379 3.86 3.86 0 0 0-.966 2.574q.006.356.074.707l-5.544 2.421a4.3 4.3 0 0 0-2.151-1.065 4.4 4.4 0 0 0-2.414.241 4.16 4.16 0 0 0-1.87 1.468 3.84 3.84 0 0 0-.7 2.205c0 .784.243 1.551.7 2.204a4.16 4.16 0 0 0 1.87 1.469 4.4 4.4 0 0 0 2.414.242 4.3 4.3 0 0 0 2.151-1.066l5.544 2.42q-.069.351-.074.708c0 .788.247 1.559.708 2.214a4.16 4.16 0 0 0 1.885 1.467c.767.302 1.612.38 2.427.227a4.28 4.28 0 0 0 2.15-1.09 3.93 3.93 0 0 0 1.15-2.04 3.8 3.8 0 0 0-.24-2.303 4.04 4.04 0 0 0-1.546-1.788 4.36 4.36 0 0 0-2.334-.672"
    />
  </svg>
);
export default SvgShare;
