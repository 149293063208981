import { z } from "zod";

export const registerCredentialsSchema = z.object({
  fullName: z
    .string({
      required_error: "Please enter your full name.",
    })
    .nonempty("Please enter your full name."),
  email: z
    .string({
      required_error: "Please enter your email address.",
    })
    .nonempty("Please enter your email address.")
    .email("This is not a valid email."),
  password: z
    .string({
      required_error: "Please enter your password.",
    })
    .nonempty("Please enter your password.").min(6, "Password should be at least 6 characters long."),
  gender: z
    .enum(["male", "female", "prefer_not_to_say"], {
      message: "Please select your gender."
    }),
  nationality: z
    .string({
      required_error: "Please select your nationality.",
    })
    .nonempty("Please select your nationality."),
  country_code: z
    .string({
      required_error: "Please select your country code.",
    })
    .nonempty("Please select your country code."),
  phone: z
    .string({
      required_error: "Please enter your mobile number.",
    })
    .nonempty("Please enter your mobile number."),
});

export const loginCredentialsSchema = z.object({
  email: z
    .string({
      required_error: "Please enter your email address.",
    })
    .nonempty("Please enter your email address.")
    .email("This is not a valid email."),
  password: z
    .string({
      required_error: "Please enter your password.",
    })
    .nonempty("Please enter your password.").min(6, "Password should be at least 6 characters long."),
});

export const forgotPasswordSchema = z.object({
  email: z
    .string({
      required_error: "Please enter your email address.",
    })
    .nonempty("Please enter your email address.")
    .email("This is not a valid email."),
});
