import { Loader2 } from 'lucide-react'
import React from 'react'

const Spinner = () => {
    return (
        <Loader2 className="w-4 h-4 animate-spin" />
    )
}

export default Spinner
