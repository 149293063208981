import * as React from "react";
const SvgBeds = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 13"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.194 5.972v5.972a.597.597 0 1 1-1.194 0V5.972a.597.597 0 1 1 1.194 0"
    />
    <path
      fill="currentColor"
      d="M17 5.972h14.93v5.375H17zM18.193 1.194v3.583h1.195V4.18a.597.597 0 0 1 .597-.597h3.284a.597.597 0 0 1 .598.597v.602h1.194V4.18a.597.597 0 0 1 .597-.597h3.284a.597.597 0 0 1 .598.597v.597h1.194V1.194A1.194 1.194 0 0 0 29.54 0H19.388a1.194 1.194 0 0 0-1.195 1.194"
    />
    <path
      fill="currentColor"
      d="M31.929 5.972v5.972a.597.597 0 1 1-1.195 0V5.972a.597.597 0 1 1 1.195 0M1.194 5.972v5.972a.597.597 0 1 1-1.194 0V5.972a.597.597 0 1 1 1.194 0"
    />
    <path
      fill="currentColor"
      d="M0 5.972h14.93v5.375H0zM1.193 1.194v3.583h1.195V4.18a.597.597 0 0 1 .597-.597h3.284a.597.597 0 0 1 .597.597v.602h1.195V4.18a.597.597 0 0 1 .597-.597h3.284a.597.597 0 0 1 .598.597v.597h1.194V1.194A1.194 1.194 0 0 0 12.54 0H2.388a1.194 1.194 0 0 0-1.195 1.194"
    />
    <path
      fill="currentColor"
      d="M14.929 5.972v5.972a.597.597 0 1 1-1.195 0V5.972a.597.597 0 1 1 1.195 0"
    />
  </svg>
);
export default SvgBeds;
