import { Link } from 'react-router-dom'
import { buttonVariants } from 'src/components/ui/button'

const AlreadyAMember = () => {
    return (
        <div className="mb-0 md:mb-5 pb-10">
            <h4 className=" text-white font-semibold text-[15px] sm:text-lg text-center xl:text-start leading-3 mb-10">
                Already a member?
                <Link
                to="/login"
                className={buttonVariants({
                    variant: "link",
                    className:
                    "-translate-y-[1px] text-white inline underline hover:no-underline hover:text-green text-[15px] sm:text-lg !font-semibold !py-0 !px-1 sm:!px-1.5",
                })}
                >
                Sign in
                </Link>
                Now!
            </h4>
            <p className="text-white text-[10px] sm:text-xs font-regular font-main italic text-center xl:text-start">
                By proceeding you agree to our Terms of Use and confirm you have read our Privacy and Cookie Statement.
            </p>
        </div>
    )
}

export default AlreadyAMember
