import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import "./App.css";
import "./styles/main.css";
import { Toaster } from "./components/ui/toaster";
import { axiosClient } from './config/axios'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

/**
 * Data fetching using "Tanstack React Query"
 * 
 *  */ 
const queryClient = new QueryClient()
console.log('query client: ', queryClient)
console.log('axiosClient', axiosClient)

/**
 * The App component sets up the main application environment.
 * It provides a QueryClientProvider for handling server state
 * using react-query, a Router for managing navigation, 
 * and a Toaster for displaying notifications. 
 * Routes are rendered within the Router, and there is an optional 
 * ScrollArea for managing scroll behavior.
 */
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {/* Toaster for notifications */}
        <Toaster />
        {/* Routes with conditional layouts */}
        {/* <ScrollArea className="w-full max-w-screen h-screen"> */}
        <Routes />
        {/* </ScrollArea> */}
      </Router>
    </QueryClientProvider>
  );
};

export default App;
