import { useEffect, useRef, useState } from "react";
import { Form, FormControl, FormField, FormItem, FormMessage } from "src/components/ui/form";
import { Button } from "src/components/ui/button";
import { toast } from "src/hooks/use-toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { registerCredentialsSchema } from "src/schemas/auth";
import { Input } from "src/components/ui/input";
import { cn } from "src/lib/utils";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Eye, EyeOff } from "lucide-react";
import PhoneInput from "../components/PhoneInput";
import Spinner from "src/components/common/Spinner";
import FormError from "src/components/common/FormError";
import useRegister from "src/hooks/use-register"; // Import your useRegister hook

const RegisterCredentialsForm = () => {
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const [isPassword, setIsPassword] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const form = useForm<z.infer<typeof registerCredentialsSchema>>({
        resolver: zodResolver(registerCredentialsSchema),
    });

    // Using the useRegister hook
    const { mutate, isPending, isSuccess, isError, data } = useRegister();

    const handleToggleShowPassword = () => {
        const currentType = passwordInputRef.current?.getAttribute("type");
        const isPassword = currentType === 'password' ? true : false;
        passwordInputRef.current?.setAttribute("type", `${isPassword ? 'text' : 'password'}`);
        setIsPassword(isPassword ? false : true);
    };

    // Handle form submission
    function onSubmit(data: z.infer<typeof registerCredentialsSchema>) {
        setIsSubmitting(true);
        mutate(data); // Call mutate from useRegister hook
    }

    // Handle success and error states
    useEffect(() => {
        if (isSubmitting && isSuccess) {
            toast({
                variant: "default",
                title: "👍 Success login!",
            });
            setIsSubmitting(false);
        }
        if (isSubmitting && isError) {
            toast({
                variant: "destructive",
                title: "Registration failed!",
                description: "An error occurred while registering the user.",
            });
            setIsSubmitting(false);
        }
    }, [isSubmitting, isSuccess, isError]);

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="grid grid-cols-1 xl:grid-cols-2 gap-x-5 gap-y-7 w-full max-w-[480px] xl:max-w-[630px] mx-auto xl:ms-0 pb-8"
            >
                {/* Full Name */}
                <div className="order-1 form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="fullName"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type="text"
                                        placeholder="Full Name"
                                        {...field}
                                        className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('fullName').invalid }
                                        )}
                                    />
                                </FormControl>
                                <FormError />
                            </FormItem>
                        )}
                    />
                </div>

                {/* Email */}
                <div className="order-2 xl:order-3 form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        {...field}
                                        className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('email').invalid }
                                        )}
                                    />
                                </FormControl>
                                <FormError />
                            </FormItem>
                        )}
                    />
                </div>

                {/* Password */}
                <div className="order-3 xl:order-5 form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                        {...field}
                                        ref={(e) => {
                                            passwordInputRef.current = e;
                                        }}
                                        className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('password').invalid }
                                        )}
                                    />
                                </FormControl>
                                <FormError />
                            </FormItem>
                        )}
                    />
                    <div className="absolute right-2 top-[18px] translate-x-[-50%] transition-all ease-in-out duration-0">
                        {isPassword ? (
                            <EyeOff onClick={() => handleToggleShowPassword()} className="w-6 text-white cursor-pointer" />
                        ) : (
                            <Eye onClick={() => handleToggleShowPassword()} className="w-6 text-white cursor-pointer" />
                        )}
                    </div>
                </div>

                {/* Gender */}
                <div className="order-4 xl:order-2 form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="gender"
                        render={({ field }) => (
                            <FormItem>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <FormControl>
                                        <SelectTrigger className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('gender').invalid }
                                        )}>
                                            <SelectValue placeholder="Gender" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent className="rounded-[16px] max-w-[14.5rem] max-h-[450px] mx-auto">
                                        <SelectGroup className="divide-y divide-gray-400 max-w-[14rem] px-4 py-3 min-w-[13rem]">
                                            <SelectItem value={'male'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Male</SelectItem>
                                            <SelectItem value={'female'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Female</SelectItem>
                                            <SelectItem value={'prefer_not_to_say'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Prefer not to say</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                <FormError />
                            </FormItem>
                        )}
                    />
                </div>

                {/* Nationality */}
                <div className="order-5 xl:order-4 form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="nationality"
                        render={({ field }) => (
                            <FormItem>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <FormControl>
                                        <SelectTrigger className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('gender').invalid }
                                        )}>
                                            <SelectValue placeholder="Nationality" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent className="rounded-[16px] max-w-[14.5rem] max-h-[450px] mx-auto">
                                        <SelectGroup className="divide-y divide-gray-400 max-w-[14rem] px-4 py-3 min-w-[13rem]">
                                            <SelectItem value={'country-1'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Country 1</SelectItem>
                                            <SelectItem value={'country-2'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Country 2</SelectItem>
                                            <SelectItem value={'country-3'} className="text-xs sm:text-[13px] xl:text-sm cursor-pointer py-4 px-2 rounded-none hover:!bg-lightBlue">Country 3</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                <FormError />
                            </FormItem>
                        )}
                    />
                </div>

                {/* Mobile */}
                <PhoneInput form={form} schema={registerCredentialsSchema} />

                {/* Submit Button */}
                <Button
                    type="submit"
                    variant={"default"}
                    disabled={isSubmitting}
                    className="order-7 primary-button w-full h-14 text-sm sm:text-[15px] xl:text-base font-main font-medium py-4 flex justify-center items-center gap-1.5"
                >
                    {isSubmitting  ? <Spinner /> : null}
                    Join
                </Button>
            </form>
        </Form>
    );
};

export default RegisterCredentialsForm;
