import AuthBg from "src/assets/images/auth/bg.jpg";
import Container from "src/components/common/Container";
import AuthHeader from "../components/AuthHeader";
import ForgotPasswordForm from "./ForgotPasswordForm";
const ForgotPassword = () => {
    return (
        <div className="relative bg-no-repeat h-auto w-full bg-cover pt-px pb-px" style={{
                backgroundImage: `url(${AuthBg})`
        }}>
            <Container>
                {/* header */}
                <AuthHeader to="/login" />
                <div className="mx-auto xl:ms-0 max-w-[480px] xl:max-w-[630px] pb-20">
                    {/* title */}
                    <div className="flex flex-col items-center xl:items-start gap-6 mb-8">
                        <h1 className="text-white text-xl font-bold font-main">
                            Forgot Password
                        </h1>
                        <hr className="w-24 h-1" />
                        <div className="flex w-10/12 pt-5">
                            <p className="text-white text-sm font-regular font-main text-center xl:text-start">
                                Enter your email address below and we will send the reset link to
                                your email.
                            </p>
                        </div>
                    </div>
                    {/* forget password form */}
                    <ForgotPasswordForm />
                </div>
            </Container>
        </div>
    );
};

export default ForgotPassword;
