import ImageCarousel from "src/components/common/carousels/ImageCarousel";
import tourDetails from "src/assets/images/ui/tour-details.jpg";
import { LocationPin } from "src/components/icons";

const Destinations = () => {
    const images = [
        { src: tourDetails, title: 'Destination 1', subtitle: '3 nights / 4 days' },
        { src: tourDetails, title: 'Destination 2', subtitle: '1 nights / 2 days' },
        { src: tourDetails, title: 'Destination 3', subtitle: '2 nights / 3 days' },
        { src: tourDetails, title: 'Destination 4', subtitle: '2 nights / 3 days' },
    ];

    return (
        <div className="w-full sm:px-2 xl:px-4">
            <h3 className="text-darkTeal font-semibold text-sm flex items-center gap-2 mb-4">
                <LocationPin className="w-4 h-4 text-teal" />
                Destinations ( 4 Destinations )
            </h3>
            <ImageCarousel images={images} />
        </div>
    );
};

export default Destinations;
