import * as React from "react";
const SvgPets = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.593 2.157c.45-1.494 1.86-2.43 3.146-2.086.637.174 1.142.623 1.424 1.271.273.623.302 1.358.089 2.068-.387 1.286-1.483 2.157-2.602 2.157a2.2 2.2 0 0 1-.545-.07c-1.283-.348-1.962-1.842-1.512-3.34m9.276 5.187a2.97 2.97 0 0 1-1.149 1.602 2.26 2.26 0 0 1-1.28.419q-.265 0-.521-.072c-.597-.158-1.073-.582-1.339-1.19-.253-.583-.282-1.268-.082-1.934.42-1.401 1.742-2.276 2.95-1.953.597.161 1.073.586 1.339 1.193.252.583.282 1.268.082 1.935M4.748 3.41c-.213-.71-.184-1.445.089-2.068C5.119.694 5.624.245 6.26.072c1.286-.345 2.697.591 3.146 2.085.45 1.498-.23 2.992-1.512 3.34a2.2 2.2 0 0 1-.545.07c-1.119 0-2.215-.87-2.602-2.157m13.578 9.625c.275 1.333-.073 2.517-.958 3.249-.571.474-1.313.716-2.133.716-.47 0-.968-.08-1.473-.242-.988-.313-2.055-.468-3.262-.468s-2.274.155-3.262.468c-1.39.443-2.707.273-3.606-.474-.886-.732-1.233-1.916-.958-3.249.463-2.22 2.904-4.355 4.873-5.756.846-.57 1.742-.88 2.68-.93v-.01h.404c.076.003.135 0 .145 0v.01a5.07 5.07 0 0 1 2.677.93c1.969 1.4 4.41 3.537 4.873 5.756M3.08 9.293q-.255.071-.522.072a2.26 2.26 0 0 1-1.28-.419A2.97 2.97 0 0 1 .132 7.344c-.2-.667-.17-1.352.082-1.935.266-.607.742-1.032 1.34-1.193 1.207-.323 2.529.552 2.949 1.953.2.666.17 1.351-.082 1.934-.266.608-.742 1.032-1.339 1.19"
    />
  </svg>
);
export default SvgPets;
