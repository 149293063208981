import { Link } from "react-router-dom";
import { Card } from "../../ui/card";
import { buttonVariants } from "../../ui/button";
import { TrendingCardData } from "src/interfaces/trendingCard";
import ShareButton from "../../common/buttons/ShareButton";
import FavouriteButton from "../../common/buttons/FavouriteButton";

const TrendingCard = ({
    data
}: {
    data: TrendingCardData
}) => {
    const {
        id,
        imageSrc,
        title
    } = data;
    return (
        <Card className="max-w-[90vw] sm:max-w-[400px] h-[354px] sm:h-[412px] relative mb-2 rounded-[30px] border-none overflow-hidden">
            <Link
                to={`/tour-packages/${id}`}
                className={'cursor-pointer'}>
                <img src={imageSrc} alt="cardImage" className="w-full h-full object-cover" />
            </Link>
            <div className="absolute top-5 right-5 flex items-center gap-3">
                <ShareButton />
                <FavouriteButton />
            </div>
            <Link
                to={`/tour-packages/${id}`}
                className={'cursor-pointer'}>
                <div className="absolute bottom-0 h-1/2 left-0 right-0 w-full bg-gradient-to-t from-black/[.88] via-black/[.45] to-transparent z-[9]"></div>
            </Link>
            <div className="absolute max-w-full px-2 sm:px-4 bottom-4 left-0 flex flex-col items-start gap-1 text-white z-10">
                <Link
                to={`/tour-packages/${id}`}
                    className={'cursor-pointer'}>
                    <h3 className="px-4 w-full truncate">{title}</h3>
                </Link>
                <Link to={`/tour-packages/${id}`} className={
                    buttonVariants({
                        variant: 'link',
                        className: 'underline sm:no-underline ms-1 sm:ms-0 text-white text-xs sm:text-sm text-start'
                    })
                }>Read More</Link>
            </div>
        </Card>
    );
};

export default TrendingCard;
