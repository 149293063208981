import SvgLogoFooter from "../icons/LogoFooter";
import SvgLogoFooterCenter from "../icons/LogoFooterCenter";
import { Button } from "../ui/button";
import Container from "../common/Container";

const Footer = () => {
    return (
        <footer className="bg-darkTeal text-white pt-16 pb-4">
            <Container className="flex flex-col xl:flex-row xl:justify-between gap-8">
                <div className="w-full flex justify-center xl:justify-start items-start pb-10 border-b-[0.5px] xl:border-b-0 border-white xl:pb-0">
                    <SvgLogoFooter className="hidden xl:block h-16" />
                    <SvgLogoFooterCenter className="block xl:hidden h-32" />
                </div>
                <div className="h-80 xl:h-full"></div>
                <div className="w-full xl:max-w-80 pt-10 border-t-[0.5px] xl:!border-t-0 xl:border-l-[0.5px] border-white pb-10 xl:pb-56 xl:ps-10 xl:pt-1 flex flex-col items-center xl:items-start gap-4">
                    <h3 className="text-[17px] sm:text-lg">
                        Become a partner
                    </h3>
                    <p className="text-xs sm:text-sm font-normal max-w-80 text-center xl:text-start mb-2 xl:mb-0">
                        Lorem ipsum dolor sit amet consectetur Est nullam non mattis suspendisse et...
                    </p>
                    <Button variant={'outline'} className="text-xs sm:text-sm h-[36px] sm:h-[40px] px-8 w-max bg-transparent">Start Now</Button>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
