import TextWithContent from "src/components/common/text/TextWithContent"


const Policies = () => {
    return (
        <div className="space-y-5">
            <TextWithContent text={'Payment & Refund Policy:'} content={
                <ul>
                    <li>
                        Lorem ipsum dolor sit amet consectetur. Faucibus porttitor
                        dignissim ullamcorper neque tristique quam laoreet.
                        Ullamcorper odio nunc tristique mauris tempor nulla. Ac
                        pulvinar interdum aliquet aliquet interdum dui. Tristique
                        interdum lectus viverra consectetur sem eu elit.
                    </li>
                    <li>
                        Et libero iaculis ultricies parturient sed. Id ornare purus
                        tincidunt magna sem bibendum. Orci sapien quam nisl aliquet.
                        Etiam consectetur morbi amet scelerisque pulvinar. Etiam
                        lacinia porta non tempus nam tellus sagittis. Nisl blandit est
                        quisque justo nisi amet tristique aenean gravida. Egestas at
                        ac adipiscing lacus habitant metus. Ultrices senectus sed
                        posuere elementum purus nibh quis neque dignissim.
                    </li>
                    <li>
                        Tortor donec in tempus risus. Justo varius est sed at ut. In
                        urna adipiscing at mauris sapien massa egestas. Enim curabitur
                        eget sapien feugiat. Ultrices nisl duis felis enim sem quam.
                        Hendrerit vel eu fringilla cursus. Amet.
                    </li>
                </ul>
            } />
            
            <TextWithContent text={'Cancellation Policy:'} content={
                <ul>
                    <li>
                        Lorem ipsum dolor sit amet consectetur. Faucibus porttitor
                        dignissim ullamcorper neque tristique quam laoreet.
                        Ullamcorper odio nunc tristique mauris tempor nulla. Ac
                        pulvinar interdum aliquet aliquet interdum dui. Tristique
                        interdum lectus viverra consectetur sem eu elit.
                    </li>
                </ul>
            } />
            
            <TextWithContent text={'Children Policy:'} content={
                <ul>
                    <li>
                        Lorem ipsum dolor sit amet consectetur. Faucibus porttitor
                        dignissim ullamcorper neque tristique quam laoreet.
                        Ullamcorper odio nunc tristique mauris tempor nulla. Ac
                        pulvinar interdum aliquet aliquet interdum dui. Tristique
                        interdum lectus viverra consectetur sem eu elit.
                    </li>
                    <li>
                        Et libero iaculis ultricies parturient sed. Id ornare purus
                        tincidunt magna sem bibendum. Orci sapien quam nisl aliquet.
                        Etiam consectetur morbi amet scelerisque pulvinar. Etiam
                        lacinia porta non tempus nam tellus sagittis. Nisl blandit est
                        quisque justo nisi amet tristique aenean gravida. Egestas at
                        ac adipiscing lacus habitant metus. Ultrices senectus sed
                        posuere elementum purus nibh quis neque dignissim.
                    </li>
                </ul>
            }  />
        </div>
    )
}

export default Policies
