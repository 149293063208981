import * as React from "react";
const SvgLocationPin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.964 6.876a7.67 7.67 0 0 0-1.322-3.635A7.5 7.5 0 0 0 10.725.74a7.41 7.41 0 0 0-7.372.528 7.6 7.6 0 0 0-2.279 2.428A7.7 7.7 0 0 0 .036 6.876a7.7 7.7 0 0 0 .406 3.303 7.6 7.6 0 0 0 1.764 2.807l4.676 4.75A.88.88 0 0 0 7.51 18a.87.87 0 0 0 .626-.265l4.659-4.75a7.6 7.6 0 0 0 1.764-2.806 7.7 7.7 0 0 0 .406-3.303m-3.405 4.848L7.5 15.84l-4.059-4.115A5.8 5.8 0 0 1 2.1 9.577a5.933 5.933 0 0 1 .487-4.973 5.84 5.84 0 0 1 1.754-1.87A5.65 5.65 0 0 1 7.5 1.768c1.124 0 2.223.336 3.159.966a5.84 5.84 0 0 1 1.75 1.862c.441.744.714 1.578.8 2.441a5.9 5.9 0 0 1-.305 2.532 5.8 5.8 0 0 1-1.345 2.155M7.5 3.63c-.785 0-1.553.236-2.206.678a4 4 0 0 0-1.462 1.807c-.3.735-.38 1.545-.226 2.326.153.78.531 1.498 1.086 2.06a3.95 3.95 0 0 0 2.033 1.102 3.9 3.9 0 0 0 2.294-.229 4 4 0 0 0 1.782-1.482 4.065 4.065 0 0 0-.496-5.08A3.95 3.95 0 0 0 7.5 3.629m0 6.262c-.436 0-.863-.132-1.225-.377a2.23 2.23 0 0 1-.813-1.004 2.27 2.27 0 0 1 .478-2.437 2.178 2.178 0 0 1 2.404-.485c.403.17.748.456.99.824a2.26 2.26 0 0 1-.274 2.824 2.2 2.2 0 0 1-1.56.655"
    />
  </svg>
);
export default SvgLocationPin;
