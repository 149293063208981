import SvgDeparturePlane from "../icons/DeparturePlane";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { FormControl, FormField, FormItem } from "../ui/form";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import SvgLocationPin from "../icons/LocationPin";
import { Plane } from "lucide-react";
import { cn } from "src/lib/utils";
import FormError from "../common/FormError";

const DepartureField = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
}) => {
    
    const options = [
        {
            country: "New York City, NY",
            code: "NYC",
            airports: [
                { name: "John F Kennedy International Airport", code: "JFK" },
                { name: "LaGuardia Airport", code: "LGA" },
                { name: "Newark International Airport", code: "EWR" },
            ],
        },
        {
            country: "Los Angeles, CA",
            code: "LAX",
            airports: [
                { name: "Los Angeles International Airport", code: "LAX" },
                { name: "Hollywood Burbank Airport", code: "BUR" },
            ],
        },
            {
            country: "Chicago, IL",
            code: "CHI",
            airports: [
                { name: "O'Hare International Airport", code: "ORD" },
                { name: "Midway International Airport", code: "MDW" },
            ],
        },
    ];

    return (
        <FormField
            control={form.control}
            name="departure"
            render={({ field }) => (
                <FormItem>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                            <SelectTrigger className={cn({'border-red-500': form.getFieldState('departure').invalid})}>
                                <div className="flex justify-start items-center gap-2">
                                    <SvgDeparturePlane className="w-6" />
                                    <div className="text-xs sm:text-[13px] xl:text-sm text-start selected-value-remove-icon">
                                        <SelectValue placeholder="Departure From" />
                                    </div>
                                </div>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent className="rounded-[16px] py-4">
                            {
                                options.map((option) => (
                                    <SelectGroup key={option.code}>
                                        <SelectLabel className="text-xs sm:text-[13px] xl:text-sm flex items-center gap-2 px-4">
                                            <SvgLocationPin className="w-3 text-darkBlue fill-darkBlue" />
                                            <div className="flex flex-col gap-0">
                                                <span>
                                                    {option.country}
                                                </span>
                                                {/* <span className="text-sm font-normal text-grey">
                                                    All Airports ({option.code})
                                                </span> */}
                                            </div>
                                        </SelectLabel>
                                        {
                                            option.airports.map(airport => (
                                                <SelectItem key={airport.code} value={airport.code} className="text-xs sm:text-[13px] xl:text-sm ps-8 pe-4 py-2 rounded-none hover:!bg-lightBlue">
                                                    <div className="flex items-center gap-2">
                                                        <Plane className="w-3 text-darkBlue" />{`${airport.name} (${airport.code})`}
                                                    </div>
                                                </SelectItem>
                                            ))
                                        }
                                    </SelectGroup>
                                ))
                            }
                        </SelectContent>
                    </Select>
                    {/* Errors */}
                    <FormError />
                </FormItem>
            )}
        />
    );
};

export default DepartureField;