import MultiSelect from "../common/MultiSelect";
import MultiSelect2 from "../common/MultiSelect2";
import { FormField, FormItem } from "../ui/form";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { MAX_DESTINATIONS_SELECTION } from "src/utils/constants";
import FormError from "../common/FormError";
import useGeneralSettings from "src/hooks/use-general-settings";

const DestinationField = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
}) => {
    // Get General settings
    const { data: generalSettings } = useGeneralSettings();   
    
    const options = [
        {
            value: "CAI",
            label: "Cairo, Egypt (CAI)"
        },
        {
            value: "HRG",
            label: "Hurghada, Egypt (HRG)"
        },
        {
            value: "AIS",
            label: "Ain Sokhna, Egypt (AIS)"
        },
        {
            value: "SSH",
            label: "Sharm Al Shaikh, Egypt (SSH)"
        },
        {
            value: "ALX",
            label: "Alexandria, Egypt (ALX)"
        }
        ,
        {
            value: "shjhsd",
            label: "sdsdsdsd"
        }
         ,
        {
            value: "sdd",
            label: "ffd"
        }
    ];

    return (
        <FormField
            control={form.control}
            name="destinations"
            render={({ field }) => (
                <FormItem>
                    <MultiSelect
                        form={form}
                        schema={schema}
                        fieldName={field.name}
                        options={options}
                        maxSelection={
                            /* TODO change type to number in interface */
                            generalSettings?.data.settingsMaxNumberOfDestination || MAX_DESTINATIONS_SELECTION
                        }
                        placeholder="Destinations" />
                    {/* Errors */}
                    <FormError />
                </FormItem>
            )}
        />
    );
};

export default DestinationField;
