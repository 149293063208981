import Container from "src/components/common/Container";
import { TrendingCardData } from "src/interfaces/trendingCard";
import Trending1 from 'src/assets/images/ui/trending-1.jpg';
import Trending2 from 'src/assets/images/ui/trending-2.jpg';
import Trending3 from 'src/assets/images/ui/trending-3.jpg';
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "src/components/ui/carousel";
import TrendingCard from "src/components/cards/trendingCard/TrendingCard";
import { useState } from "react";
import { DotButtons } from "src/components/common/carousels/components/DotButtons";
const Trending = () => {
    const [cardsCarouselApi, setCardsCarouselApi] = useState<CarouselApi>()

    const trendingItems: TrendingCardData[] = [
        {
            id: 1,
            imageSrc: Trending1,
            title: 'Lorem ipsum dummy text'
        },
        {
            id: 2,
            imageSrc: Trending2,
            title: 'Lorem ipsum dummy text'
        },
        {
            id: 3,
            imageSrc: Trending3,
            title: 'Lorem ipsum dummy text'
        },
        {
            id: 4,
            imageSrc: Trending1,
            title: 'Lorem ipsum dummy text'
        },
        {
            id: 5,
            imageSrc: Trending2,
            title: 'Lorem ipsum dummy text'
        },
    ]

    return (
        <div className="pt-10 pb-14 sm:pt-14 sm:pb-20 bg-lightBlue">
            <Container>
                <h2 className="text-center sm:text-start mb-8 text-darkBlue">Trending Now</h2>
                <div className="w-full overflow-visible">
                    <Carousel setApi={setCardsCarouselApi} opts={{
                    align: "start",
                    skipSnaps: true
                    }} className="slider-visible-overflow-sm w-full mx-auto">
                        <CarouselContent className="w-full mx-auto ml-0 justify-evenly gap-5">
                            {
                                trendingItems.map((item) => (
                                    <CarouselItem key={item.id} className="basis-full sm:basis-auto ps-0">
                                        <TrendingCard data={item} />
                                    </CarouselItem>
                                ))
                            }
                        </CarouselContent>
                        {/* Dots */}
                        <DotButtons
                            carouselApi={cardsCarouselApi}
                            className="sm:hidden"
                        />
                    </Carousel>
                    
                </div>
                
            </Container>
        </div>
    );
};

export default Trending;
