import * as React from "react";
const SvgRoomMates = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="M25.002 7.43a1.715 1.715 0 1 0 0-3.43 1.715 1.715 0 0 0 0 3.43m2.573 3.86a.43.43 0 0 0 .428-.429 3.002 3.002 0 1 0-6.003 0 .43.43 0 0 0 .429.429zM32.002 7.43a1.715 1.715 0 1 0 0-3.43 1.715 1.715 0 0 0 0 3.43m2.573 3.86a.43.43 0 0 0 .428-.429 3.002 3.002 0 1 0-6.003 0 .43.43 0 0 0 .429.429zM4.704 5.376a2.688 2.688 0 1 0 0-5.376 2.688 2.688 0 0 0 0 5.376m4.032 6.048a.67.67 0 0 0 .672-.672 4.704 4.704 0 0 0-9.408 0 .67.67 0 0 0 .672.672zM14.704 5.376a2.688 2.688 0 1 0 0-5.376 2.688 2.688 0 0 0 0 5.376m4.032 6.048a.67.67 0 0 0 .672-.672 4.704 4.704 0 0 0-9.408 0 .67.67 0 0 0 .672.672z"
    />
  </svg>
);
export default SvgRoomMates;
