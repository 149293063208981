import { Loader2 } from 'lucide-react'
import React, { useState, useCallback } from 'react'
import { cn } from 'src/lib/utils'

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`

type PropType = {
    title: string;
    imgSrc: string;
    inView: boolean;
    index: number;
    className?: string;
}

export const LazyLoadImage: React.FC<PropType> = ({
    imgSrc,
    inView,
    title,
    index,
    className,
}) => {
    const [hasLoaded, setHasLoaded] = useState(false)

    const setLoaded = useCallback(() => {
        if (inView) setHasLoaded(true)
    }, [inView, setHasLoaded])

    return (
        <div
            className={cn('relative h-72 w-full overflow-hidden rounded-[10px] sm:rounded-[20px] xl:rounded-[30px]',
                {
                    'loaded': hasLoaded
                },
                className
            )}
        >
            <img
                className="lazy-load-image w-full h-full object-cover"
                onLoad={setLoaded}
                src={inView ? imgSrc : PLACEHOLDER_SRC}
                alt={title}
                data-src={imgSrc}
            />
            {
                !hasLoaded ?
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-lightGrey w-full h-full z-[0] flex justify-center items-center">
                        <Loader2 className='w-5 h-5 animate-spin' />
                    </div>
                    : null
            }
        </div>
    )
}