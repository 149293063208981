import "../../styles/home/search.css";
import DepartureField from "./DepartureField";
import DestinationField from "./DestinationField";
import PriceRangeField from "./PriceRangeField";
import NumberOfTravelersField from "./NumberOfTravelersField";
import TravelDateField from "./TravelDateField";
import Container from "../common/Container";
import { Button, buttonVariants } from "src/components/ui/button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";
import { Form } from "src/components/ui/form";
import { searchFormSchema } from "src/schemas/search";
import { Link } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { cn } from "src/lib/utils";
import Spinner from "../common/Spinner";
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import axios from "axios";
import { GENERAL_SETTINGS_URL, PRICE_RANGE_URL } from "src/utils/constants";
import { GeneralSettings } from "src/interfaces/settings";
import { toast } from "src/hooks/use-toast";
import { PriceRangeList } from "src/interfaces/priceRange";


const Search = ({
    notAdvanced,
    className,
}: {
    notAdvanced?: boolean
    className?: string;
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const form = useForm<z.infer<typeof searchFormSchema>>({
        resolver: zodResolver(searchFormSchema),
    })

    function onSubmit(formValues: z.infer<typeof searchFormSchema>) {
        setIsSubmitting(true)
        console.log(formValues)
    }

    


    return (
        <div className={cn("search",  className)}>
            <Container className="z-10">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 mb-8">
                        <DepartureField schema={searchFormSchema} form={form} />
                        <TravelDateField schema={searchFormSchema} form={form} />
                        <NumberOfTravelersField schema={searchFormSchema} form={form} />
                        <DestinationField schema={searchFormSchema} form={form} />
                        <PriceRangeField schema={searchFormSchema} form={form} />
                        <Button
                            type="submit"
                            variant={'default'}
                            disabled={isSubmitting}
                            className="primary-button text-sm sm:text-[15px] xl:text-base w-full h-14"
                        >
                            {
                                isSubmitting ?  <Spinner /> : null
                            }
                            Search
                        </Button>     
                    </form>
                </Form>

                <div className={cn("flex justify-end items-center", {"hidden": notAdvanced})}>
                    <Link to='/search' className={buttonVariants({
                        variant: 'link',
                        className: 'text-white underline hover:no-underline text-sm sm:text-[15px] xl:text-base !font-bold'
                    })}>Advanced Search</Link>
                </div>
            </Container>
        </div>
    );
};

export default Search;
