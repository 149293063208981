import * as React from "react";
const SvgLogoOutlook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 31"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.825 6.53v7.804l1.941 1.652 11.11-9.456zm13 17.28-8.3-6.927-1.958 1.666a1.25 1.25 0 0 1-1.602 0l-1.14-.97v6.231zm-6.392-8.552 8.562 7.145L36 7.966zM9.207 11.765c-1.835 0-2.656 1.723-2.656 3.43 0 1.965 1.1 3.391 2.617 3.391s2.617-1.442 2.617-3.43c0-1.173-.54-3.392-2.578-3.392"
    />
    <path
      fill="currentColor"
      d="M18.353 0 0 2.645v25.06l18.354 2.647h.002zM9.088 20.474c-2.873 0-4.88-2.146-4.88-5.22 0-3.166 2.072-5.377 5.038-5.377 2.884 0 4.899 2.146 4.899 5.22 0 3.694-2.622 5.377-5.057 5.377"
    />
  </svg>
);
export default SvgLogoOutlook;
