import { ChevronLeft, ChevronRight } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { Carousel, CarouselApi, CarouselContent } from "src/components/ui/carousel"
import { cn } from "src/lib/utils";

const ButtonCarousel = ({
    children,
    className,
    carouselClassName,
    hideBtnGradient,
}: {
    children: ReactNode;
    className?: string;
    carouselClassName?: string;
    hideBtnGradient?: boolean;
}) => {
    const [api, setApi] = useState<CarouselApi>()
    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)
    
    useEffect(() => {
        if (!api) return;
        setCanScrollPrev(api?.canScrollPrev()!)
        setCanScrollNext(api?.canScrollNext()!)
        api.on('scroll', () => {
            setCanScrollPrev(api?.canScrollPrev()!)
            setCanScrollNext(api?.canScrollNext()!)
        })
        api.on('resize', () => {
            setCanScrollPrev(api?.canScrollPrev()!)
            setCanScrollNext(api?.canScrollNext()!)
        })
    }, [api])
    return (
        <Carousel setApi={setApi} className={cn("relative w-full", carouselClassName)} opts={{
            skipSnaps: true,
            align: "start",
            slidesToScroll: 3,
        }}>
            <CarouselContent className={cn("w-full", className)}>
                {children}
            </CarouselContent>
            
            <div className="absolute h-full top-1/2 inset-x-0 -translate-y-1/2 pointer-events-none">
                {
                    canScrollPrev && !hideBtnGradient ?
                        // <div className="absolute top-0 bottom-0 left-0 w-10 -my-px h-[calc(100%+2px)] bg-gradient-to-l from-transparent to-mediumGrey/[.47] pointer-events-none" />
                        <div className="absolute top-0 bottom-0 left-0 w-10 -my-px h-[calc(100%+2px)] bg-gradient-to-l from-transparent to-white pointer-events-none" />
                    :null
                }
                <button
                    onClick={() => { api?.scrollPrev(); setCanScrollPrev(api?.canScrollPrev()!) }}
                    disabled={canScrollPrev === false}
                    type="button"
                    className="pointer-events-auto absolute top-1/2 -translate-y-1/2 -left-4 rounded-full w-6 h-6 sm:w-8 sm:h-8 hidden sm:flex justify-center items-center border-2 border-teal hover:border-green text-teal hover:text-white bg-white hover:bg-green disabled:hidden">
                    <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5 rounded-full" />
                </button>
                {
                    canScrollNext && !hideBtnGradient ?
                        // <div className="absolute top-0 bottom-0 right-0 w-10 -my-px h-[calc(100%+2px)] bg-gradient-to-r from-transparent to-mediumGrey/[.47] pointer-events-none" />
                        <div className="absolute top-0 bottom-0 right-0 w-10 -my-px h-[calc(100%+2px)] bg-gradient-to-r from-transparent to-white pointer-events-none" />
                    :null
                }
                <button
                    onClick={() => { api?.scrollNext(); setCanScrollNext(api?.canScrollNext()!) }}
                    disabled={canScrollNext === false}
                    type="button"
                    className="pointer-events-auto absolute top-1/2 -translate-y-1/2 -right-4 rounded-full w-6 h-6 sm:w-8 sm:h-8 hidden sm:flex justify-center items-center border-2 border-teal hover:border-green text-teal hover:text-white bg-white hover:bg-green disabled:hidden">
                    <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 rounded-full" />
                </button>
            </div>
        </Carousel>
    )
}

export default ButtonCarousel
