import { ReactNode } from "react";
import { cn } from "src/lib/utils";

const TextWithContent = ({
    text,
    content,
    className,
}: {
    text: string;
    content: ReactNode;
    className?: string;
}) => {
    return (
        <div className={cn("w-full flex flex-col gap-3", className)}>
            <h3 className="text-darkTeal font-semibold text-[15px] sm:text-[17px] xl:text-md">{text}</h3>
            <div className="text-xs sm:text-[13px] xl:text-sm text-black prose max-w-full">
                {content}
            </div>
        </div>
    )
}

export default TextWithContent
