import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { LoginCredential, LoginResponse, LoginError } from "src/interfaces/login";
import fetcher from "src/utils/fetcher";
import { toast } from "./use-toast";
 
function useLogin() {
  const navigate = useNavigate();
 
  const mutation = useMutation<any, LoginError,  Partial<LoginCredential>>({
    mutationFn: (loginData) => {
        const result = fetcher({
          url: "/User/Login",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(loginData),
        });
        console.log("Fetcher result:", result);
        return result;
    },
    onError: (error) => {
      console.log("Error in login", error);
      toast({
        title: "Error!",
        variant: "destructive",
        description: "Failed to login! \nPlease try again",
      });
    },
    onSuccess: (response) => {
      console.log("Success in login", response);
      if (response?.token) {
        localStorage.setItem("authToken", response.token);
        toast({
          title: "Success!",
          variant: "success",
          description: "Login successfully completed",
        });
      navigate("/"); 
      } else {
        toast({
          title: "Login failed!",
          variant: "destructive",
          description: "No token received.",
        });
      }
    },
  });
  return {
    ...mutation,
  };
}
 
export default useLogin;