import * as React from "react";
const SvgGlobe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.51 0C14.753 0 19 4.294 19 9.585s-4.246 9.585-9.49 9.585C4.256 19.17 0 14.876 0 9.585 0 4.295 4.256 0 9.51 0M2.925 5.751h2.803a15 15 0 0 1 1.31-3.412A7.64 7.64 0 0 0 2.927 5.75M9.5 1.955a13.6 13.6 0 0 0-1.814 3.796h3.629A13.6 13.6 0 0 0 9.5 1.955m7.353 9.547a8 8 0 0 0 .247-1.917c0-.661-.095-1.303-.247-1.917h-3.211c.076.633.133 1.265.133 1.917s-.057 1.284-.133 1.917zm-.779 1.917h-2.803a15 15 0 0 1-1.31 3.412 7.6 7.6 0 0 0 4.113-3.412m-2.803-7.668h2.803A7.6 7.6 0 0 0 11.96 2.34a15 15 0 0 1 1.311 3.412M9.5 17.215a13.6 13.6 0 0 0 1.815-3.796h-3.63A13.6 13.6 0 0 0 9.5 17.215m-2.223-5.713h4.446c.085-.633.152-1.265.152-1.917s-.066-1.294-.152-1.917H7.277a14 14 0 0 0-.152 1.917c0 .652.066 1.284.152 1.917m-.237 5.33a15 15 0 0 1-1.311-3.413H2.926a7.64 7.64 0 0 0 4.114 3.412m-1.682-5.33a16 16 0 0 1-.133-1.917c0-.652.057-1.284.133-1.917H2.147A8 8 0 0 0 1.9 9.585c0 .661.095 1.304.247 1.917z"
    />
  </svg>
);
export default SvgGlobe;
