import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "src/components/ui/carousel"
import { cn } from "src/lib/utils";
import { LazyLoadImage } from "./components/LazyLoadImage";
import { EmblaCarouselType } from 'embla-carousel'

const ImageCarousel = ({
    images
}: {
        images: { src: string; title: string; subtitle?: string; }[];
}) => {
    const [carouselApi, setCarouselApi] = useState<CarouselApi>()
    const [slidesInView, setSlidesInView] = useState<number[]>([])
    const [current, setCurrent] = useState(0)
    const [count, setCount] = useState(0)

    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off('slidesInView', updateSlidesInView)
            }
            const inView = emblaApi
                .slidesInView()
                .filter((index) => !slidesInView.includes(index))
            return slidesInView.concat(inView)
        })
    }, [])
    
    useEffect(() => {
        if (!carouselApi) return;
    
        setCount(carouselApi.scrollSnapList().length);
        setCurrent(carouselApi.selectedScrollSnap());
    
        carouselApi.on("select", () => {
            setCurrent(carouselApi.selectedScrollSnap())
        })

        updateSlidesInView(carouselApi)
        carouselApi.on('slidesInView', updateSlidesInView)
        carouselApi.on('reInit', updateSlidesInView)

    }, [carouselApi, updateSlidesInView])
    return (
        <Carousel setApi={setCarouselApi} className="relative rounded-[10px] sm:rounded-[20px] xl:rounded-[30px] overflow-hidden" opts={{
            skipSnaps: true,
            loop: true,
            duration: 10,
            slidesToScroll: 1,
        }}>
            <CarouselContent className={"carousel-content !ml-0 w-full max-h-[370px]"}>
                {
                    images.length ?
                        images.map((image, index) => (
                            <CarouselItem key={`image-${index}`} className="remove-px w-full relative overflow-hidden">
                                <LazyLoadImage
                                    title={image?.title}
                                    className="carousel-image h-[370px] w-full"
                                    index={index}
                                    imgSrc={image?.src}
                                    inView={slidesInView.indexOf(index) > -1}
                                />
                                {/* <img src={image?.src} alt={image?.title} className="w-full h-full object-cover overflow-hidden" /> */}
                                <div className="absolute bottom-0 inset-x-1 h-[150px] bg-gradient-to-b from-transparent to-black/[0.47] pointer-events-none rounded-b-[10px] sm:rounded-b-[20px] xl:rounded-b-[30px]" />
                            </CarouselItem>
                        ))
                    : null
                }
            </CarouselContent>
            <div className="absolute bottom-4 inset-x-0 px-10 w-full flex justify-between items-end">
                {/* <div className="hidden sm:block sm:w-20" /> */}
                <div className="w-full flex flex-col sm:items-center gap-0 sm:gap-3 mb-2">
                    <h1 className="text-[20px] sm:text-xl text-white font-bold font-main sm:max-w-[calc(100%-160px)] text-center">
                        {images[current].title}
                    </h1>
                    {   images[current]?.subtitle ?
                        <h4 className="text-[15px] sm:text-[17px] xl:text-md text-center text-white font-semibold">
                            {
                                images[current].subtitle ||  images[count - 1].subtitle
                            }
                        </h4>
                        : null
                    }
                </div>
                
                <div className="absolute bottom-2 end-4 sm:end-6 xl:end-8 sm:w-20 flex justify-end">
                    <span className="text-[10px] sm:text-[17px] xl:text-md text-black font-normal px-3 sm:px-3.5 xl:px-4 py-2 bg-white/80 leading-none rounded-[7px]">
                        {current + 1}/{count}
                    </span>
                </div>
            </div>
            <div className="absolute top-1/2 inset-x-4 px-5 -translate-y-1/2">
                <button
                    onClick={() => carouselApi?.scrollPrev()}
                    disabled={carouselApi?.canScrollPrev() === false}
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 left-0 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                    <ChevronLeft className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                </button>
                <button
                    onClick={() => carouselApi?.scrollNext()}
                    disabled={carouselApi?.canScrollNext() === false}
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 right-0 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                    <ChevronRight className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                </button>
            </div>
        </Carousel>
    )
}

export default ImageCarousel
