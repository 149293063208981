import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Calendar from "../common/Calendar";
import { FormControl, FormField, FormItem } from "../ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Calendar as CalendarIcon, Minus, Plus } from "lucide-react";
import { cn } from "src/lib/utils";
import FormError from "../common/FormError";
import useGeneralSettings from "src/hooks/use-general-settings";

const TravelDateField = ({
    schema,
    form,
}: {
    schema: any
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>
}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    // For Update button
    const [selectedDateFormatted, setSelectedDateFormatted] = useState<string | null>(null);
    const [daysOffset, setDaysOffset] = useState<number>(0); // Default +/- 2 days
    const selectItemRef = useRef<HTMLDivElement>(null);

    // Get General settings
    const { data: generalSettings } = useGeneralSettings(); 

    // Increment the days offset
    const incrementOffset = () => {
        setDaysOffset(daysOffset + 1);
        formatSelectedDate()
    };

    // Decrement the days offset
    const decrementOffset = () => {
        if (daysOffset > 0) {
        setDaysOffset(daysOffset - 1);
        }
        formatSelectedDate()
    };

    // Format the selected date for display
    const formatSelectedDate = useCallback(() => {
        if (!selectedDate) return "Travel Date";
        const options: any = { weekday: "short", day: "numeric", month: "short" };
        return `${selectedDate.toLocaleDateString(
        "en-US",
        options
        )}${daysOffset > 0 ? `, +/- ${daysOffset} days` :''}`;
    }, [selectedDate, daysOffset]);

    useEffect(() => {
        if (selectedDateFormatted) {
            form.setValue('travel_date', selectedDate ? formatSelectedDate() : '')
            // Trigger validation
            if(form.control.getFieldState('travel_date').error)
                form.trigger('travel_date')
        }
    }, [selectedDate, selectedDateFormatted, daysOffset, form, formatSelectedDate])
  

    return (
        <FormField
                control={form.control}
                name="travel_date"
                render={({ field }) => (
                    <FormItem>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                                <SelectTrigger className={cn({'border-red-500': form.getFieldState('travel_date').invalid})}>
                                    <div className="flex justify-start items-center gap-2">
                                        <CalendarIcon className="w-6 stroke-[1.4px]" />
                                        <div className="text-xs sm:text-[13px] xl:text-sm selected-value-remove-icon">
                                            {/* For update button */}
                                            {selectedDateFormatted || "Travel Date"}
                                        </div>
                                    </div>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent className="max-w-[calc(100%-1rem)] sm:max-w-full w-full rounded-[16px] max-h-[450px] mx-auto p-2 sm:p-4">
                                {/* For Closing Content on update button click */}
                                <SelectItem ref={selectItemRef} value={selectedDateFormatted ? selectedDateFormatted: '-'} className="hidden"></SelectItem>
                                {/* Content */}
                                {/* Calendar */}
                                <div className="max-w-[calc(100%-1rem)] sm:max-w-full w-full px-2 sm:px-4">
                                    <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                                </div>
                                {/* Footer */}
                                <div className="max-w-[calc(100%-1rem)] sm:max-w-full border-t border-gray-400 p-4 flex flex-col gap-2">
                                    <Label className="text-xs sm:text-[13px] xl:text-sm font-semibold">It can also be</Label>
                                    <div className="flex flex-wrap justify-between items-end gap-4">
                                        {/* Counter */}
                                        <div className="pl-2 flex items-center gap-5">
                                            <span className="text-sm sm:text-[15px] xl:text-base text-nowrap text-black font-semibold">+/- days</span>
                                            <div className="flex items-center gap-2">
                                                <button
                                                    onClick={decrementOffset}
                                                    disabled={+daysOffset === 0}
                                                    className="counter-button w-6 h-6 rounded-full flex justify-center items-center">
                                                    <Minus className="w-4 h-4" />
                                                </button>
                                                <Input onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    if (+e.target.value >= 0) {
                                                        setDaysOffset(+e.target.value)
                                                    }
                                                }} type="text" readOnly value={daysOffset} className="text-xs sm:text-[13px] xl:text-sm rounded-[4px] text-center w-9 max-w-fit min-w-0 pointer-events-none select-none" />
                                                <button
                                                    onClick={incrementOffset}
                                                    disabled={generalSettings?.data.settingsMaxSearchDays && generalSettings?.data.settingsMaxSearchDays > 1 ? false : true}
                                                    className="counter-button w-6 h-6 rounded-full flex justify-center items-center">
                                                    <Plus className="w-4 h-4" />
                                                </button>
                                            </div>
                                        </div>
                                        {/* Update Button */}
                                        
                                            <Button
                                                variant={'default'}
                                                className="submenu-button ms-auto font-semibold"
                                                onClick={() => {
                                                    setSelectedDateFormatted(formatSelectedDate());
                                                    if (selectedDate) {
                                                        selectItemRef.current?.click();
                                                    }
                                                }}> 
                                                Update
                                            </Button>
                                    </div>
                                </div>
                            </SelectContent>
                        </Select>
                        {/* Errors */}
                        <FormError />
                    </FormItem>
                )}
            />
    );
};

export default TravelDateField;
