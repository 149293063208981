import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import { buttonVariants } from "src/components/ui/button";

const EmailButton = ({
    to,
}: {
    to: string;
}) => {
    return (
        <Link to={to} aria-label="Continue With Email" className={buttonVariants({
            variant: "default",
            className: "border border-white rounded-full h-12 w-[412px] xl:h-14 text-white font-medium text-sm sm:text-base !bg-black/40 hover:!bg-green transition-all"
        })}>
            Continue With Email
        </Link>
    );
};

export default EmailButton;
