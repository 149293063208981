import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";
import SearchResults from "../pages/SearchResults";
import TourDetails from "../pages/TourDetails";
import Home from "../pages/Home";
import ForgotPassword from "src/pages/Auth/ForgotPassword";
import Layout from "../layouts/Layout";
import Register from "../pages/Auth/Register";
import RegisterCredentials from "../pages/Auth/Register/Credentials";
import Login from "../pages/Auth/Login";
import LoginCredentials from "../pages/Auth/Login/Credentials";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Routes with Layout */}
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/tour-packages/:id" element={<TourDetails />} />
        <Route path="/search" element={<SearchResults />} />

        <Route path="/login" element={<Login />} />
        <Route path="/login/credentials" element={<LoginCredentials />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/credentials" element={<RegisterCredentials />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Catch-all for Not Found */}
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* Routes without Layout */}
    </Routes>
  );
};

export default AppRoutes;
