import { useMutation } from "@tanstack/react-query";
import fetcher from "src/utils/fetcher";
import { toast } from "./use-toast";
import { RegisterResponse , RegisterError , RegisterReqest } from "src/interfaces/registerCredential";

const useRegister = () => {
    const mutation = useMutation<RegisterResponse, RegisterError,Partial<RegisterReqest>>({
        mutationFn: (credentials) =>
            fetcher({
            url: "/User/Register",
            method: "POST",
            data: credentials, // Dynamically pass payload
            }),
        onSuccess: (data) => {
            toast({
                title: "Success!",
                description: data.message || "User registered successfully",
                variant: "success",
            });
            console.log("Registration successful:", data);
        },
        onError: (error: RegisterError) => {
            // Enhanced error handling with specific error object
            toast({
                title: "Error!",
                variant: "destructive",
                description: error.message || "An unexpected error occurred",
            });
            console.error("Registration failed:", error);
        },
    });

    return {
        mutate: mutation.mutate,
        data: mutation.data,
        isError: mutation.isError,
        isSuccess: mutation.isSuccess,
        isPending: mutation?.status
    };
};

export default useRegister;
