import { Star } from "lucide-react";
import { cn } from "src/lib/utils";

const RatingDetails = ({
    rating,
    reviewsCount,
    className
}: {
    rating: number;
    reviewsCount: number;
    className?: string;
}) => {
    return (
        <div className={cn("text-teal text-xs sm:text-[13px] xl:text-sm flex justify-start items-center gap-2", className)}>
            <div className="w-max flex justify-start items-center gap-1">
                <Star className="w-4 -mt-1 fill-current" />
                <span className="font-semibold">
                    {rating} / 5.0 
                </span>
            </div>
            <span className="italic">{reviewsCount} reviews</span>
        </div>
    )
}

export default RatingDetails
