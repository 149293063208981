import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "src/components/ui/form";
import { forgotPasswordSchema } from "src/schemas/auth";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import CheckPopup from "./CheckInboxDialog";
import { Input } from "src/components/ui/input";
import { cn } from "src/lib/utils";
import Spinner from "src/components/common/Spinner";
import FormError from "src/components/common/FormError";
import useForgotPassword from "src/hooks/use-forgot-password";

const ForgotPasswordForm = () => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const { mutate, isError, error, isPending } = useForgotPassword();

    useEffect(() => {
        console.log({ isPending, isError, error });
      }, [isPending, isError, error]);

    const form = useForm<z.infer<typeof forgotPasswordSchema>>({
        resolver: zodResolver(forgotPasswordSchema),
    });
    function onSubmit(data: z.infer<typeof forgotPasswordSchema>) {
        setIsSuccess(false);
        console.log(data);
        // if (isPending) {
            setIsSubmitting(true);
            mutate(data,{
                onSettled: () => {
                // setIsSuccess(false);
                setIsSubmitting(false);
                form.reset();
            }
        });
        // }
    }
    useEffect(() => {
        if (isSubmitting) {
            if (
                !form.formState.errors.email
                &&
                form.formState.submitCount
            ) {
                setIsSubmitting(false);
                setIsSuccess(true)
            }
        }
    }, [form.formState, isSubmitting]);

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col justify-center items-center xl:items-start gap-8 w-full max-w-[480px] mx-auto xl:ms-0 pb-8"
            >
                <div className="form-group relative w-full">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        {...field}
                                        className={cn("bg-black/[.35] !placeholder-white border border-white text-white font-main text-sm font-normal h-14 p-6",
                                            { 'border-red-500': form.getFieldState('email').invalid }
                                        )}
                                    />
                                </FormControl>
                                {/* Errors */}
                                <FormError />
                            </FormItem>
                        )} />
                </div>
 
                <Button
                    type="submit"
                    variant={"default"}
                    disabled={isSubmitting}
                    className="primary-button w-full h-14 text-sm sm:text-[15px] xl:text-base font-main font-medium py-4 flex justify-center items-center gap-1.5"
                >
                    {
                        isSubmitting ?  <Spinner /> : null
                    }
                    Confirm Email
                </Button>
                   
                {/* Show Dialog on success */}
                {
                    isSuccess ? <CheckPopup />: null
                }
            </form>
        </Form>
    )
}
 
export default ForgotPasswordForm
