import * as React from "react";
const SvgLogoApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 35"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.544 5.431a7.5 7.5 0 0 0 1.418-2.535A7.6 7.6 0 0 0 20.318 0a7.39 7.39 0 0 0-5.02 2.588 7.1 7.1 0 0 0-1.36 2.458 7.2 7.2 0 0 0-.312 2.803 6.1 6.1 0 0 0 2.736-.625 6.2 6.2 0 0 0 2.183-1.793M22.759 18.1a7.8 7.8 0 0 1 .982-3.72 7.6 7.6 0 0 1 2.632-2.767 7.85 7.85 0 0 0-2.666-2.439 7.7 7.7 0 0 0-3.457-.967c-2.61-.272-5.02 1.55-6.408 1.55s-3.346-1.515-5.52-1.481A8.1 8.1 0 0 0 4.3 9.499a8.3 8.3 0 0 0-2.905 3.083C-1.55 17.792.642 25.54 3.587 29.744c1.338 2.06 3.011 4.393 5.22 4.308s2.927-1.396 5.487-1.396S17.64 34.052 19.815 34s3.714-2.111 5.12-4.172a18.9 18.9 0 0 0 2.308-4.852 7.4 7.4 0 0 1-3.254-2.756 7.6 7.6 0 0 1-1.23-4.122"
    />
  </svg>
);
export default SvgLogoApple;
