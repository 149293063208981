// Desc: Included and Excluded content of accordion in TourDetails page
import Item from "src/components/common/text/IncludedExcluded";

const IncludedExcluded = () => {
    return (
        <>
            <div className="pb-5">
                <h4 className="w-full py-4 text-darkTeal font-semibold text-[15px] sm:text-[17px] xl:text-md">Included</h4>
                <div className="ps-4 space-y-4">
                    <Item
                        text="Lorem ipsum dolor sit amet consectetur."
                        isIncluded
                    />
                    <Item
                        text="Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet."
                        isIncluded
                    />
                    <Item
                        text="Ullamcorper odio nunc tristique mauris tempor nulla."
                        isIncluded
                    />
                </div>
            </div>
            <div className="">
                <h4 className="w-full py-4 text-darkTeal font-semibold text-[15px] sm:text-[17px] xl:text-md">Excluded</h4>
                <div className="ps-4 space-y-4">
                    <Item
                        text="Lorem ipsum dolor sit amet consectetur."
                    />
                    <Item
                        text="Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet."
                    />
                    <Item
                        text="Ullamcorper odio nunc tristique mauris tempor nulla."
                    />
                </div>
            </div>
        </>
    )
}

export default IncludedExcluded
