import { Select, SelectContent, SelectItem, SelectValue, SelectTrigger } from "src/components/ui/select"
import { Label } from "../ui/label"

const SortBy = ({
    options,
    onChange
}: {
    options: string[]
    onChange: (value: string) => void
}) => {
    return (
        <div className="w-max flex items-center gap-3">
            <Label className="hidden sm:block font-semibold text-darkBlue text-nowrap">Sort by:</Label>
            <Select defaultValue={options[0]} onValueChange={(value) => onChange(value)}>
                <SelectTrigger className="ttext-xs sm:text-[13px] xl:text-sm h-auto py-3 px-4 bg-background text-darkBlue border-[.2px] border-darkBlue w-44">
                    <SelectValue placeholder="Sort by" className="" />
                </SelectTrigger>
                <SelectContent className="rounded-[6px] [&>div]:divide-y [&>div]:divide-gray-200 [&>div]:rounded-[6px] [&>div]:overflow-hidden [&>div]:p-0 ">
                    {
                        options.map(option => (
                            <SelectItem key={option} value={option} className="rounded-none py-4 hover:!bg-lightBlue text-xs sm:text-[13px] xl:text-sm">{option}</SelectItem>
                        ))
                    }
                </SelectContent>
            </Select>
        </div>
    )
}

export default SortBy
