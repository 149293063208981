import Container from "src/components/common/Container";
import { Button } from "src/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Svg404 } from "src/components/icons";
import illustration from 'src/assets/images/illustrations/download_app.jpg'
import illustrationSmall from 'src/assets/images/illustrations/download_app_small.jpg'
import { useMediaQueries } from 'src/hooks/use-media-query'

const NotFound = () => {
    const { xl } = useMediaQueries()
    const navigate = useNavigate()

    return (
        <section className='relative w-full bg-lightGreen xl:h-[560px] bg-cover sm:bg-contain overflow-hidden py-16 sm:py-20 xl:py-10'>
            <div className='relative xl:absolute w-full h-[271px] sm:h-[368px] xl:h-[85%] bg-contain overflow-hidden' style={{
                    backgroundImage: xl ? `url(${illustration})` : `url(${illustrationSmall})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }} />
            <Container className='mt-8 xl:mt-0 h-full flex items-center'>
                <div className='h-full w-full bg-cover sm:bg-contain'>
                    <div className="h-full w-full flex flex-col justify-center items-center xl:items-start gap-2 sm:gap-3 xl:gap-7">
                        <h2 className='text-2xl sm:text-3xl xl:text-4xl text-green font-heading font-bold text-center xl:text-start'>We are sorry</h2>
                        <p className='text-md sm:text-[20px] xl:text-lg text-black font-bold text-center xl:text-start'>The requested page not found!</p>
                        <Button
                            onClick={() => navigate(-1)}
                            variant={'default'}
                            className="mt-5 mb-10 group w-fit max-w-full !px-5 !py-3 h-auto font-semibold text-xs sm:text-[13px] xl:text-sm font-main flex justify-center text-center items-center !gap-1.5"
                        >
                            <ArrowLeft className="!w-[22px] !h-[22px] group-hover:-translate-x-1 transition-all" />
                            Go Back
                        </Button>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default NotFound;
