import { ReactNode } from "react"
import { ControllerRenderProps, UseFormReturn } from "react-hook-form"
import { Card, CardContent, CardHeader } from "src/components/ui/card"
import { Checkbox } from "src/components/ui/checkbox"
import { FormControl, FormField, FormItem, FormLabel } from "src/components/ui/form"
import { FilterCheckboxCardOption } from "src/interfaces/filterCheckboxCard"
import { Schema, z } from "zod"

const FilterCheckboxCard = ({
    schema,
    form,
    fieldName,
    options,
    label,
    children
}: {
    schema: Schema;
    form: UseFormReturn<z.infer<typeof schema>, any, undefined>;
    fieldName: string;
    options: FilterCheckboxCardOption[];
    label: string;  
    children?: ReactNode    
}) => {
    const handleCheckChange = (checked: string | boolean, field: ControllerRenderProps<any, string>, option: FilterCheckboxCardOption) => {
        return checked ?
                field.onChange(
                    field.value?.length ?
                        [...field.value, option] : [option]
                )
                :
                field.onChange(
                    field.value?.length ?
                        field.value?.filter((value: string) => value !== option.value) : []
                )
    }    
    
    return (
        <Card className="w-full overflow-hidden rounded-[5px] border-[.2px] border-teal">
            <CardHeader className="py-4 px-3 bg-lightTeal border-b-[.2px] border-teal">
                <label>
                    {label}
                </label>
            </CardHeader>
            <CardContent className="my-4 space-y-4">
                {options.map((option) => (
                    <FormField
                    key={option.value}
                    control={form.control}
                    name={fieldName}
                    render={({ field }) => {
                        return (
                        <FormItem
                            className="flex flex-row items-start space-x-3 space-y-0"
                        >
                            <FormControl>
                            <Checkbox
                                checked={field.value && field.value.find((value: string) => option.value === value)}
                                onCheckedChange={(checked) => handleCheckChange(checked, field, option)}
                            />
                            </FormControl>
                            <FormLabel className="font-normal text-xs sm:text-[13px] xl:text-sm text-black">
                                {option.label}
                            </FormLabel>
                        </FormItem>
                        )
                    }}
                    />
                ))}
                {children}
            </CardContent>
        </Card>
    )
}

export default FilterCheckboxCard
