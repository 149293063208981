import { ItineraryTab, ItineraryTabContent } from "src/interfaces/tabs";
import tourDetails from "src/assets/images/ui/tour-details.jpg";
import trending1 from "src/assets/images/ui/trending-1.jpg";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import ButtonCarousel from "src/components/common/carousels/ButtonCarousel";
import { CarouselItem } from "src/components/ui/carousel";
import { useState } from "react";
import ItineraryCard from "src/components/cards/itineraryCard/ItineraryCard";

const TabsContentComponent = ({
    content,
}: {
    content: ItineraryTabContent[];
}) => {

    return (
        <div className="flex flex-col gap-4 mt-5 py-5">
            {
                content.length ? content.map((item, index) => (
                    <ItineraryCard
                        key={item.title}
                        title={item.title}
                        imageSrc={item.coverImage}
                        duration={item.duration}
                        optionalText={item.optionalText || ''}
                        listItems={item.listItems} />
                )) : null
            }
        </div>
    )
}

const Itinerary = () => {
    const itineraryTabs: ItineraryTab[] = [
        {
            label: "Mon 25 Nov",
            value: "25nov",
            sublabel: "Day 1, Cairo - Alexandria",
            content: [
                {
                    coverImage: trending1,
                    title: "Check in - Hotel Name Lorem Ipsum",
                    duration: "2 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                    
                },
                {
                    coverImage: tourDetails,
                    title: "Activity in Location Lorem Ipsum",
                    duration: "6 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                },
                {
                    coverImage: tourDetails,
                    title: "Activity in Location",
                    duration: "6 hours",
                    optionalText: "Optional for 30$ per person extra fees",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                }
            ]
        },
        {
            label: "Tue 26 Nov",
            value: "26nov",
            sublabel: "Day 1, Cairo - Alexandria",
            content: [
                {
                    coverImage: tourDetails,
                    title: "Type of itinerary ex: air conditioned van.",
                    duration: "6 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                    
                },
                {
                    coverImage: trending1,
                    title: "Type of itinerary 1",
                    duration: "6 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                    
                },
            ]
        },
        {
            label: "Wed 27 Nov",
            value: "27nv",
            sublabel: "Day 3, Alexandria",
            content: [
                {
                    coverImage: tourDetails,
                    title: "Type of itinerary ex: air conditioned van.",
                    duration: "6 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                    
                },
            ]
        },
        {
            label: "Wed 28 Nov",
            value: "28nv",
            sublabel: "Day 4, Alexandria - Luxor",
            content: [
                {
                    coverImage: trending1,
                    title: "Type of itinerary ex: air conditioned van.",
                    duration: "6 hours",
                    listItems: [
                        "Check in 3 stars hotel lorem ipsum amet consectetur.",
                        "Faucibus porttitor dignissim ullamcorper neque tristique quam laoreet.",
                        "Ullamcorper odio nunc tristique mauris tempor nulla.",
                    ]
                    
                },
            ]
        },
    ]

    const [tabs, setTabs] = useState<ItineraryTab[]>(itineraryTabs)
    
    return (
            itineraryTabs.length ? 
                <Tabs onValueChange={() => setTabs(itineraryTabs)} defaultValue={tabs[0].value} className="w-full !rounded-none">
                    <TabsList className="w-full h-auto bg-transparent !rounded-none">
                        <ButtonCarousel>
                            {
                                tabs.map((tab) => (
                                    <CarouselItem key={tab.value} className="basis-auto relative overflow-hidden">
                                        <TabsTrigger key={tab.value} value={tab.value} className="flex flex-col py-3 px-8 rounded-[3px] justify-center items-center gap-1 text-darkBlue text-[13px] sm:text-sm xl:text-[15px] bg-white border-[0.7px] border-teal data-[state=active]:bg-green data-[state=active]:text-white data-[state=active]:border-green">
                                            <span className="text-inherit font-semibold text-[13px] sm:text-sm xl:text-[15px]">{tab.label}</span>
                                            <span className="text-inherit font-medium text-xs">{tab.sublabel}</span>
                                        </TabsTrigger>
                                    </CarouselItem>
                                ))
                            }
                        </ButtonCarousel>
                    </TabsList>
                    {
                        tabs.map((tab, index) => (
                            <TabsContent key={`tab-content-${index}`} value={tab.value}>
                                {
                                    TabsContentComponent({ content: tab.content})
                                }
                            </TabsContent>
                        ))
                    }
                </Tabs>
            : null
    );
};

export default Itinerary;
