// import "../../../styles/tourDetails/tourContent.css";
import ViewGallery from "./ViewGallery";
import Accordion from "./Accordion";
const TourDetailsContent = () => {
    return (
      <div className="mb-10">
        <h1 className="font-main text-3xl mb-8 text-darkBlue">
            Movenpick Royal Lily & Lotus Nile Cruises
        </h1>
        {/* Gallery  */}
        <ViewGallery />
        {/* Accordion */}
        <Accordion/>
      </div>
    );
};

export default TourDetailsContent;
