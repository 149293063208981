import { Link } from "react-router-dom";
import SvgLocationPin from "../../icons/LocationPin";
import SvgActivities from "../../icons/Activities";
import { buttonVariants } from "../../ui/button";
import ShareButton from "../../common/buttons/ShareButton";
import FavouriteButton from "../../common/buttons/FavouriteButton";
import HotDealBadge from "../../common/badges/HotDeal";
import DiscountBadge from "../../common/badges/DiscountBadge";
import LimitedPlacesBadge from "../../common/badges/LimitedPlaces";
import RatingDetails from "../../common/rating/RatingDetails";
import StayDetails from "../../common/StayDetails";
import Price from "../../common/Price";
import { Card } from "../../ui/card";
import { TourCardData } from "src/interfaces/tourCard";

const TourCard = ({
    data
}: {
    data: TourCardData
}) => {
    const {
        id,
        isHotDeal,
        isOnSale,
        salePercent,
        isLimitedPlaces,
        placesLeft,
        imageSrc,
        title,
        avgRating,
        reviewsCount,
        nightsCount,
        daysCount,
        currency,
        salePrice,
        price,
        locations,
        activities,
    } = data;
    return (
        <Card className="max-w-[90vw] sm:max-w-[500px] mx-auto p-2 rounded-[12px] border-[0.5px] border-teal bg-cardsBG max-h-fit xl:hover:shadow-cards transition-all ease-out duration-200">
            <Link
                to={`/tour-packages/${id}`}
                className={'cursor-pointer'}>    
                <div className="relative h-[261px] sm:h-72 mb-2 rounded-[8px] overflow-hidden">
                    <img src={imageSrc} alt="cardImage" className="w-full h-full object-cover" />
                    <div className="absolute top-5 right-5 flex items-center gap-3">
                        <ShareButton />
                        <FavouriteButton />
                    </div>
                    <div className="absolute bottom-5 left-0 flex flex-col gap-2">
                        {
                            isHotDeal ? <HotDealBadge /> : null
                        }
                        {
                            isOnSale ? <DiscountBadge percent={salePercent} /> : null
                        }
                        {
                            isLimitedPlaces ? <LimitedPlacesBadge count={placesLeft} /> : null
                        }
                    </div>
                </div>
            </Link>
            <div className="p-2 sm:p-3 xl:p-4">
                <div className="flex flex-col gap-3">
                    <Link
                        to={`/tour-packages/${id}`}
                        className={'cursor-pointer'}>
                        <h3 className="text-darkBlue">{title}</h3>
                    </Link>
                    <RatingDetails rating={avgRating} reviewsCount={reviewsCount} />
                    <div className="flex flex-col gap-2 mb-1">
                        <StayDetails nights={nightsCount} days={daysCount} />
                        <Price currency={currency} sale={salePrice} price={price} />
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex items-start gap-2">
                            <SvgLocationPin className="text-teal w-4 mt-1" />
                            <p className="text-black text-xs sm:text-[13px] xl:text-sm">
                                {locations}
                            </p>
                        </div>
                        <div className="flex items-start gap-2">
                            <SvgActivities className="text-teal w-4 mt-1" />
                            <p className="text-black text-xs sm:text-[13px] xl:text-sm">
                                {activities}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-4 w-full flex justify-end">
                    <Link
                        to={`/tour-packages/${id}`}
                        className={
                            buttonVariants({
                                variant: 'link',
                                className: 'text-teal hover:text-green active:text-green text-sm xl:text-base !p-0 font-semibold underline xl:no-underline'
                            })
                        }>
                        View Details
                    </Link>
                </div>
            </div>
        </Card>
    );
};

export default TourCard;
