import { ArrowLeft } from "lucide-react";
import { ReactNode } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "src/components/ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import { ScrollArea } from "src/components/ui/scroll-area";
import ImageGalleryCarousel from "../carousels/ImageGalleryCarousel";

function DetailsDialog({
    title,
    trigger,
    images,
}: {
    title: string;
    trigger: ReactNode;
    images: string[];
}) {
    return (
        <Dialog>
            <DialogTrigger asChild>{trigger}</DialogTrigger>
            <DialogContent className="max-w-[1400px] bg-transparent !rounded-none border-none gap-0 p-0">
                <DialogTitle className="sr-only">{title}</DialogTitle>
                <ScrollArea className="max-h-screen">
                    <div className="bg-darkTeal py-4 px-5 lg:py-5 lg:px-8">
                        <DialogClose asChild>
                            <button className="text-white no-underline flex items-center gap-1.5 font-semibold text-sm">
                                <ArrowLeft className="w-[22px] text-white" /> Back
                            </button>
                        </DialogClose>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 py-4 px-5 lg:py-10 lg:px-8 gap-4 xl:gap-8 bg-white">
                        {/* Gallery */}
                        <div className="w-full h-full">
                            <ImageGalleryCarousel images={images} />
                        </div>
                        
                        {/* Text */}
                        <div className="flex flex-col gap-10 pt-10 pb-5 w-full xl:w-[31.5rem] h-auto">
                            <div className="flex flex-col gap-2.5">
                                <h3 className="text-darkTeal font-main text-md sm:text-[19px] xl:text-lg font-bold">
                                    Details about Hotel or Type of Transportation or Location in
                                    Activities
                                </h3>
                                <p className="text-black text-xs sm:text-[13px] xl:text-sm leading-relaxed font-regular font-main xl:ms-5">
                                    Lorem ipsum dolor sit amet consectetur. Faucibus porttitor
                                    dignissim ullamcorper neque tristique quam laoreet. Ullamcorper
                                    odio nunc tristique mauris tempor nulla. Ac pulvinar interdum
                                    aliquet aliquet interdum dui. Tristique interdum lectus viverra
                                    consectetur sem eu elit. Et libero iaculis ultricies parturient
                                    sed. Id ornare purus tincidunt magna sem bibendum. Orci sapien
                                    quam nisl aliquet. Etiam consectetur morbi amet scelerisque
                                    pulvinar. Etiam lacinia porta non tempus nam tellus sagittis.
                                </p>
                            </div>

                            <div className="flex flex-col gap-2.5">
                                <h3 className="text-darkTeal font-main text-md sm:text-[19px] xl:text-lg font-bold">
                                    Details about Hotel or Location
                                </h3>
                                <p className="text-black text-xs sm:text-[13px] xl:text-sm leading-relaxed font-regular font-main xl:ms-5">
                                    Lorem ipsum dolor sit amet consectetur. Faucibus porttitor
                                    dignissim ullamcorper neque tristique quam laoreet. Ullamcorper
                                    odio nunc tristique mauris tempor nulla. Ac pulvinar interdum
                                    aliquet aliquet interdum dui. Tristique interdum lectus viverra
                                    consectetur sem eu Et libero iaculis ultricies parturient sed.
                                    Id ornare purus tincidunt magna sem bibendum.
                                </p>
                            </div>
                        </div>
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
}

export default DetailsDialog;
