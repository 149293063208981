import { ArrowLeft } from "lucide-react"
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "src/components/ui/dialog"
import { ReactNode } from "react";
import ImageCarouselDialog from "./ImageCarouselDialog";
import { ScrollArea } from "src/components/ui/scroll-area";

const ImageToggler = ({
    images,
}: {
    images: string[];
    }) => (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-4 px-5 lg:py-5 lg:px-8 gap-4 bg-white">
        {
            images.length ? (
                images.map((image: string, index: number) => (
                    <div key={`Gallery ${index + 1}`} className="w-full h-full min-h-72 rounded-[20px] overflow-hidden cursor-pointer">
                        <img
                            src={image}
                            alt={`Gallery ${index + 1}`}
                            className="object-cover w-full h-full"
                        />
                    </div>
                ))
            ) : null
        }
    </div>
)


const GalleryDialog = ({
    title,
    trigger,
    backButtonText,
    images,
}: {
    title: string;
    trigger: ReactNode;
    backButtonText: string;
    images: string[];
}) => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                {trigger}
            </DialogTrigger>
            <DialogContent className="max-w-[1400px] bg-transparent !rounded-none border-none gap-0 p-0">
                <DialogTitle className="sr-only">Tour Package Gallery Dialog</DialogTitle>
                <ScrollArea className="max-h-screen">
                    <div className="bg-darkTeal py-4 px-5 lg:py-5 lg:px-8">
                        <DialogClose asChild>
                            <button className="text-white no-underline flex items-center gap-1.5 font-semibold text-sm">
                                <ArrowLeft className="w-[22px] text-white" /> {backButtonText}
                            </button>
                        </DialogClose>
                    </div>
                    <div className="w-full bg-white">
                        <ImageCarouselDialog title={title} toggler={ImageToggler({images})} images={images} />
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}

export default GalleryDialog
