import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { GeneralSettings } from "src/interfaces/settings"
import { GENERAL_SETTINGS_URL } from "src/utils/constants"
import fetcher from "src/utils/fetcher"
import { toast } from "./use-toast"

const useGeneralSettings = () => {
    // Fetch General settings
    const {
        data,
        isError,
        isSuccess,
        isPending
    } = useQuery<GeneralSettings>({
        queryKey: ['general', 'settings'],
        queryFn: () => {
            return fetcher({url: GENERAL_SETTINGS_URL, method: 'post'})
        },
    })
    useEffect(() => {
        if (isError) {
            toast({
                title: 'Error!',
                variant: 'destructive',
                description: 'Couldn\'t load general settings'
            })
        }
    }, [isError])

    useEffect(() => {
        if (data && isSuccess) {
            console.log(data)
        }
    }, [data, isSuccess])

    return {
        data,
        isError,
        isSuccess,
        isPending
    }
}


export default useGeneralSettings;