import AuthBg from "src/assets/images/auth/bg.jpg"; // Background image
import FacbookButton from "src/pages/Auth/components/FacbookButton";
import EmailButton from "src/pages/Auth/components/EmailButton";
import GmailButton from "src/pages/Auth/components/GmailButton";
import SocialIcons from "src/pages/Auth/components/SocialIcons";
import Container from "src/components/common/Container";
import AuthHeader from "../components/AuthHeader";
import AlreadyAMember from "../components/AlreadyAMember";
const Register = () => {

    return (
        <div className="w-full bg-cover bg-no-repeat bg-center pt-px pb-px" style={{
                backgroundImage: `url(${AuthBg})`
            }}>
            {/* Content Container */}
            <Container>
                {/* header */}
                <AuthHeader to="back" />
                <div className="mx-auto xl:ms-0 max-w-[480px]">
                    {/* heading  */}
                    <div aria-label="sign up" className="w-full flex justify-center xl:justify-start text-white font-bold text-[20px] sm:text-[22px] xl:text-2xl mb-8" >
                        Sign up to Tripvex
                    </div>
                    {/* bottons to sign Up */}
                    <div className="w-full flex flex-col items-center xl:items-start gap-y-4 xl:gap-y-6 mb-10">
                        <EmailButton  to={'/register/credentials'}/>
                        <GmailButton />
                        <FacbookButton />
                    </div>
                    {/* Social Icons */}
                    <div className="w-full flex justify-center xl:justify-start mb-6 sm:mb-9">
                        <SocialIcons/>
                    </div>
                </div>
                
                {/* Already a member */}
                <AlreadyAMember />
            </Container>
        </div>
    );
};

export default Register;
