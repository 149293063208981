import TourCardHorizontal from "src/components/cards/tourCard/TourCardHorizontal"
import { TourCardData } from "src/interfaces/tourCard"
import BudgetPerDayFilter from "./filter/BudgetPerDayFilter"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { filterFormSchema } from "src/schemas/filter"
import { Form, FormLabel } from "src/components/ui/form"
import PopularFiltersFilter from "./filter/PopularFiltersFilter"
import ActivitiesFilter from "./filter/ActivitiesFilter"
import RatingFilter from "./filter/RatingFilter"
import { useMemo, useState } from "react"
import LoadMoreResults from "./LoadMoreResults"
import { toast } from "src/hooks/use-toast"

const MainContent = ({
    results
}: {
    results: TourCardData[]
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1)  
    const totalCount = useMemo(() => 20, []) // 20 for now should be coming from search results response
    const pageCount = useMemo(() => results.length, [results])
    const form = useForm<z.infer<typeof filterFormSchema>>({
        resolver: zodResolver(filterFormSchema),
    })
    
    function onSubmit(data: z.infer<typeof filterFormSchema>) {
        console.log(data)
    }

    return (
        <main className="w-full flex flex-wrap xl:flex-nowrap justify-start gap-5 mt-2.5 mb-8 xl:my-10">
            <aside className="hidden xl:block max-w-80 min-w-72">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="sticky top-4 w-full">
                        <FormLabel className="font-semibold text-sm tetx-black block mb-3">Filter by</FormLabel>
                        <div className="space-y-5">
                            <BudgetPerDayFilter form={form} />
                            <RatingFilter form={form} />
                            <PopularFiltersFilter form={form} />
                            <ActivitiesFilter form={form} />
                        </div>
                    </form>
                </Form>
            </aside>
            <section className="w-full flex flex-col items-center gap-4">
                {
                    results.map(result => (
                        <TourCardHorizontal key={result.id} data={result} />
                    ))
                }
                <LoadMoreResults
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalCount={totalCount}
                    pageCount={pageCount} />
            </section>
        </main>
    )
}

export default MainContent
