import { CheckCircle, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";

export function CheckInboxDialog() {
    let navigate = useNavigate();

    return (
        <Dialog defaultOpen={true}>
            <DialogContent className="max-w-[26rem] !rounded-[26px] justify-center">
                <DialogHeader>
                    <DialogClose className="absolute top-4 end-4">
                        <X className="text-darkBlue w-5" />
                    </DialogClose>
                <div className="pt-5">
                    <DialogTitle className="flex flex-col gap-6 text-green justify-center items-center">
                        <CheckCircle className="!w-[90px] !h-[90px]" />
                        <h1 className="text-xl font-main font-bold">Check Your Inbox</h1>
                    </DialogTitle>
                    <DialogDescription className="text-black text-center text-sm leading-7 py-4 mt-4">
                        We sent you the reset link, please check your inbox and follow the
                        link to reset your password.
                    </DialogDescription>
                </div>
                </DialogHeader>
                <DialogFooter className="pb-5 flex justify-center items-center">
                    <Button variant={'default'} onClick={()=> navigate('/login')} className="secondary-button mx-auto py-3.5 h-auto px-[2.5rem] text-sm font-semibold">
                        Back to Sign in
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default CheckInboxDialog;
