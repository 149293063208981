import * as React from "react";
const SvgMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 22"
    {...props}
  >
    <path
      fill="currentColor"
      d="M30.311 8.969H1.69C.756 8.969 0 9.732 0 10.673v.18c0 .94.756 1.703 1.689 1.703H30.31c.933 0 1.689-.763 1.689-1.704v-.18c0-.94-.756-1.703-1.689-1.703M30.311 17.938H1.69C.756 17.938 0 18.7 0 19.642v.179c0 .941.756 1.704 1.689 1.704H30.31c.933 0 1.689-.763 1.689-1.704v-.18c0-.94-.756-1.703-1.689-1.703M30.311 0H1.69C.756 0 0 .763 0 1.704v.18c0 .94.756 1.703 1.689 1.703H30.31c.933 0 1.689-.763 1.689-1.704v-.179C32 .763 31.244 0 30.311 0"
    />
  </svg>
);
export default SvgMenu;
