import CredentialsForm from "./CredentialsForm";
import Container from "src/components/common/Container";
import AuthBg from "src/assets/images/auth/bg.jpg";
import AuthHeader from "../components/AuthHeader";
import NotAMember from "../components/NotAMember";
const LoginCredentials = () => {

    return (
        <div className="w-full bg-cover bg-no-repeat bg-center pt-px pb-px" style={{
            backgroundImage: `url(${AuthBg})`
        }}>
            <Container>
                {/* header */}
                <AuthHeader to="/login" />
                <div className="mx-auto xl:ms-0 max-w-[480px]">
                    {/* title */}
                    <div className="flex flex-col items-center xl:items-start gap-6 mb-8">
                        <h1 className="text-white text-xl font-bold font-main">
                            Welcome Back!
                        </h1>
                        <hr className="w-24 h-1" />
                    </div>
                    <CredentialsForm />
                </div>
                {/* Not a member */}
                <NotAMember />
            </Container>
        </div>
    );
};

export default LoginCredentials;
