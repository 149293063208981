import { useState } from "react";
import Destinations from "./Destinations";
import Activities from "./Activities";
import Accommodation from "./Accommodation";
import Transportation from "./Transportation";
import Itinerary from "./Itinerary";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import Policies from "./Policies";
import IncludedExcluded from "./IncludedExcluded";
import Description from "./Description";

const AccordionData = () => {
  return (
    <Accordion type="single" collapsible className="w-full border border-mediumGrey rounded-[14px] overflow-hidden">
      <AccordionItem value="description" className="">
        <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Description</AccordionTrigger>
        <AccordionContent className="px-5 py-5 mb-5">
            <Description />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="destinations" className="">
        <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Destinations</AccordionTrigger>
        <AccordionContent className="px-5 py-5 mb-5">
            <Destinations />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="activities" className="">
        <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Activities</AccordionTrigger>
        <AccordionContent className="px-5 py-5 mb-5">
            <Activities />
        </AccordionContent>
          </AccordionItem>
        <AccordionItem value="accommodation" className="">
            <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Accommodation</AccordionTrigger>
            <AccordionContent className="px-5 py-5 mb-5">
                <Accommodation />
            </AccordionContent>
        </AccordionItem>
        <AccordionItem value="transportation" className="">
            <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Transportation</AccordionTrigger>
            <AccordionContent className="px-5 py-5 mb-5">
                <Transportation />
            </AccordionContent>
        </AccordionItem>
        <AccordionItem value="itinerary" className="">
            <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Itinerary and Days Schedule</AccordionTrigger>
            <AccordionContent className="px-5 py-5 mb-5">
                <Itinerary />
            </AccordionContent>
        </AccordionItem>
        <AccordionItem value="includedExcluded" className="">
            <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Included / Excluded</AccordionTrigger>
            <AccordionContent className="px-5 py-5 mb-5">
                <IncludedExcluded />
            </AccordionContent>
        </AccordionItem>
        <AccordionItem value="policies" className="">
            <AccordionTrigger className="text-[15px] sm:text-[17px] xl:text-md text-darkBlue font-semibold hover:no-underline ps-5 pe-8 data-[state=open]:bg-lightBlue hover:bg-lightBlue">Policies</AccordionTrigger>
            <AccordionContent className="px-5 py-5 mb-5">
                <Policies />
            </AccordionContent>
        </AccordionItem>  
    </Accordion>
  );
};

export default AccordionData;
