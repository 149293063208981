import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "src/components/ui/carousel"
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { DotButtons } from "../carousels/components/DotButtons";
import { ScrollArea } from "src/components/ui/scroll-area";
import { LazyLoadImage } from "../carousels/components/LazyLoadImage";
import { EmblaCarouselType, EmblaEventType } from 'embla-carousel'

// const TWEEN_FACTOR_BASE = 0.52

// const numberWithinRange = (number: number, min: number, max: number): number =>
//   Math.min(Math.max(number, min), max)

const ImageCarouselDialog = ({
    title,
    toggler,
    images
}: {
    title: string;
    toggler: ReactNode;
    images: string[];
}) => {
    const [carouselApi, setCarouselApi] = useState<CarouselApi>()
    const [slidesInView, setSlidesInView] = useState<number[]>([])
    const [current, setCurrent] = useState(0)
    const [count, setCount] = useState(0)
    // const tweenFactor = useRef(0)
    // const tweenNodes = useRef<HTMLElement[]>([])

    // const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    //     tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
    //         return slideNode.querySelector('.carousel-image') as HTMLElement
    //     })
    // }, [])

    // const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    //     tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
    // }, [])

    // const tweenScale = useCallback(
    //     (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
    //     const engine = emblaApi.internalEngine()
    //     const scrollProgress = emblaApi.scrollProgress()
    //     const slidesInView = emblaApi.slidesInView()
    //     const isScrollEvent = eventName === 'scroll'

    //     emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
    //         let diffToTarget = scrollSnap - scrollProgress
    //         const slidesInSnap = engine.slideRegistry[snapIndex]

    //         slidesInSnap.forEach((slideIndex) => {
    //         if (isScrollEvent && !slidesInView.includes(slideIndex)) return

    //         if (engine.options.loop) {
    //             engine.slideLooper.loopPoints.forEach((loopItem) => {
    //             const target = loopItem.target()

    //             if (slideIndex === loopItem.index && target !== 0) {
    //                 const sign = Math.sign(target)

    //                 if (sign === -1) {
    //                 diffToTarget = scrollSnap - (1 + scrollProgress)
    //                 }
    //                 if (sign === 1) {
    //                 diffToTarget = scrollSnap + (1 - scrollProgress)
    //                 }
    //             }
    //             })
    //         }

    //         const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
    //         const scale = numberWithinRange(tweenValue, 0, 1).toString()
    //         const tweenNode = tweenNodes.current[slideIndex]
    //         tweenNode.style.transform = `scale(${scale})`
    //         })
    //     })
    // }, [])


    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off('slidesInView', updateSlidesInView)
            }
            const inView = emblaApi
                .slidesInView()
                .filter((index) => !slidesInView.includes(index))
            return slidesInView.concat(inView)
        })
    }, [])
    
    useEffect(() => {
        if (!carouselApi) return;

        setCount(carouselApi.scrollSnapList().length);
        setCurrent(carouselApi.selectedScrollSnap());
    
        carouselApi.on("select", () => {
            setCurrent(carouselApi.selectedScrollSnap())
        })

        updateSlidesInView(carouselApi)
        carouselApi.on('slidesInView', updateSlidesInView)
        carouselApi.on('reInit', updateSlidesInView)

        // setTweenNodes(carouselApi)
        // setTweenFactor(carouselApi)
        // tweenScale(carouselApi)

        // carouselApi
        //     .on('reInit', setTweenNodes)
        //     .on('reInit', setTweenFactor)
        //     .on('reInit', tweenScale)
        //     .on('scroll', tweenScale)
        //     .on('slideFocus', tweenScale)

    }, [
        carouselApi,
        updateSlidesInView,
        // tweenScale,
    ])
    
    return (
        <Dialog modal={true}>
            <DialogTrigger asChild>
                {toggler}
            </DialogTrigger>
            <DialogContent className="max-w-[1400px] bg-transparent !rounded-none border-none gap-0 p-0">
                <DialogTitle className="sr-only">Image carousel Dialog</DialogTitle>
                <DialogClose asChild>
                    <button className="absolute top-4 right-0 z-10">
                        <X className="w-[58px] text-white me-2" />
                    </button>
                </DialogClose>
                <ScrollArea className="max-h-screen">
                    <div className="w-auto h-screen flex flex-col items-center justify-center mx-auto relative overflow-hidden">
                        <Carousel setApi={setCarouselApi} className="relative max-w-[90%] sm:px-8" opts={{
                            skipSnaps: true,
                            loop: false,
                            duration: 10,
                            // startIndex: carouselApi?.selectedScrollSnap()
                        }}>
                            <CarouselContent className={"carousel-content !ml-0 w-full max-h-[90vh]"}>
                                {
                                    images.length ?
                                        images.map((image, index) => (
                                            <CarouselItem key={`image-${index}`} className="w-full relative">
                                                {/* <img src={image} alt={image} className="w-full h-full object-contain overflow-hidden rounded-[10px] sm:rounded-[20px] xl:rounded-[30px]" /> */}
                                                <LazyLoadImage
                                                    title={image}
                                                    className="carousel-image h-fit"
                                                    index={index}
                                                    imgSrc={image}
                                                    inView={slidesInView.indexOf(index) > -1}
                                                />
                                            </CarouselItem>
                                        ))
                                    : null
                                }
                            </CarouselContent>
                        </Carousel>
                        <div className="hidden sm:block absolute top-1/2 inset-x-0 px-5 -translate-y-1/2">
                            <button
                                onClick={() => carouselApi?.scrollPrev()}
                                disabled={carouselApi?.canScrollPrev() === false}
                                type="button"
                                className="absolute top-1/2 -translate-y-1/2 left-4 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                                <ChevronLeft className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                            </button>
                            <button
                                onClick={() => carouselApi?.scrollNext()}
                                disabled={carouselApi?.canScrollNext() === false}
                                type="button"
                                className="absolute top-1/2 -translate-y-1/2 right-4 rounded-full w-8 h-8 sm:w-11 sm:h-11 flex justify-center items-center border-2 border-teal/80 hover:border-green/80 text-teal/80 hover:text-white bg-white/80 hover:bg-green/80 disabled:border-disabled/80 disabled:text-disabled/80 disabled:hover:text-disabled/80 disabled:hover:bg-white/80">
                                <ChevronRight className="w-6 h-6 sm:w-7 sm:h-7 rounded-full" />
                            </button>
                        </div>
                        <DotButtons carouselApi={carouselApi} className="block sm:hidden" />
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
        
    )
}

export default ImageCarouselDialog
