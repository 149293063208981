import React from 'react'
import { Card } from '../../ui/card'
import { Clock } from 'lucide-react'
import { Button } from '../../ui/button'

const ItineraryCard = ({
    imageSrc,
    title,
    duration,
    optionalText,
    listItems,
}: {
    imageSrc: string;
    title: string;
    duration: string;
    optionalText?: string;
    listItems: string[];
}) => {
    return (
        <Card className="mx-auto p-2 rounded-[12px] border-[0.5px] border-teal w-full flex flex-col md:flex-row justify-start items-stretch gap-4">
            <div className="relative w-full md:max-w-[177px] max-h-[200px] h-auto rounded-[8px] overflow-hidden">
                <img src={imageSrc} alt="cardImage" className="h-full w-full object-cover" />
            </div>
            <div className="w-full p-2 sm:p-3 xl:p-4">
                <div className="w-full flex flex-col gap-2">
                    <div className="flex justify-start items-center gap-1 flex-wrap">
                        <h4 className="text-darkBlue font-semibold text-[15px] sm:text-[17px] xl:text-md">{title}</h4>
                        {
                            optionalText ? (
                                <div className='flex justify-start items-center gap-1 flex-wrap'>
                                    <span>-</span>
                                    <label className='italic text-teal text-xs sm:text-[13px] xl:text-sm'>
                                        {optionalText}
                                    </label>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="flex items-center gap-2">
                        <Clock className="text-teal w-5" />
                        <p className="text-teal text-xs sm:text-[13px] xl:text-sm font-semibold">
                            {duration}
                        </p>
                    </div>
                    {
                        listItems.length ?
                            <div className="prose text-xs sm:text-[13px] xl:text-sm text-black">
                                <ul className='list-outside -ms-1'>
                                    {
                                        listItems.map((item) => (    
                                            <li key={item}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        : null
                    }
                </div>
                {
                    optionalText ? (
                        <div className="mt-6 flex justify-start">
                            <Button
                                variant="outline"
                                className='outline-button'>
                                Add to My Package
                            </Button>
                        </div>
                    ) : null
                }
                
            </div>
        </Card>
    )
}

export default ItineraryCard
