const countryCodes = [
  { code: "+93", name: "AF +93" },
  { code: "+355", name: "AL +355" },
  { code: "+213", name: "DZ +213" },
  { code: "+376", name: "AD +376" },
  { code: "+244", name: "AO +244" },
  { code: "+1", name: "US +1" },
  { code: "+297", name: "AW +297" },
  { code: "+61", name: "AU +61" },
  { code: "+43", name: "AT +43" },
  { code: "+994", name: "AZ +994" },
  { code: "+973", name: "BH +973" },
  { code: "+880", name: "BD +880" },
  { code: "+375", name: "BY +375" },
  { code: "+32", name: "BE +32" },
  { code: "+501", name: "BZ +501" },
  { code: "+229", name: "BJ +229" },
  { code: "+975", name: "BT +975" },
  { code: "+591", name: "BO +591" },
  { code: "+387", name: "BA +387" },
  { code: "+267", name: "BW +267" },
  { code: "+55", name: "BR +55" },
  { code: "+673", name: "BN +673" },
  { code: "+359", name: "BG +359" },
  { code: "+226", name: "BF +226" },
  { code: "+257", name: "BI +257" },
  { code: "+855", name: "KH +855" },
  { code: "+237", name: "CM +237" },
  { code: "+1", name: "CA +1" },
  { code: "+238", name: "CV +238" },
  { code: "+236", name: "CF +236" },
  { code: "+235", name: "TD +235" },
  { code: "+56", name: "CL +56" },
  { code: "+86", name: "CN +86" },
  { code: "+57", name: "CO +57" },
  { code: "+269", name: "KM +269" },
  { code: "+243", name: "CD +243" },
  { code: "+242", name: "CG +242" },
  { code: "+682", name: "CK +682" },
  { code: "+506", name: "CR +506" },
  { code: "+385", name: "HR +385" },
  { code: "+53", name: "CU +53" },
  { code: "+357", name: "CY +357" },
  { code: "+420", name: "CZ +420" },
  { code: "+45", name: "DK +45" },
  { code: "+253", name: "DJ +253" },
  { code: "+1", name: "DO +1" },
  { code: "+593", name: "EC +593" },
  { code: "+20", name: "EG +20" },
  { code: "+503", name: "SV +503" },
  { code: "+240", name: "GQ +240" },
  { code: "+291", name: "ER +291" },
  { code: "+372", name: "EE +372" },
  { code: "+251", name: "ET +251" },
  { code: "+500", name: "FK +500" },
  { code: "+298", name: "FO +298" },
  { code: "+679", name: "FJ +679" },
  { code: "+358", name: "FI +358" },
  { code: "+33", name: "FR +33" },
  { code: "+241", name: "GA +241" },
  { code: "+220", name: "GM +220" },
  { code: "+995", name: "GE +995" },
  { code: "+49", name: "DE +49" },
  { code: "+233", name: "GH +233" },
  { code: "+350", name: "GI +350" },
  { code: "+30", name: "GR +30" },
  { code: "+299", name: "GL +299" },
  { code: "+502", name: "GT +502" },
  { code: "+224", name: "GN +224" },
  { code: "+245", name: "GW +245" },
  { code: "+592", name: "GY +592" },
  { code: "+509", name: "HT +509" },
  { code: "+504", name: "HN +504" },
  { code: "+852", name: "HK +852" },
  { code: "+36", name: "HU +36" },
  { code: "+354", name: "IS +354" },
  { code: "+91", name: "IN +91" },
  { code: "+62", name: "ID +62" },
  { code: "+98", name: "IR +98" },
  { code: "+964", name: "IQ +964" },
  { code: "+353", name: "IE +353" },
  { code: "+972", name: "IL +972" },
  { code: "+39", name: "IT +39" },
  { code: "+81", name: "JP +81" },
  { code: "+962", name: "JO +962" },
  { code: "+7", name: "KZ +7" },
  { code: "+254", name: "KE +254" },
  { code: "+686", name: "KI +686" },
  { code: "+965", name: "KW +965" },
  { code: "+996", name: "KG +996" },
  { code: "+856", name: "LA +856" },
  { code: "+371", name: "LV +371" },
  { code: "+961", name: "LB +961" },
  { code: "+266", name: "LS +266" },
  { code: "+231", name: "LR +231" },
  { code: "+218", name: "LY +218" },
  { code: "+423", name: "LI +423" },
  { code: "+370", name: "LT +370" },
  { code: "+352", name: "LU +352" },
  { code: "+853", name: "MO +853" },
  { code: "+389", name: "MK +389" },
  { code: "+261", name: "MG +261" },
  { code: "+265", name: "MW +265" },
  { code: "+60", name: "MY +60" },
  { code: "+960", name: "MV +960" },
  { code: "+223", name: "ML +223" },
  { code: "+356", name: "MT +356" },
  { code: "+692", name: "MH +692" },
  { code: "+222", name: "MR +222" },
  { code: "+230", name: "MU +230" },
  { code: "+262", name: "RE +262" },
];

export default countryCodes;
