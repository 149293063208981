import { FC } from "react";
import { AccommodationTab, AccommodationTabContent } from "src/interfaces/tabs";
import tourDetails from "src/assets/images/ui/tour-details.jpg";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import ButtonCarousel from "src/components/common/carousels/ButtonCarousel";
import { CarouselItem } from "src/components/ui/carousel";
import { Button } from "src/components/ui/button";
import RatingStars from "src/components/common/rating/RatingStars";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Label } from "src/components/ui/label";
import RoomDetailsDialog from "src/components/common/dialogs/RoomDetailsDialog";
import { RoomDetails } from "src/interfaces/tourDetails";

// Refactor tabsContent to be a proper React component
const TabsContentComponent = ({
    content,
}: {
    content: AccommodationTabContent;
}) => {
    const images = [
        tourDetails,
        tourDetails,
        tourDetails,
        tourDetails,
    ];

    const roomDetails: RoomDetails = {
        title: 'Oasis Twin Room',
        subtitle: 'Partial Sea & Garden View',
        currency: '$',
        price: 30,
        extraNights: 2,
        homeSpace: 22,
        beds: 2,
        adults: 2,
        children: 2,
        isAllInclusive: true,
        isPetsAllowed: true,
        isSmokingAllowed: true,
        facilities: [
            'Private Bathroom',
            'Minibar',
            'Flat Screen',
            'Electric Kettle',
            'Garden View',
            'Sea View',
            'Stand Shower',
            'Free Wifi',
            'Private Bathroom',
            'Minibar',
            'Flat Screen',
            'Electric Kettle',
            'Garden View',
            'Sea View',
            'Stand Shower',
            'Free Wifi',
            'Private Bathroom',
            'Minibar',
            'Flat Screen',
            'Electric Kettle',
        ],
    }

    return (
        <>
        <div className="flex flex-col md:flex-row gap-4 mt-5 py-5">
            <div className="w-full md:max-w-72 max-h-72 rounded-[20px] overflow-hidden">
            <img
                src={content.coverImage}
                alt={content.title}
                className="w-full h-full object-cover"
            />
            </div>
            <div className="w-full">
            <button
                type="button"
                onClick={() => {}}
                className="text-[17px] sm:text-[19px] xl:text-lg text-darkTeal font-bold underline"
            >
                {content.title}
            </button>
            <div className="mt-3 flex align-items-center gap-1.5 text-darkTeal text-xs sm:text-[13px] xl:text-sm font-semibold">
                <RatingStars rating={content.rating} />
                <span>
                {content.rating} {content.rating > 1 ? 'Stars' : 'Star'}
                </span>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-8 gap-y-2 mt-3">
                <p className="text-darkBlue text-xs sm:text-[13px] xl:text-sm font-normal text-nowrap">
                (2 rooms, 2 Adults, 0 Children)
                </p>
                <Button
                type="button"
                variant={'link'}
                className="text-xs underline font-semibold text-hyperLink hover:text-green active:text-green px-0 mx-0 h-auto"
                >
                    Change Travelers Details
                </Button>
            </div>
            <div className="mt-5 xl:ms-5 flex flex-col gap-5">
                {content.rooms.map((room, index) => (
                <div key={`room-${index}`} className="flex flex-col gap-3">
                    <div className="text-darkTeal text-[10px] sm:text-[11px] xl:text-xs font-bold">Room {index + 1}:</div>
                    <div className="flex flex-col gap-2">
                    <RadioGroup
                        defaultValue={`room${index}-option0`}
                        className="flex flex-col gap-3.5"
                    >
                        {room.options.map((option, optionIndex) => (
                        <div
                            key={`room-${index}-option-${optionIndex}`}
                            className="flex items-center space-x-2 mb-2"
                        >
                            <RadioGroupItem
                            value={`room${index}-option${optionIndex}`}
                            id={`room${index}-option${optionIndex}`}
                            />
                            <Label
                            htmlFor={`room${index}-option${optionIndex}`}
                            className="flex items-center gap-1"
                            >
                                <RoomDetailsDialog
                                    title={roomDetails.title}
                                    images={images}
                                    trigger={
                                        <div className="max-w-full text-xs sm:text-[13px] xl:text-sm leading-normal font-semibold underline text-darkBlue hover:text-green cursor-pointer">
                                            {option.title} 
                                        </div>
                                    }
                                    details={roomDetails}
                                />
                                
                                {
                                    option.price ? (
                                        <span className="font-normal italic text-teal text-nowrap text-xs sm:text-[13px] xl:text-sm">
                                            ({option.currency} {option.price} extra)
                                        </span>
                                    ) : (
                                        <span className="font-normal italic text-teal text-nowrap text-xs sm:text-[13px] xl:text-sm">(included)</span>
                                    )
                                }
                            </Label>
                        </div>
                        ))}
                    </RadioGroup>
                    </div>
                </div>
                ))}
            </div>
            </div>
        </div>
        </>
    );
};

const Accommodation = () => {
    const accommodationTabs: AccommodationTab[] = [
        {
            label: "Cairo",
            value: "cairo",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 1",
                rating: 2.3,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
        {
            label: "Alexandria",
            value: "alexandria",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 2",
                rating: 4.5,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
        {
            label: "Sharm Al Sheikh",
            value: "sharm-al-sheikh",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 3",
                rating: 0,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
        {
            label: "Hurghada",
            value: "hurghada",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 3",
                rating: 1,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
        {
            label: "Dahab",
            value: "dahab",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 3",
                rating: 5,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
        {
            label: "El Gouna",
            value: "el-gouna",
            sublabel: "3 nights / 4 days",
            content: {
                coverImage: tourDetails,
                title: "Mariott - Al Zamalek Hotel 3",
                rating: 5,
                rooms: [
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    },
                    {
                        options: [
                            {
                                title: "Oasis Twin Room - Partial Sea & Garden View",
                                currency: "$",
                            },
                            {
                                title: "Bella Vista Queen - Sea, Pool, & Garden View",
                                currency: "$",
                                price: 50,
                            },
                            {
                                title: "Suite with Balcony - Pool & Garden View",
                                currency: "$",
                                price: 110,
                            },
                        ],
                    }
                ],

            }
        },
    ]

    return (
            accommodationTabs.length ? 
                <Tabs defaultValue={accommodationTabs[0].value} className="w-full !rounded-none">
                    <TabsList className="w-full h-auto bg-transparent !rounded-none">
                        <ButtonCarousel>
                            {
                                accommodationTabs.map((tab, index) => (
                                    <CarouselItem key={tab.value} className="basis-auto relative overflow-hidden">
                                        <TabsTrigger key={tab.value} value={tab.value} className="flex flex-col py-3 px-8 rounded-[3px] justify-center items-center gap-1 text-darkBlue text-[13px] sm:text-sm xl:text-[15px] bg-white border-[0.7px] border-teal data-[state=active]:bg-green data-[state=active]:text-white data-[state=active]:border-green">
                                            <span className="text-inherit font-semibold text-[13px] sm:text-sm xl:text-[15px]">{tab.label}</span>
                                            <span className="text-inherit font-medium text-xs">{tab.sublabel}</span>
                                        </TabsTrigger>
                                    </CarouselItem>
                                ))
                            }
                        </ButtonCarousel>
                    </TabsList>
                    {
                        accommodationTabs.map((tab, index) => (
                            <TabsContent key={`tab-content-${index}`} value={tab.value}>{TabsContentComponent({content: tab.content})}</TabsContent>
                        ))
                    }
                </Tabs>
            : null
    );
};

export default Accommodation;
