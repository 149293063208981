import { useMemo, useState } from "react";
import TourCard from "../cards/tourCard/TourCard";
import SvgWavyLine from "../icons/WavyLine";
import Container from "./Container";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { TourCardData, TourPackagesTab } from "src/interfaces/tourCard";
import PackageImage1 from "src/assets/images/ui/package-image-1.jpg";
import PackageImage2 from "src/assets/images/ui/package-image-2.jpg";
import PackageImage3 from "src/assets/images/ui/package-image-3.jpg";
import { DotButtons } from "./carousels/components/DotButtons";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import ButtonCarousel from "./carousels/ButtonCarousel";
import { EmblaCarouselType } from 'embla-carousel'

const TabsContentComponent = ({
    cards,
    api,
    setApi,
}: {
    cards: TourCardData[];
    api: EmblaCarouselType | undefined;
    setApi: (api: EmblaCarouselType | undefined) => void;
}) => {
    return (
        <Carousel setApi={setApi} opts={{
            align: "center",
            skipSnaps: true,
        }} className="slider-visible-overflow-sm w-full mx-auto">
            <CarouselContent className="w-full mx-auto ml-0 justify-evenly xl:justify-center gap-2 sm:gap-5">
                {
                    cards.map((card: TourCardData) => (
                        <CarouselItem key={card.id} className="basis-full sm:basis-auto xl:max-w-[calc(33%-0.5rem)] ps-0">
                            <TourCard key={card.id} data={card} />
                        </CarouselItem>
                        
                    ))
                }
            </CarouselContent>
            {/* Dots */}
            <DotButtons
                className="xl:hidden"
                carouselApi={api}
            />
        </Carousel>
    );
}

const TourPackages = () => {
    const [cardsCarouselApi, setCardsCarouselApi] = useState<CarouselApi>()

    const tourPackagesTabs: TourPackagesTab[] = [
        {
            label: "October",
            value: "october",
            cards: [
                {
                    id: 1,
                    imageSrc: PackageImage1,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Long Stay Vacation in El Quseir',
                    avgRating: 4.5,
                    reviewsCount: 132,
                    nightsCount: 12,
                    daysCount: 13,
                    currency: '$',
                    salePrice: 2700,
                    price: 2500,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 2,
                    imageSrc: PackageImage2,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    title: 'A Legendary Vacation',
                    avgRating: 4,
                    reviewsCount: 172,
                    nightsCount: 10,
                    daysCount: 11,
                    currency: '$',
                    salePrice: 2500,
                    price: 2250,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
        {
            label: "December",
            value: "december",
            cards: [
                {
                    id: 1,
                    imageSrc: PackageImage1,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Long Stay Vacation in El Quseir',
                    avgRating: 4.5,
                    reviewsCount: 132,
                    nightsCount: 12,
                    daysCount: 13,
                    currency: '$',
                    salePrice: 2700,
                    price: 2500,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
        {
            label: "January",
            value: "january",
            cards: [
                {
                    id: 1,
                    imageSrc: PackageImage1,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Long Stay Vacation in El Quseir',
                    avgRating: 4.5,
                    reviewsCount: 132,
                    nightsCount: 12,
                    daysCount: 13,
                    currency: '$',
                    salePrice: 2700,
                    price: 2500,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 2,
                    imageSrc: PackageImage2,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    title: 'A Legendary Vacation',
                    avgRating: 4,
                    reviewsCount: 172,
                    nightsCount: 10,
                    daysCount: 11,
                    currency: '$',
                    salePrice: 2500,
                    price: 2250,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
        {
            label: "February",
            value: "february",
            cards: [
                {
                    id: 2,
                    imageSrc: PackageImage2,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    title: 'A Legendary Vacation',
                    avgRating: 4,
                    reviewsCount: 172,
                    nightsCount: 10,
                    daysCount: 11,
                    currency: '$',
                    salePrice: 2500,
                    price: 2250,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
        {
            label: "March",
            value: "march",
            cards: [
                {
                    id: 1,
                    imageSrc: PackageImage1,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Long Stay Vacation in El Quseir',
                    avgRating: 4.5,
                    reviewsCount: 132,
                    nightsCount: 12,
                    daysCount: 13,
                    currency: '$',
                    salePrice: 2700,
                    price: 2500,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 2,
                    imageSrc: PackageImage2,
                    isHotDeal: true,
                    isOnSale: true,
                    salePercent: 20,
                    title: 'A Legendary Vacation',
                    avgRating: 4,
                    reviewsCount: 172,
                    nightsCount: 10,
                    daysCount: 11,
                    currency: '$',
                    salePrice: 2500,
                    price: 2250,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                },
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
        {
            label: "April",
            value: "april",
            cards: [
                {
                    id: 3,
                    imageSrc: PackageImage3,
                    isLimitedPlaces: true,
                    placesLeft: 2,
                    title: 'Movenpick Royal Lily & Lotus Nile Cruises',
                    avgRating: 4.8,
                    reviewsCount: 15,
                    nightsCount: 9,
                    daysCount: 10,
                    currency: '$',
                    price: 2300,
                    locations: 'Cairo, Hurghada, Abu simbil, Luxor, Aswan, St. Catherine, Taba.',
                    activities: 'Diving, Nile Cruise, Safari, Sightseeing, Camping, Shopping, Skiing.'
                }
            ]
        },
    ]

    return (
        <div className="my-20">
            <Container>
                <div className="w-full flex flex-col items-center gap-4">
                    <h2 className="text-darkTeal text-center">
                        Top Rated Tour Packages
                    </h2>
                    <SvgWavyLine className="w-[132px] sm:w-36 xl:w-44 text-darkTeal" />
                </div>
                {
                    tourPackagesTabs.length ? 
                    <Tabs defaultValue={tourPackagesTabs[0].value} className="w-full !rounded-none">
                        <TabsList className="w-full mt-6 sm:mt-8 xl:mt-12 max-w-full mx-auto bg-transparent">
                            <ButtonCarousel
                                hideBtnGradient    
                                carouselClassName="slider-visible-overflow w-full max-w-full mx-auto"
                                className="w-full mx-auto ml-0 justify-start xl:justify-center gap-2 sm:gap-4 xl:gap-6"
                            >
                                {
                                    tourPackagesTabs.map((tab, index) => (
                                        <CarouselItem key={tab.value} className="basis-auto relative overflow-hidden">
                                            <TabsTrigger key={tab.value} value={tab.value} className="px-10 py-3 rounded-[3rem] text-darkBlue text-xs sm:text-sm bg-white border-[0.7px] border-teal data-[state=active]:bg-green data-[state=active]:text-white data-[state=active]:border-green">
                                                {tab.label}
                                            </TabsTrigger>
                                        </CarouselItem>
                                    ))
                                }
                            </ButtonCarousel>
                        </TabsList>
                        {
                            tourPackagesTabs.map((tab, index) => (
                                <TabsContent key={`tab-content-${index}`} value={tab.value}>
                                    <div className="mt-6 sm:mt-10">
                                        <div className="mx-auto w-full max-w-full">
                                            {
                                                TabsContentComponent({
                                                    cards: tab.cards,
                                                    api: cardsCarouselApi,
                                                    setApi: setCardsCarouselApi
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full mt-5 flex justify-center xl:justify-end">
                                        <Button variant={'link'} className="text-sm sm:text-base text-darkBlue font-bold underline hover:text-green">Show More</Button>
                                    </div>
                                </TabsContent>
                            ))
                        }
                    </Tabs>
                    : null
                }
                {/* <nav className="mt-6 sm:mt-8 xl:mt-12 w-fit max-w-full mx-auto">
                    <Carousel opts={{
                    align: "center",
                    skipSnaps: true
                    }} className="slider-visible-overflow w-full mx-auto">
                        <CarouselContent className="w-full mx-auto ml-0 justify-evenly gap-2 sm:gap-5 xl:gap-8">
                            {
                                availableMonths.map((month) => (
                                    <CarouselItem key={month} className="basis-auto ps-0">
                                        <Button
                                            className={cn("text-xs sm:text-sm px-8", {"border-teal": selectedMonth !== month })}
                                            variant={selectedMonth === month ? 'default' : 'outline'}
                                            onClick={() => handleMonthClick(month)}>
                                            {month}
                                        </Button>
                                    </CarouselItem>
                                ))
                            }
                        </CarouselContent>
                    </Carousel>
                </nav> */}
                {/* <div className="mt-6 sm:mt-10">
                    <div className="mx-auto w-full max-w-full">
                        <Carousel setApi={setCardsCarouselApi} opts={{
                            align: "center",
                            skipSnaps: true,
                        }} className="slider-visible-overflow-sm w-full mx-auto">
                            <CarouselContent className="w-full mx-auto ml-0 justify-evenly gap-2 sm:gap-5">
                                {
                                    cards.map((card: TourCardData) => (
                                        <CarouselItem key={card.id} className="basis-auto xl:max-w-[calc(33%-0.5rem)] ps-0">
                                            <TourCard key={card.id} data={card} />
                                        </CarouselItem>
                                        
                                    ))
                                }
                            </CarouselContent>
                            <DotButtons
                                className="xl:hidden"
                                carouselApi={cardsCarouselApi}
                            />
                        </Carousel>
                    </div>
                    <div className="w-full mt-5 flex justify-center xl:justify-end">
                        <Button variant={'link'} className="text-sm sm:text-base text-darkBlue font-bold underline hover:text-green">Show More</Button>
                    </div>
                </div> */}
            </Container>
        </div>
    );
};

export default TourPackages;
