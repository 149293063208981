
const HotDealBadge = () => {
    return (
        <div className="relative w-max flex items-center">
            <div className="bg-green h-auto py-1.5 px-2 text-xs sm:text-sm text-white font-semibold">Hot Deal</div>
            <div className="absolute right-0 top-0 bottom-0 translate-x-full h-full border-s-[16px] border-t-[16px] border-b-[16px] border-s-green border-t-transparent border-b-transparent" />
        </div>
    )
}

export default HotDealBadge
